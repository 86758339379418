import React, { useState } from 'react';
import Dashboard from '../../Layouts/Dashboard';
import CreateCRMTypeForm from '../Modules/CreateCRMTypeForm';
import EditCRMTypeProperties from '../Modules/EditCRMTypeProperties';
import DefineCRMTypeChildren from '../Modules/DefineCRMTypeChildren';
import { SortableTable } from '../../Components/Table';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Modal from '../../Components/Modal';

const ManageCRMTypes = () => {
    const [isPropertyModalOpen, setIsPropertyModalOpen] = useState(false);
    const [isChildModalOpen, setIsChildModalOpen] = useState(false);
    const [currentCRMType, setCurrentCRMType] = useState(null);

    const handleEditProperties = (row) => {
        setCurrentCRMType(row);
        setIsPropertyModalOpen(true);
    };

    const handleManageChildren = (row) => {
        setCurrentCRMType(row);
        setIsChildModalOpen(true);
    };

    const customColumns = {
        edit: {
            type: 'action',
            name: 'edit',
            display_name: 'Propriétés',
            sortable: false,
            actionCallback: handleEditProperties,
            tooltipContent: "Modifier les propriétés"
        },
        manageChildren: {
            type: 'action',
            name: 'manageChildren',
            display_name: 'Gérer les liens',
            sortable: false,
            actionCallback: handleManageChildren,
            tooltipContent: "Gérer les liens"
        }
    };

    return (
        <Dashboard dashboardTitle={'Manage CRM Types'}>
            <div className='p-4 w-full'>
                <CreateCRMTypeForm />

                <Card variant="outlined">
                    <CardContent>
                        <Typography variant='h5' color='textPrimary' className='mb-4'>
                            Gestion des types CRM
                        </Typography>
                        <SortableTable
                            apiEndpoint="/crm/types/list"
                            customColumns={customColumns}
                        />
                    </CardContent>
                </Card>

                <Modal maxWidthClass = "max-w-5xl" isOpen={isPropertyModalOpen} closeModal={() => setIsPropertyModalOpen(false)}>
                    {currentCRMType && (
                        <EditCRMTypeProperties 
                            typeName={currentCRMType.type_name.value} 
                            onClose={() => setIsPropertyModalOpen(false)} 
                        />
                    )}
                </Modal>

                <Modal isOpen={isChildModalOpen} closeModal={() => setIsChildModalOpen(false)}>
                    {currentCRMType && (
                        <DefineCRMTypeChildren 
                            typeName={currentCRMType.type_name.value} 
                            onClose={() => setIsChildModalOpen(false)} 
                        />
                    )}
                </Modal>
            </div>
        </Dashboard>
    );
};

export default ManageCRMTypes;