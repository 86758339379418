import React, { useEffect, useState, useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { HttpRequestContext } from './HttpRequestProvider';
import { resolveValueObject } from './ValueObjects';

const EditableContainerTable = ({ apiPath, containerType, onSave, modifyApiPath, handleSaveExternally, setHasUnsavedChanges }) => {
  const { sendRequest } = useContext(HttpRequestContext);
  const [containers, setContainers] = useState([]);
  const [columns, setColumns] = useState([]);
  const [crmType, setCrmType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deletedContainers, setDeletedContainers] = useState([]); // New state to track deleted containers

  // Fetch CRM Type and container data
  useEffect(() => {
    const fetchCrmTypeAndContainers = async () => {
      setIsLoading(true);
      try {
        // Step 1: Fetch CRM type
        const crmTypeResponse = await sendRequest({
          apiPath: `crm/types/read/${containerType}`,
          type: 'GET',
        });
        setCrmType(crmTypeResponse);

        // Step 2: Fetch containers data
        const containerResponse = await sendRequest({
          apiPath,
          type: 'GET',
        });

        if (containerResponse && containerResponse.rows && containerResponse.columns) {
          // Step 3: Extract prefixes and filter columns
          const prefixes = new Set();
          containerResponse.columns.forEach(column => {
            const splitName = column.name.split('/');
            if (splitName.length > 1) {
              prefixes.add(splitName[0]);
            }
          });

          const filteredColumns = containerResponse.columns.filter(column => {
            const splitName = column.name.split('/');
            if (splitName.length > 1) {
              return false;
            }
            return !prefixes.has(column.name) &&
              !['to_string', 'id', containerType].includes(column.name);
          });


          // Step 4: Map response data to the containers array using ValueObjects
          const mappedContainers = containerResponse.rows.map((row) => {
            const properties = {};
            for (const [key, value] of Object.entries(row)) {
              const prefix = key.split('/')[0];
              // Apply all necessary filters, and only add CRM properties
              if (
                !prefixes.has(prefix) &&
                key !== 'id' &&
                key !== 'to_string' &&
                key !== containerType &&
                value !== undefined &&
                typeof value === 'object' &&
                value !== null &&
                'type' in value &&
                'value' in value
              ) {
                properties[key] = resolveValueObject(value);
              }
            }
            return { id: row.id, properties, isNew: false }; // Add isNew flag for existing containers
          });

          setContainers(mappedContainers);
          setColumns(filteredColumns);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des containers:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCrmTypeAndContainers();
  }, [apiPath, sendRequest, containerType]);

  // Step 5: Add a new container row based on crmType
  const handleAddContainer = () => {
    if (!crmType) return;

    const properties = {};
    for (const [key, property] of Object.entries(crmType.properties)) {
      properties[key] = resolveValueObject(property);
    }

    setContainers([
      ...containers,
      {
        id: null, // New container does not have an ID yet
        properties,
        isNew: true, // Mark this container as new
      },
    ]);

    // Notify parent about unsaved changes
    setHasUnsavedChanges(true);
  };

  // Handle changes in container data
  const handlePropertyChange = (containerIndex, propertyName, newValue) => {
    const updatedContainers = [...containers];
    const container = updatedContainers[containerIndex];

    if (container.properties[propertyName]) {
      if (container.properties[propertyName].type === 'currency_amount') {
        container.properties[propertyName].setValue({
          ...container.properties[propertyName].value,
          ...newValue,
        });
      } else if (container.properties[propertyName].type === 'number') {
        container.properties[propertyName].setValue(parseFloat(newValue) || 0);
      } else {
        container.properties[propertyName] = container.properties[propertyName].setValue(newValue);
      }
    }

    setContainers(updatedContainers);
    // Notify parent about unsaved changes
    setHasUnsavedChanges(true);
  };

  // Remove a container row
  const handleRemoveContainer = (index) => {
    setContainers((prevContainers) => {
      const containerToRemove = prevContainers[index];
      if (!containerToRemove.isNew) {
        // If the container is not new, add its ID to the deletedContainers list
        setDeletedContainers((prevDeleted) => [...prevDeleted, containerToRemove.id]);
      }
      // Remove the container from the containers list
      return prevContainers.filter((_, i) => i !== index);
    });

    // Notify parent about unsaved changes
    setHasUnsavedChanges(true);
  };

  // Save the changes made to the containers
  const handleSave = async () => {
    try {
      const newContainers = [];
      const modifiedContainers = [];

      containers.forEach((container) => {
        const propertyValues = {};
        for (const [key, valueObject] of Object.entries(container.properties)) {
          if (typeof valueObject === 'object' && valueObject !== null && 'type' in valueObject && 'value' in valueObject) {
            propertyValues[key] = valueObject.value;
          }
        }

        if (container.isNew) {
          newContainers.push(propertyValues); // Add the property values directly
        } else {
          modifiedContainers.push({
            container_id: container.id,
            property_values: propertyValues,
          });
        }
      });

      // Debugging logs to verify the container states
      console.log("New containers:", newContainers);
      console.log("Modified containers:", modifiedContainers);
      console.log("Deleted containers:", deletedContainers);

      if (handleSaveExternally) {
        // If a parent component is handling the save, pass the data back
        handleSaveExternally({ newContainers, modifiedContainers, deletedContainers });
      } else {
        // Internal save handling: Directly call respective APIs
        for (const newContainer of newContainers) {
          console.log("Sending new container:", newContainer); // Debug log
          await sendRequest({
            apiPath: `/crm/instances/${containerType}/create`,
            type: 'POST',
            request: newContainer,  // Send each container's property values directly
          });
        }

        // Update existing containers using batch update API
        if (modifiedContainers.length > 0) {
          console.log("Sending modified containers:", modifiedContainers); // Debug log
          await sendRequest({
            apiPath: `/crm/containers/update-multiple`,
            type: 'POST',
            request: {
              updates: modifiedContainers, // Send all modified containers in one request
            },
          });
        }

        // Delete containers
        for (const containerId of deletedContainers) {
          console.log("Deleting container with ID:", containerId); // Debug log
          await sendRequest({
            apiPath: `/crm/container/${containerId}`,
            type: 'DELETE',
          });
        }

        if (onSave) {
          onSave();
        }

        setDeletedContainers([]);
        setHasUnsavedChanges(false);
      }
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des containers:", error);
    }
  };

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.name}>{column.display_name}</TableCell>
                  ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {containers.map((container, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => {
                      const valueObject = container.properties[column.name];
                      return (
                        <TableCell key={column.name}>
                          {valueObject?.renderCompactInput((propertyName, value) =>
                            handlePropertyChange(index, propertyName, value)
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <IconButton onClick={() => handleRemoveContainer(index)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAddContainer}
            style={{ marginTop: 10 }}
          >
            Ajouter un container
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            style={{ marginTop: 10, marginLeft: 10 }}
          >
            Enregistrer les modifications
          </Button>
        </>
      )}
    </div>
  );
};

export default EditableContainerTable;
