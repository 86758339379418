import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material';

const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const formatDateForBackend = (dateString) => {
  if (!dateString) return '';
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};

const parseDateFromBackend = (dateString) => {
  if (!dateString) return '';
  const [day, month, year] = dateString.split('/');
  return `${year}-${month}-${day}`;
};

const PropertyInputField = ({ property, value, onChange }) => {
  const handleChange = (e) => {
    const newValue = property.type === 'image' ? e.target.files[0] : e.target.value;
    onChange(property.property_name, newValue);
  };

  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    const formattedDate = formatDateForBackend(dateValue);
    onChange(property.property_name, formattedDate);
  };

  const handleCurrencyChange = (e) => {
    const { name, value: inputValue } = e.target;
    onChange(property.property_name, { ...value, [name]: inputValue });
  };

  if (property.type === 'label') {
    if (property.accepted_values.length === 0) return null;
    const dropdownOptions = property.accepted_values.map((val) => ({
      label: val,
      value: val,
    }));

    return (
      <FormControl fullWidth margin="normal" required>
        <InputLabel>{property.display_name}</InputLabel>
        <Select
          id={property.property_name}
          value={value || property.accepted_values[0]}
          onChange={handleChange}
        >
          {dropdownOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (property.type === 'image') {
    return (
      <Button variant="contained" component="label" margin="normal">
        {property.display_name}
        <input type="file" hidden onChange={handleChange} accept="image/*" />
      </Button>
    );
  } else if (property.type === 'date') {
    const dateValue = value.includes('/') ? parseDateFromBackend(value) : formatDateForInput(value);
    return (
      <TextField
        id={property.property_name}
        type="date"
        label={property.display_name}
        value={dateValue}
        onChange={handleDateChange}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  } else if (property.type === 'currency_amount') {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <TextField
          id={`${property.property_name}_amount`}
          name="amount"
          type="number"
          label={`${property.display_name} Amount`}
          placeholder={`${property.display_name} Amount`}
          value={value?.amount || ''}
          onChange={handleCurrencyChange}
          fullWidth
          margin="normal" 
        />
        <FormControl fullWidth margin="normal" required>
          <InputLabel>{property.display_name} Currency</InputLabel>
          <Select
            id={`${property.property_name}_currency`}
            name="currency"
            value={value?.currency || property.value.supported_currencies[0]}
            onChange={handleCurrencyChange}
          >
            {property.value.supported_currencies.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {property.currency_symbols[currency] || currency}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  } else if (property.type === 'schedule') {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <TextField
          id={`${property.property_name}_start`}
          name="start"
          type="date"
          label={`${property.display_name} Stard Date`}
          placeholder={`${property.display_name} Start`}
          value={value?.start || ''}
          onChange={handleCurrencyChange}
          fullWidth
          margin="normal" 
        />
        <TextField
          id={`${property.property_name}_end`}
          name="end"
          type="date"
          label={`${property.display_name} End Date`}
          placeholder={`${property.display_name} End`}
          value={value?.end || ''}
          onChange={handleCurrencyChange}
          fullWidth
          margin="normal" 
        />
        <TextField
          id={`${property.property_name}_start`}
          name="start"
          type="date"
          label={`${property.display_name} Stard Date`}
          placeholder={`${property.display_name} Start`}
          value={value?.start || ''}
          onChange={handleCurrencyChange}
          fullWidth
          margin="normal" 
        />
      </div>
    );
  } else {
    return (
      <TextField
        id={property.property_name}
        type="text"
        label={property.display_name}
        placeholder={property.display_name}
        value={value || ''}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
    );
  }
};

export default PropertyInputField;