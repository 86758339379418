import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { HttpRequestContext } from './HttpRequestProvider';
import { resolveValueObject, BaseValueObject, PrimitiveValue, EmailValue, JsonValue } from './ValueObjects';
import { ContainerListReader } from './ContainerListReader';

const CreateEditContainerInstance = ({ isOpen, onClose, crmType, selectedInstance, onSave }) => {
  const { sendRequestWithToast, sendRequest } = useContext(HttpRequestContext);
  const { fetchData } = ContainerListReader();
  const [propertyValues, setPropertyValues] = useState({});
  const [parentValues, setParentValues] = useState({});
  const [parentDefinitions, setParentDefinitions] = useState({});
  const [parentContainers, setParentContainers] = useState({});

  const fetchParentData = useCallback(async () => {
    const parentLinks = crmType.parent_links || [];
    const parentDefs = {};
    const parentConts = {};

    for (const parentType of parentLinks) {
      const parentDef = await sendRequest({
        apiPath: `crm/types/read/${parentType}`,
        type: 'GET',
      });
      parentDefs[parentType] = parentDef;

      const parentContainersData = await fetchData(`/crm/instances/${parentType}/list`);
      parentConts[parentType] = parentContainersData.rows;
    }

    setParentDefinitions(parentDefs);
    setParentContainers(parentConts);
  }, [crmType, sendRequest, fetchData]);

  useEffect(() => {
    if (crmType) {
      fetchParentData();
    }
  }, [crmType, fetchParentData]);

  useEffect(() => {
    const initializePropertyValues = () => {
      const initialValues = {};
      for (const [key, property] of Object.entries(crmType.properties)) {
        initialValues[key] = resolveValueObject(property);
      }
      return initialValues;
    };

    const fetchContainerDetails = async (containerId) => {
      try {
        const instanceDetails = await sendRequest({
          apiPath: `/crm/container/${containerId}`,
          type: 'GET',
        });

        const initialPropertyValues = {};
        for (const [key, property] of Object.entries(crmType.properties)) {
          initialPropertyValues[key] = resolveValueObject(property);
        }
        for (const [key, property] of Object.entries(instanceDetails.properties)) {
          initialPropertyValues[key] = resolveValueObject(property);
        }

        setPropertyValues(initialPropertyValues);
        setParentValues(instanceDetails.parent_links || {});
      } catch (error) {
        console.error("Error fetching container details:", error);
      }
    };

    if (crmType) {
      if (selectedInstance) {
        fetchContainerDetails(selectedInstance.id.value);
      } else {
        setPropertyValues(initializePropertyValues());
      }
    }
  }, [crmType, selectedInstance, sendRequest]);

  const handleInputChange = (name, value) => {
    setPropertyValues(prevValues => {
      const updatedValues = { ...prevValues };
      updatedValues[name].setValue(value);
      return updatedValues;
    });
  };

  const handleParentChange = (parentType, value) => {
    setParentValues(prevValues => ({
      ...prevValues,
      [parentType]: value
    }));
  };

  const handleModalSubmit = async () => {
    for (const [key, valueObject] of Object.entries(propertyValues)) {
      if (valueObject.type === 'label' && !valueObject.value) {
        alert(`The field ${valueObject.displayName} is required.`);
        return;
      }
    }

    const endpoint = selectedInstance 
      ? `/crm/container/${selectedInstance.id.value}`
      : `/crm/instances/${crmType.type_name}/create`;

    const formData = new FormData();
    Object.entries(propertyValues).forEach(([key, valueObject]) => {
      if (valueObject instanceof JsonValue || valueObject instanceof PrimitiveValue) {
        return; //Don't process them
      }

      if (valueObject.value) {
        if (valueObject.value instanceof File) {
          let file = valueObject.value;
          if (valueObject.value.name.endsWith('.svg')) {
            file = new File([valueObject.value], valueObject.value.name, { type: 'image/svg+xml' });
          }
          formData.append(key, file);
        } else if (typeof valueObject.value === 'object' && valueObject.type === 'currency_amount') {
          formData.append(key, JSON.stringify(valueObject.value));
        } else if (typeof valueObject.value === 'object' && valueObject.type === 'schedule') {
          formData.append(key, JSON.stringify(valueObject.value));
        } else {
          formData.append(key, valueObject.value);
        }
      }
    });
    formData.append('parent_links', JSON.stringify(parentValues));

    const response = await sendRequestWithToast({
      apiPath: endpoint,
      type: 'POST',
      request: formData,
      successMessage: selectedInstance ? "Updated successfully" : "Création réussie",
      errorMessage: "Erreur lors de la connexion",
    });

    if (response) {
      const newContainerId = response.container_id;
      setPropertyValues({});
      setParentValues({});
      onSave(newContainerId);
    }
  };

  const handleDeleteInstance = async () => {
    if (selectedInstance) {
      const response = await sendRequestWithToast({
        apiPath: `/crm/container/${selectedInstance.id.value}`,
        type: 'DELETE',
        successMessage: "Deleted successfully",
        errorMessage: "Erreur lors de la suppression",
      });

      if (response) {
        setPropertyValues({});
        setParentValues({});
        onSave();
      }
    }
  };

  const renderParentDropdown = (parentType) => {
    const parentDefinition = parentDefinitions[parentType];
    const parentContainersList = parentContainers[parentType] || [];
 
    if (!parentDefinition) return null;
    return (
      <FormControl key={parentType} fullWidth margin="normal">
        <InputLabel>{parentDefinition.display_name}</InputLabel>
        <Select
          id={parentType}
          value={parentValues[parentType] || ''}
          onChange={(e) => handleParentChange(parentType, e.target.value)}
          displayEmpty
        >
          {parentContainersList.map(parent => (
            <MenuItem key={parent.id.value} value={parent.id.value}>
              {parent[parentDefinition["display_name"]]?.value || parent.id.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderInputField = (key, valueObject) => {
    if (valueObject instanceof PrimitiveValue) {
      return null; // Don't render anything for PrimitiveValue
    }
    if (valueObject instanceof JsonValue) {
      return null; // Don't render anything for JsonValue
    }
    return valueObject.renderInput((name, value) => handleInputChange(key, value));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{selectedInstance ? "Modification: " : "Création: "} {crmType.display_name}</DialogTitle>
      <DialogContent>
        {Object.entries(propertyValues).map(([key, valueObject]) => (
          <div key={key}>
            {renderInputField(key, valueObject)}
          </div>
        ))}
        {crmType.parent_links && crmType.parent_links.map(parentType => (
          <div key={parentType}>
            {renderParentDropdown(parentType)}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        {selectedInstance && (
          <Button onClick={handleDeleteInstance} color="error" variant="contained" style={{ marginRight: 'auto' }}>
            Supprimer
          </Button>
        )}
        <Button onClick={onClose} color="secondary">
          Annuler
        </Button>
        <Button onClick={handleModalSubmit} color="primary" variant="contained">
          {selectedInstance ? "Mettre à Jour" : "Créer"}
        </Button>
      </DialogActions>
    </Dialog>

  );
};

export default CreateEditContainerInstance;