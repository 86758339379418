import React from 'react';
import { Button, ButtonGroup, Paper, Divider } from '@mui/material';

const Toolbar = ({ addNode, applyLayout, saveFlow, clearFlow }) => {
  return (
    <Paper
      elevation={3}
      style={{
        position: 'absolute',
        left: 10,
        top: 70,
        zIndex: 10,
        padding: '10px',
        backgroundColor: '#ffffff',
      }}
    >
      <ButtonGroup
        orientation="vertical"
        variant="contained"
        aria-label="vertical contained button group"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Button onClick={() => addNode('trigger')} color="primary">
          Add Trigger Node
        </Button>
        <Button onClick={() => addNode('wait')} color="info">
          Add Wait Node
        </Button>
        <Button onClick={() => addNode('waitUntil')} color="info">  {/* Nouveau bouton pour waitUntil */}
          Add WaitUntil Node
        </Button>
        <Button onClick={() => addNode('edit')} color="secondary">
          Add Edit Node
        </Button>
        <Button onClick={() => addNode('sendEmail')} color="success">
          Add Send Email Node
        </Button>
        <Button onClick={() => addNode('sendEmailToChildren')} color="success">  {/* Nouveau bouton pour sendEmailToChildren */}
          Add Send Email To Children Node
        </Button>
        <Button onClick={() => addNode('ifElse')} color="warning">
          Add IF/ELSE Node
        </Button>
        <Button onClick={() => applyLayout('TB')} color="info">
          Apply Vertical Layout
        </Button>
        <Button onClick={() => applyLayout('LR')} color="info">
          Apply Horizontal Layout
        </Button>
      </ButtonGroup>

      <Divider style={{ margin: '10px 0' }} />

      <ButtonGroup
        orientation="vertical"
        variant="contained"
        aria-label="vertical contained button group"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Button onClick={saveFlow} color="primary">
          Save Flow
        </Button>
        <Button onClick={clearFlow} color="error">
          Clear Flow
        </Button>
      </ButtonGroup>
    </Paper>
  );
};

export default Toolbar;
