// CreateUserForm.js
import React, { useState, useContext } from 'react';
import { Box, Button, TextField, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';

const CreateUserForm = () => {
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        password: '',
        phone_number: '',
        website_url: '',
        business_name: ''
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { sendRequestWithToast } = useContext(HttpRequestContext);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await sendRequestWithToast({
                apiPath: 'admin/register',
                type: 'POST',
                request: formData,
                successMessage: 'Utilisateur créé avec succès',
                errorMessage: 'Une erreur est survenue lors de la création de l\'utilisateur'
            });
            if (response) {
                navigate('/admin/users');
            }
        } catch (err) {
            setError('Une erreur est survenue lors de la création de l\'utilisateur');
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>
                Créer un nouvel utilisateur
            </Typography>
            {error && (
                <Typography color="error" sx={{ mb: 3 }}>
                    {error}
                </Typography>
            )}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Nom complet"
                            name="full_name"
                            value={formData.full_name}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Mot de passe"
                            name="password"
                            type="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Numéro de téléphone"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Site web"
                            name="website_url"
                            value={formData.website_url}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Nom de la société"
                            name="business_name"
                            value={formData.business_name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit">
                            Créer
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default CreateUserForm;
