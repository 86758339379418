import React, { useState, useEffect, useContext } from 'react';
import { Card, CardContent, Button, Typography, TextField, MenuItem, IconButton, Select } from '@mui/material';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { TagInputWithIcon } from '../../Components/InputFields';

const PropertyTypeCard = ({ type, name, defaultValue, acceptedValues, onDelete }) => (
    <div className="bg-white my-2 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow duration-300 overflow-hidden">
      <div className="flex justify-between items-center p-4 border-b border-gray-200">
        <span className="text-lg font-semibold text-gray-800">{name}</span>
        <IconButton onClick={onDelete} color="error" className="hover:bg-red-100 transition-colors duration-300">
          <FontAwesomeIcon icon={faTrash} size="sm" />
        </IconButton>
      </div>
      <div className="p-4 space-y-2">
        <div className="flex justify-between">
          <span className="text-sm font-medium text-gray-600">Type:</span>
          <span className="text-sm text-gray-800">{type}</span>
        </div>
        {defaultValue !== undefined && (
          <div className="flex justify-between">
            <span className="text-sm font-medium text-gray-600">Default Value:</span>
            <span className="text-sm text-gray-800">{defaultValue || 'None'}</span>
          </div>
        )}
        {acceptedValues && acceptedValues.length > 0 && (
          <div className="flex flex-col">
            <span className="text-sm font-medium text-gray-600 mb-1">Accepted Values:</span>
            <div className="flex flex-wrap gap-1">
              {acceptedValues.map((value, index) => (
                <span key={index} className="text-xs bg-gray-100 text-gray-800 px-2 py-1 rounded">
                  {value}
                </span>
              ))}
            </div>
          </div>
        )}
        {type === 'currency_amount' && (
          <div className="flex justify-between">
            <span className="text-sm font-medium text-gray-600">Currency:</span>
            <span className="text-sm text-gray-800">{defaultValue?.currency || 'Not set'}</span>
          </div>
        )}
      </div>
    </div>
  );
  

const AddProperty = ({ newProperty, setNewProperty, accepted_values, setaccepted_values }) => {
  switch (newProperty.type) {
    case 'string':
    case 'phone':
    case 'email':
    case 'number':
      return (
        <TextField
          name='default_value'
          value={newProperty.default_value || ''}
          onChange={(e) => setNewProperty(prev => ({ ...prev, default_value: e.target.value }))}
          placeholder='Default value'
          label='Default value'
          variant='outlined'
          fullWidth
          margin="normal"
          className="bg-white"
        />
      );
    case 'label':
      return (
        <>
          <TagInputWithIcon
            id="tag-input"
            placeholder="Enter options (press enter after each option)"
            label="Options"
            value={accepted_values}
            onChange={setaccepted_values}
            name="tags"
            className="mb-4"
          />
          <Select
            value={newProperty.default_value || ""}
            onChange={(e) => setNewProperty(prev => ({ ...prev, default_value: e.target.value }))}
            fullWidth
            variant='outlined'
            margin="normal"
            className="bg-white"
          >
            {accepted_values.map(type => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </>
      );
    case 'currency_amount':
      return (
        <>
          <TextField
            name='amount'
            type="number"
            value={newProperty.amount || ''}
            onChange={(e) => setNewProperty(prev => ({ ...prev, amount: e.target.value }))}
            placeholder='Amount'
            label='Amount'
            variant='outlined'
            fullWidth
            margin="normal"
            className="bg-white mb-4"
          />
          <Select
            name='currency'
            value={newProperty.currency || 'EUR'}
            onChange={(e) => setNewProperty(prev => ({ ...prev, currency: e.target.value }))}
            fullWidth
            variant='outlined'
            margin="normal"
            className="bg-white"
          >
            <MenuItem value="EUR">EUR (€)</MenuItem>
            <MenuItem value="USD">USD ($)</MenuItem>
            <MenuItem value="GBP">GBP (£)</MenuItem>
            <MenuItem value="CHF">CHF (CHF)</MenuItem>
          </Select>
        </>
      );
    case 'schedule':
      return(
        <>
          <TextField
              name='start'
              type="date"
              value={newProperty.start || ''}
              onChange={(e) => setNewProperty(prev => ({ ...prev, start: e.target.value }))}
              placeholder='Début'
              label='Début'
              variant='outlined'
              fullWidth
              margin="normal"
          />
          <TextField
              name='end'
              type="date"
              value={newProperty.end || ''}
              onChange={(e) => setNewProperty(prev => ({ ...prev, end: e.target.value }))}
              placeholder='Fin'
              label='Fin'
              variant='outlined'
              fullWidth
              margin="normal"
          />
          <TextField
              name='type'
              type="text"
              value={newProperty.type || ''}
              onChange={(e) => setNewProperty(prev => ({ ...prev, type: e.target.value }))}
              placeholder='Type'
              label='Type'
              variant='outlined'
              fullWidth
              margin="normal"
          />
        </>
    );
    default:
      return null;
  }
};

const EditCRMTypeProperties = ({ typeName, onClose }) => {
  const [properties, setProperties] = useState({});
  const [newProperty, setNewProperty] = useState({ type: "string" });
  const { sendRequestWithToast, sendRequest } = useContext(HttpRequestContext);
  const [accepted_values, setaccepted_values] = useState([]);
  const [availableTypes, setAvailableTypes] = useState([]);

  useEffect(() => {
    const types = [
      { value: "string", label: "Text" },
      { value: "date", label: "Date" },
      { value: "label", label: "Label" },
      { value: "phone", label: "Phone" },
      { value: "email", label: "Email" },
      { value: "schedule", label: "Schedule" },
      { value: "number", label: "Number" },
      { value: "currency_amount", label: "Currency Amount" }
    ];
    setAvailableTypes(types);
  }, []);

  useEffect(() => {
    if (typeName) {
      sendRequest({
        apiPath: `/crm/types/read/${typeName}`,
        type: 'GET'
      })
      .then(data => {
        const transformedProperties = {};
        for (let propName in data.properties) {
          transformedProperties[propName] = {
            type: data.properties[propName].type,
            default_value: data.properties[propName].value
          };
        }
        setProperties(transformedProperties);
      })
      .catch(error => {
        console.error("Failed to fetch properties:", error);
      });
    }
  }, [typeName, sendRequest]);

  const handleSaveProperties = async () => {
    const success = await sendRequestWithToast({
      apiPath: `/crm/type/${typeName}/properties`,
      type: 'POST',
      request: { properties: properties },
      successMessage: "Properties updated successfully.",
      errorMessage: "Failed to update properties."
    });
    if (success) {
      onClose();
    }
  };

  const handleAddProperty = () => {
    setProperties({
      ...properties,
      [newProperty.name]: {
        type: newProperty.type,
        default_value: newProperty.type === "date" || newProperty.type === "schedule" ? null : newProperty.default_value,
        accepted_values: accepted_values
      }
    });
    setNewProperty({ type: "string" });
    setaccepted_values([]);
  };

  const handleDeleteProperty = (propertyName) => {
    const updatedProperties = { ...properties };
    delete updatedProperties[propertyName];
    setProperties(updatedProperties);
  };

  return (
      <Card className="mx-auto bg-white shadow-xl rounded-lg overflow-hidden" >
        <CardContent className="p-8">
          <Typography variant="h4" className="text-3xl font-bold text-gray-800 mb-2">
            Edit CRM Type Properties
          </Typography>

          <div className="bg-gray-50 p-6 mb-6 rounded-lg shadow-inner">
            <Typography variant="h6" className="text-xl font-semibold text-gray-700 mb-4">
                Current Properties
            </Typography>
            <div className="space-y-3">
                {Object.keys(properties).map(propertyName => (
                <PropertyTypeCard
                    key={propertyName}
                    type={properties[propertyName].type}
                    name={propertyName}
                    defaultValue={properties[propertyName].default_value}
                    acceptedValues={properties[propertyName].accepted_values}
                    onDelete={() => handleDeleteProperty(propertyName)}
                />
                ))}
            </div>
          </div>

          <div className="bg-gray-50 p-6 rounded-lg shadow-inner">
            <Typography variant="h6" className="text-xl font-semibold text-gray-700 mb-4">
              Add New Property
            </Typography>
            <div className="space-y-4">
              <Select
                value={newProperty.type}
                onChange={(e) => {
                  setNewProperty(prev => ({ ...prev, type: e.target.value }));
                  if (e.target.value === "label") {
                    setaccepted_values(["Option1", "Option2"]);
                  }
                }}
                variant="outlined"
                fullWidth
                className="bg-white"
              >
                {availableTypes.map(type => (
                  <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                ))}
              </Select>

              <TextField
                name='name'
                value={newProperty.name || ""}
                onChange={(e) => setNewProperty(prev => ({ ...prev, name: e.target.value }))}
                placeholder='Property Name'
                label='Property Name'
                variant='outlined'
                fullWidth
                className="bg-white"
              />

              <AddProperty
                newProperty={newProperty}
                setNewProperty={setNewProperty}
                accepted_values={accepted_values}
                setaccepted_values={setaccepted_values}
              />

              <Button
                variant='contained'
                color='primary'
                onClick={handleAddProperty}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                startIcon={<FontAwesomeIcon icon={faPlus} />}
              >
                Add Property
              </Button>
            </div>
          </div>

          <div className="flex justify-end mt-8">
            <Button
              variant='contained'
              color='primary'
              onClick={handleSaveProperties}
              className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-lg"
            >
              Save Changes
            </Button>
          </div>
        </CardContent>
      </Card>
  );
};

export default EditCRMTypeProperties;