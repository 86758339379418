// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CustomNode.css */

.node {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #000;
    border-radius: 8px;
    padding: 10px;
    min-width: 150px;
    min-height: 100px;
    text-align: center;
  }
  
  .node-header {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 5px;
  }
  
  .node-content {
    font-size: 1em;
  }
  
  .trigger {
    background-color: #ffeb3b;
  }
  
  .wait {
    background-color: #2196f3;
    color: #fff;
  }
  
  .edit {
    background-color: #f44336;
    color: #fff;
  }
  
  .sendEmail {
    background-color: #4caf50;
    color: #fff;
  }
  
  .ifElse {
    background-color: #9c27b0;
    color: #fff;
  }
  .waitUntil {
    background-color: #ff9800; /* Choose a distinct color */
    color: #fff;
}

.sendEmailToChildren {
    background-color: #673ab7; /* Choose another distinct color */
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Flow/CustomNode.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;EACA;IACE,yBAAyB,EAAE,4BAA4B;IACvD,WAAW;AACf;;AAEA;IACI,yBAAyB,EAAE,kCAAkC;IAC7D,WAAW;AACf","sourcesContent":["/* CustomNode.css */\n\n.node {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    border: 2px solid #000;\n    border-radius: 8px;\n    padding: 10px;\n    min-width: 150px;\n    min-height: 100px;\n    text-align: center;\n  }\n  \n  .node-header {\n    font-weight: bold;\n    font-size: 1.2em;\n    margin-bottom: 5px;\n  }\n  \n  .node-content {\n    font-size: 1em;\n  }\n  \n  .trigger {\n    background-color: #ffeb3b;\n  }\n  \n  .wait {\n    background-color: #2196f3;\n    color: #fff;\n  }\n  \n  .edit {\n    background-color: #f44336;\n    color: #fff;\n  }\n  \n  .sendEmail {\n    background-color: #4caf50;\n    color: #fff;\n  }\n  \n  .ifElse {\n    background-color: #9c27b0;\n    color: #fff;\n  }\n  .waitUntil {\n    background-color: #ff9800; /* Choose a distinct color */\n    color: #fff;\n}\n\n.sendEmailToChildren {\n    background-color: #673ab7; /* Choose another distinct color */\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
