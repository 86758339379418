import React, { useState, useEffect, useContext } from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { HttpRequestContext } from './HttpRequestProvider';

const TypeDropdown = ({ selectedType, onTypeChange, userEmail = null }) => {
    const { sendRequestWithToast } = useContext(HttpRequestContext);
    const [types, setTypes] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCrmTypes = async () => {
            try {
                const apiPath = userEmail
                    ? `admin/crm/types/list?user_email=${userEmail}&show_hidden=true`
                    : 'crm/types/list';
                const response = await sendRequestWithToast({
                    apiPath,
                    type: 'GET',
                    errorMessage: "Erreur lors de la récupération des types CRM",
                });

                if (Array.isArray(response.rows) && response.rows.length > 0) {
                    setTypes(response.rows);
                } else {
                    setTypes([]);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des types CRM:', error);
                setError("Erreur lors de la récupération des types CRM.");
            }
        };

        fetchCrmTypes();
    }, [userEmail, sendRequestWithToast]);

    return (
        <FormControl fullWidth margin="normal">
            <InputLabel>Type de CRM</InputLabel>
            <Select
                value={selectedType || ''}
                onChange={(e) => onTypeChange(e.target.value)}
                displayEmpty
            >
                {types.length > 0 ? (
                    types.map((type) => (
                        <MenuItem key={type.type_name} value={type.type_name}>
                            {type.display_name}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>{error || "Aucun type disponible"}</MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default TypeDropdown;
