import React, { memo, useState, useContext } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import EditIcon from '@mui/icons-material/Edit';
import EditNodeModal from './EditNodeModal';
import { useParams } from 'react-router-dom';
import './CustomNode.css';

const iconStyles = 'z-10 text-gray-700';

const NodeWrapper = ({ children, id, type, data }) => {
  const { setNodes } = useReactFlow();
  const [openModal, setOpenModal] = useState(false);
  const { typeName } = useParams();

  const handleEditClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleSave = (formData) => {
    console.log('FormData:', formData); // Ajoute ce log pour vérifier le contenu de formData
  
    let newWorkflowData;
  
    if (type === 'trigger') {
      newWorkflowData = {
        node_type: 'trigger',
        trigger_type: formData.actionType === 'création' ? 'create' : formData.actionType === 'modification' ? 'edit' : 'property_modification',
        properties: formData.selectedProperties || [],
      };
    } else if (type === 'wait') {
      newWorkflowData = {
        node_type: 'wait',
        wait_time: formData.waitTime,
        wait_unit: formData.waitUnit,
      };
    } else if (type === 'waitUntil') {
      newWorkflowData = {
        node_type: 'waitUntil',
        date_property: formData.dateProperty,
        wait_time: formData.waitTime,
        wait_unit: formData.waitUnit,
        wait_direction: formData.waitDirection,
      };
    } else if (type === 'edit') {
      newWorkflowData = {
        node_type: 'edit',
        edit_property: formData.editProperty,
        edit_value: formData.editValue,
      };
    } else if (type === 'ifElse') {
      newWorkflowData = {
        node_type: 'ifElse',
        if_else_property: formData.ifElseProperty,
        if_else_value: formData.ifElseValue,
      };
    } else if (type === 'sendEmail') {
      newWorkflowData = {
        node_type: 'sendEmail',
        email_property: formData.emailProperty,
        email_property_type: formData.emailPropertyType,
        template_id: formData.templateId,
      };
    } else if (type === 'sendEmailToChildren') {
      newWorkflowData = {
        node_type: 'sendEmailToChildren',
        child_type: formData.childType,
        email_property: formData.emailProperty,
        template_id: formData.templateId,
      };
    }
  
    console.log('Saved data:', newWorkflowData);  // Ajoute un log ici pour voir les données sauvegardées
  
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                workflowData: newWorkflowData,  // Mise à jour du workflowData
              },
            }
          : node
      )
    );
  
    setOpenModal(false);  // Fermer la modal après sauvegarde
  };
  

  return (
    <div className="relative node-wrapper">
      <div className={`${iconStyles} absolute bottom-0 left-10`}>
        <button
          className={`${iconStyles} text-blue-500`}
          style={{ width: 25, height: 25 }}
          onClick={handleEditClick}
        >
          <EditIcon fontSize="small" />
        </button>
      </div>
      {children}
      <EditNodeModal
        open={openModal}
        onClose={handleCloseModal}
        onSave={handleSave}
        initialData={data}
        typeName={typeName}
        nodeType={type}
      />
    </div>
  );
};

const TriggerNode = ({ data, id }) => {
  const { workflowData = {} } = data;
  return (
    <NodeWrapper id={id} type="trigger" data={data} workflowData={workflowData}>
      <div className="node trigger">
        <Handle type="source" position={Position.Right} className="w-16 !bg-teal-500" />
        <div className="node-header">Trigger</div>
        <div className="node-content">
          <div>Type: {workflowData.trigger_type || 'N/A'}</div>
          {workflowData.trigger_type === 'property_modification' && (
            <div>Properties: {workflowData.properties ? workflowData.properties.join(', ') : 'N/A'}</div>
          )}
        </div>
      </div>
    </NodeWrapper>
  );
};

const WaitNode = ({ data, id }) => {
  const { workflowData = {} } = data;
  return (
    <NodeWrapper id={id} type="wait" data={data} workflowData={workflowData}>
      <div className="node wait">
        <Handle type="target" position={Position.Left} className="w-16 !bg-teal-500" />
        <Handle type="source" position={Position.Right} className="w-16 !bg-teal-500" />
        <div className="node-header">Wait</div>
        <div className="node-content">
          <div>Time: {workflowData.wait_time || 'N/A'} {workflowData.wait_unit || 'N/A'}</div>
        </div>
      </div>
    </NodeWrapper>
  );
};


const WaitUntilNode = ({ data, id }) => {
  const { workflowData = {} } = data;

  console.log('Rendered workflowData for waitUntil:', workflowData);  // Ajoute un log ici

  return (
    <NodeWrapper id={id} type="waitUntil" data={data} workflowData={workflowData}>
      <div className="node waitUntil">
        <Handle type="target" position={Position.Left} className="w-16 !bg-teal-500" />
        <Handle type="source" position={Position.Right} className="w-16 !bg-teal-500" />
        <div className="node-header">Wait Until</div>
        <div className="node-content">
          <div>Property: {workflowData.date_property || 'N/A'}</div>
          <div>Time: {workflowData.wait_time || 'N/A'} {workflowData.wait_unit || 'N/A'}</div>
          <div>Direction: {workflowData.wait_direction || 'before'}</div>
        </div>
      </div>
    </NodeWrapper>
  );
};


const EditNode = ({ data, id }) => {
  const { workflowData = {} } = data;
  return (
    <NodeWrapper id={id} type="edit" data={data} workflowData={workflowData}>
      <div className="node edit">
        <Handle type="target" position={Position.Left} className="w-16 !bg-teal-500" />
        <Handle type="source" position={Position.Right} className="w-16 !bg-teal-500" />
        <div className="node-header">Edit</div>
        <div className="node-content">
          <div>Property: {workflowData.edit_property || 'N/A'}</div>
          <div>Value: {workflowData.edit_value || 'N/A'}</div>
        </div>
      </div>
    </NodeWrapper>
  );
};

const SendEmailNode = ({ data, id }) => (
  <NodeWrapper id={id} type="sendEmail" data={data}>
    <div className="node sendEmail">
      <Handle type="target" position={Position.Left} className="w-16 !bg-teal-500" />
      <Handle type="source" position={Position.Right} className="w-16 !bg-teal-500" />
      <div className="node-header">Send Email</div>
      <div className="node-content">
        <div>Email Property: {data.workflowData?.email_property || 'N/A'}</div>
        <div>Email Property Type: {data.workflowData?.email_property_type || 'N/A'}</div>
        <div>Template: {data.workflowData?.template_id || 'N/A'}</div>
      </div>
    </div>
  </NodeWrapper>
);

const SendEmailToChildrenNode = ({ data, id }) => (
  <NodeWrapper id={id} type="sendEmailToChildren" data={data}>
    <div className="node sendEmailToChildren">
      <Handle type="target" position={Position.Left} className="w-16 !bg-teal-500" />
      <Handle type="source" position={Position.Right} className="w-16 !bg-teal-500" />
      <div className="node-header">Send Email to Children</div>
      <div className="node-content">
        <div>Child Type: {data.workflowData?.child_type || 'N/A'}</div>
        <div>Email Property: {data.workflowData?.email_property || 'N/A'}</div>
        <div>Template: {data.workflowData?.template_id || 'N/A'}</div>
      </div>
    </div>
  </NodeWrapper>
);

const IfElseNode = ({ data, id }) => {
  const { workflowData = {} } = data;
  return (
    <NodeWrapper id={id} type="ifElse" data={data} workflowData={workflowData}>
      <div className="node ifElse">
        <Handle type="target" position={Position.Left} className="w-16 !bg-teal-500" />
        <div className="node-header">If-Else</div>
        <div className="node-content">
          <div>Property: {workflowData.if_else_property || 'N/A'}</div>
          <div>Value: {workflowData.if_else_value || 'N/A'}</div>
        </div>
        <Handle type="source" position={Position.Right} id="true" className="w-16 !bg-teal-500" />
        <Handle type="source" position={Position.Bottom} id="false" className="w-16 !bg-teal-500" />
      </div>
    </NodeWrapper>
  );
};

export { TriggerNode, WaitNode, WaitUntilNode, EditNode, SendEmailNode, IfElseNode, SendEmailToChildrenNode };

