import React from 'react';
import { Card, CardContent, Typography, Box, Divider, Grid } from '@mui/material';
import { TrendingUp, TrendingDown, BarChart, ArrowDownward, ArrowUpward, ShowChart } from '@mui/icons-material';

const Scorecard = ({ 
  label, 
  dataSet, 
  compareDataSet = null, 
  valueExtractor, 
  aggregation = "sum", 
  formatter = value => value, 
  color, 
  icon 
}) => {
  // Aggregation resolver function
  const aggregateData = (data, method) => {
    if (!data || data.length === 0) return 0;

    const values = data.map(valueExtractor);
    switch (method) {
      case 'avg':
        return values.reduce((sum, val) => sum + val, 0) / values.length;
      case 'min':
        return Math.min(...values);
      case 'max':
        return Math.max(...values);
      case 'sum':
      default:
        return values.reduce((sum, val) => sum + val, 0);
    }
  };

  // Perform aggregation for the main dataset
  const aggregatedValue = aggregateData(dataSet, aggregation);

  // If a compare dataset is provided, perform aggregation and calculate change
  let change = null;
  if (compareDataSet) {
    const compareValue = aggregateData(compareDataSet, aggregation);
    change = ((aggregatedValue - compareValue) / compareValue) * 100;
  }

  // Get number of data points and additional insights
  const dataPoints = dataSet ? dataSet.length : 0;
  const minValue = dataPoints > 0 ? aggregateData(dataSet, 'min') : null;
  const maxValue = dataPoints > 0 ? aggregateData(dataSet, 'max') : null;
  const avgValue = dataPoints > 0 ? aggregateData(dataSet, 'avg') : null;

  return (
    <Card sx={{ borderRadius: 3, boxShadow: 6, p: 3, bgcolor: '#f5f5f5' }}>
      <CardContent>
        {/* Header Section */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h5" fontWeight="bold" color="primary.dark">{label}</Typography>
          <Box sx={{ 
            bgcolor: color, 
            color: 'white', 
            borderRadius: '50%', 
            width: 50, 
            height: 50, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            boxShadow: 3
          }}>
            {icon}
          </Box>
        </Box>

        {/* Main Value Section */}
        <Typography variant="h3" fontWeight="bold" color="text.primary">
          {formatter(aggregatedValue)}
        </Typography>
        {change !== null && (
          <Typography variant="h6" sx={{ color: change >= 0 ? 'success.main' : 'error.main', mt: 1, display: 'flex', alignItems: 'center' }}>
            {change >= 0 ? <TrendingUp fontSize="small" /> : <TrendingDown fontSize="small" />} {change >= 0 ? '+' : ''}{change.toFixed(2)}%
          </Typography>
        )}

        {/* Divider for better visual separation */}
        <Divider sx={{ my: 3 }} />

        {/* Additional Insights Section */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center" bgcolor="#e0f7fa" p={2} borderRadius={2}>
              <Typography variant="body1" color="text.secondary" display="flex" alignItems="center">
                <BarChart fontSize="small" sx={{ mr: 1 }} /> Nombre:
              </Typography>
              <Typography variant="body1" fontWeight="medium" color="text.primary">
                {dataPoints}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center" bgcolor="#e0f7fa" p={2} borderRadius={2}>
              <Typography variant="body1" color="text.secondary" display="flex" alignItems="center">
                <ShowChart fontSize="small" sx={{ mr: 1 }} /> Moy:
              </Typography>
              <Typography variant="body1" fontWeight="medium" color="text.primary">
                {avgValue !== null ? formatter(avgValue) : 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center" bgcolor="#e0f7fa" p={2} borderRadius={2}>
              <Typography variant="body1" color="text.secondary" display="flex" alignItems="center">
                <ArrowDownward fontSize="small" sx={{ mr: 1 }} /> Min:
              </Typography>
              <Typography variant="body1" fontWeight="medium" color="text.primary">
                {minValue !== null ? formatter(minValue) : 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center" bgcolor="#e0f7fa" p={2} borderRadius={2}>
              <Typography variant="body1" color="text.secondary" display="flex" alignItems="center">
                <ArrowUpward fontSize="small" sx={{ mr: 1 }} /> Max:
              </Typography>
              <Typography variant="body1" fontWeight="medium" color="text.primary">
                {maxValue !== null ? formatter(maxValue) : 'N/A'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Scorecard;