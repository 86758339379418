import React from 'react';
import { User, Mail, MapPin, Briefcase, Building, CreditCard } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { resolveValueObject } from './ValueObjects';

export class ContainerValueObject {
  constructor(data) {
    this.properties = {};
    for (const [key, value] of Object.entries(data)) {
      this[key] = resolveValueObject(value);
      this.properties[key] = resolveValueObject(value);
    }
  }

  getIcon(key) {
    switch (key) {
      case 'firstname':
      case 'lastname':
        return <User className="w-5 h-5 text-blue-500" />;
      case 'email':
        return <Mail className="w-5 h-5 text-red-500" />;
      case 'address_line1':
      case 'address_line2':
        return <MapPin className="w-5 h-5 text-yellow-500" />;
      case 'entity_name':
        return <Building className="w-5 h-5 text-purple-500" />;
      case 'bce_number':
      case 'vat_number':
        return <CreditCard className="w-5 h-5 text-green-500" />;
      default:
        return <Briefcase className="w-5 h-5 text-gray-500" />;
    }
  }

  render(propertiesToRender = null) {
    const properties = propertiesToRender ? 
      Object.entries(this.properties).filter(([key]) => propertiesToRender.includes(key)) : 
      Object.entries(this.properties);

    return (
      <AnimatePresence>
        <motion.div
          key="containerValueObjectCard"
          initial={{ x: '100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '100%', opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
        >
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{this.properties["to_string"].value}</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              {properties.map(([key, valueObject], index) => (
                <div
                  key={key}
                  className={`${
                    index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                  } px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}
                >
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    {this.getIcon(key)}
                    <span className="ml-2">{valueObject.displayName || key}</span>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {valueObject.value !== null ? valueObject.render() : <span className="text-gray-400 italic">Non renseigné</span>}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </motion.div>
      </AnimatePresence>
    );
  }

  renderInput(onChange, propertiesToRender = null) {
    const properties = propertiesToRender ? 
      Object.entries(this.properties).filter(([key]) => propertiesToRender.includes(key)) : 
      Object.entries(this.properties);

    return (
      <div className="container-value-object-input">
        {properties.map(([key, valueObject]) => (
          <div key={key} className="property-input-row">
            <label className="property-label">{valueObject.displayName || key}:</label>
            <div className="property-input">
              {valueObject.renderInput((propertyName, newValue) => {
                // Update the individual property and trigger the onChange callback
                this.properties[key].setValue(newValue);
                onChange(this);
              })}
            </div>
          </div>
        ))}
      </div>
    );
  }
}