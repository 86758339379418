import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Tooltip, 
  IconButton, 
  Popover,
  List,
  ListItem,
  ListItemText,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { Info, ChevronLeft, ChevronRight } from 'lucide-react';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const GanttChartPlot = ({ startDate, endDate, tasks }) => {
  const [hoveredTask, setHoveredTask] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [visibleMonths, setVisibleMonths] = useState(12);
  const chartRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    canvasRef.current = document.createElement('canvas');
    const context = canvasRef.current.getContext('2d');
    if (context) {
      context.font = '14px Arial';
    }
  }, []);


  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const start = new Date(startDate);
  const end = new Date(endDate);
  const today = new Date();
  const months = [];
  let currentDate = new Date(start);

  while (currentDate <= end) {
    months.push({
      name: currentDate.toLocaleString('default', { month: 'short' }),
      year: currentDate.getFullYear(),
      days: getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth())
    });
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;

  const getPosition = (taskStart, duration) => {
    const taskStartDate = new Date(taskStart);
    const daysSinceStart = Math.ceil((taskStartDate - start) / (1000 * 60 * 60 * 24));
    const left = (daysSinceStart / (visibleMonths * 30)) * 100;
    const width = (duration / (visibleMonths * 30)) * 100;
    return { left: `${left}%`, width: `${width}%` };
  };

  const getTextWidth = (text) => {
    if (!canvasRef.current) {
      console.warn('Canvas not available for text measurement');
      return 0;
    }
    const context = canvasRef.current.getContext('2d');
    if (!context) {
      console.warn('Canvas context not available');
      return 0;
    }
    const metrics = context.measureText(text);
    return metrics.width;
  };

  const getLabelPosition = (taskStart, duration, taskName) => {
    const position = getPosition(taskStart, duration);
    const left = parseFloat(position.left);
    const width = parseFloat(position.width);
    
    const textWidth = getTextWidth(taskName) + 10;
    const chartWidth = chartRef.current ? chartRef.current.offsetWidth : 1000;
    const taskWidth = (width / 100) * chartWidth;
    
    if (taskWidth > textWidth + 20) return 'inside';
    if (left + width + (textWidth / chartWidth * 100) <= 100) return 'right';
    if (left - (textWidth / chartWidth * 100) >= 0) return 'left';
    return 'right';
  };

  const handleTaskClick = (event, task) => {
    event.preventDefault();
    setAnchorEl({
      top: event.clientY,
      left: event.clientX,
    });
    setSelectedTask(task);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedTask(null);
  };

  const handlePrevMonths = () => {
    setVisibleMonths(Math.max(3, visibleMonths - 1));
  };

  const handleNextMonths = () => {
    setVisibleMonths(Math.min(months.length, visibleMonths + 1));
  };

  const groupMonthsByYear = () => {
    const years = {};
    months.forEach(month => {
      if (!years[month.year]) {
        years[month.year] = [];
      }
      years[month.year].push(month);
    });
    return years;
  };

  const yearGroups = groupMonthsByYear();

  const visibleMonthsData = months.slice(0, visibleMonths);

  const groupVisibleMonthsByYear = () => {
    const years = {};
    visibleMonthsData.forEach(month => {
      if (!years[month.year]) {
        years[month.year] = [];
      }
      years[month.year].push(month);
    });
    return years;
  };
  
  const visibleYearGroups = groupVisibleMonthsByYear();

  const todayPosition = () => {
    const todayDate = today.setHours(0, 0, 0, 0);
    const visibleStartDate = new Date(startDate).setHours(0, 0, 0, 0);
    const visibleEndDate = addDays(visibleStartDate, visibleMonths * 30).getTime();

    if (todayDate < visibleStartDate || todayDate > visibleEndDate) {
      return null;
    }

    const daysSinceVisibleStart = Math.ceil((todayDate - visibleStartDate) / (1000 * 60 * 60 * 24));
    const visibleDays = visibleMonths * 30;

    return (daysSinceVisibleStart / visibleDays) * 100;
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength - 3) + '...';
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="container mx-auto p-4">
        <Typography variant="h4" component="h1" gutterBottom>
          Gantt Chart
        </Typography>
        <Paper elevation={3} className="overflow-hidden">
          <Box className="flex items-center justify-between p-2 bg-gray-100">
            <Box className="flex flex-1 flex-col">
              <Box className="flex justify-around">
                <IconButton onClick={handlePrevMonths} disabled={visibleMonths === 3}>
                  <ChevronLeft />
                </IconButton>
                {Object.entries(visibleYearGroups).map(([year, yearMonths]) => (
                  <Typography
                    key={year}
                    variant="subtitle1"
                    className="font-semibold"
                    style={{
                      flex: yearMonths.length,
                      textAlign: 'center',
                    }}
                  >
                    {year}
                  </Typography>
                ))}
                <IconButton onClick={handleNextMonths} disabled={visibleMonths === months.length}>
                  <ChevronRight />
                </IconButton>
              </Box>
              <Box className="flex justify-around">
                {months.slice(0, visibleMonths).map((month, index) => (
                  <Typography
                    key={`${month.name}-${month.year}`}
                    variant="subtitle2"
                    className="font-semibold"
                    style={{
                      flex: 1,
                      textAlign: 'center',
                    }}
                  >
                    {month.name}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
          <Box ref={chartRef} className="relative" style={{ height: `auto`, overflowY: 'auto', overflowX: 'hidden' }}>
            {todayPosition() !== null && (
              <Box
                className="absolute top-0 bottom-0 w-px bg-red-500"
                style={{
                  left: `${todayPosition()}%`,
                  height: `100%`,
                }}
              />
            )}
            <Box style={{ height: `${tasks.length * 30}px`, position: 'relative' }}>
              {Array.from({ length: visibleMonths + 1 }).map((_, index) => (
                <Box
                  key={`separator-${index}`}
                  className="absolute top-0 bottom-0 w-px bg-gray-300"
                  style={{
                    left: `${(index / visibleMonths) * 100}%`,
                    height: '100%',
                  }}
                />
              ))}
              
              {tasks.map((task, index) => {
                const position = getPosition(task.start, task.duration);
                const labelPosition = getLabelPosition(task.start, task.duration, task.name);
                return (
                  <Tooltip
                    key={task.id}
                    title={`${task.name}: ${task.start} - ${addDays(new Date(task.start), task.duration).toISOString().split('T')[0]}`}
                    arrow
                  >
                    <Box
                      className="absolute h-6 bg-blue-500 rounded-sm flex items-center justify-center text-white text-xs cursor-pointer transition-all duration-200 hover:shadow-lg"
                      style={{
                        ...position,
                        top: `${index * 30}px`,
                      }}
                      onMouseEnter={() => setHoveredTask(task.id)}
                      onMouseLeave={() => setHoveredTask(null)}
                      onClick={(e) => handleTaskClick(e, task)}
                    >
                      <Typography
                        variant="caption"
                        className={`
                          whitespace-nowrap
                          ${labelPosition === 'inside' ? 'px-1 text-center w-full' : 'absolute'}
                          ${labelPosition === 'left' ? 'right-full mr-1' : ''}
                          ${labelPosition === 'right' ? 'left-full ml-1' : ''}
                          ${labelPosition !== 'inside' ? 'text-gray-700' : ''}
                        `}
                        style={{ 
                          maxWidth: labelPosition === 'inside' ? '100%' : 'none',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {truncateText(task.name, 60)}
                      </Typography>
                      {hoveredTask === task.id && (
                        <IconButton
                          size="small"
                          className="absolute right-0 transform scale-75"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTaskClick(e, task);
                          }}
                        >
                          <Info fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  </Tooltip>
                );
              })}
            </Box>
          </Box>
        </Paper>
        <Popover
          open={Boolean(anchorEl)}
          anchorReference="anchorPosition"
          anchorPosition={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: { maxWidth: '300px' }
          }}
        >
          {selectedTask && (
            <List>
              <ListItem>
                <ListItemText primary="Task" secondary={selectedTask.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Start Date" secondary={selectedTask.start} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Duration" secondary={`${selectedTask.duration} days`} />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="End Date" 
                  secondary={addDays(new Date(selectedTask.start), selectedTask.duration).toISOString().split('T')[0]} 
                />
              </ListItem>
            </List>
          )}
        </Popover>
      </Box>
    </ThemeProvider>
  );
};


function GanttChart() {
  const startDate = '2024-01-01';
  const endDate = '2025-12-31';
  const tasks = [
    { id: 1, name: 'Project Initiation', start: '2024-01-05', duration: 1 },
    { id: 2, name: 'Requirements Gathering', start: '2024-01-15', duration: 20 },
    { id: 3, name: 'System Design', start: '2024-02-05', duration: 30 },
    { id: 4, name: 'Development Phase 1', start: '2024-03-07', duration: 45 },
    { id: 5, name: 'Review & Testing 1', start: '2024-04-22', duration: 15 },
    { id: 6, name: 'Development Phase 2', start: '2024-05-07', duration: 40 },
    { id: 7, name: 'Review & Testing 2', start: '2024-06-17', duration: 15 },
    { id: 8, name: 'Documentation', start: '2024-07-02', duration: 20 },
    { id: 9, name: 'User Training', start: '2024-07-22', duration: 25 },
    { id: 10, name: 'System Deployment', start: '2024-08-16', duration: 15 },
    { id: 11, name: 'Post-deployment Support', start: '2024-09-01', duration: 60 },
    { id: 12, name: 'Project Closure', start: '2024-11-01', duration: 20 },
    { id: 13, name: 'Marketing Campaign', start: '2024-03-01', duration: 90 },
    { id: 14, name: 'Customer Feedback Collection', start: '2024-06-01', duration: 120 },
    { id: 15, name: 'Product Enhancement Planning', start: '2024-09-15', duration: 45 },
    { id: 16, name: 'Vendor Negotiations', start: '2024-02-15', duration: 30 },
    { id: 17, name: 'Hardware Upgrades', start: '2024-04-01', duration: 25 },
    { id: 18, name: 'Security Audit', start: '2024-08-01', duration: 40 },
    { id: 19, name: 'Performance Optimization', start: '2024-10-01', duration: 35 },
    { id: 20, name: 'Year-end Review', start: '2024-12-01', duration: 20 },
    { id: 10, name: 'System Deployment', start: '2025-08-16', duration: 15 },
    { id: 11, name: 'Post-deployment Support', start: '2025-09-01', duration: 60 },
    { id: 12, name: 'Project Closure', start: '2025-11-01', duration: 20 },
  ];

  return (
    <div className="App">
      <GanttChartPlot startDate={startDate} endDate={endDate} tasks={tasks} />
    </div>
  );
}

export default GanttChart;