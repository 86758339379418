import React, { useState } from 'react';
import { Avatar, Box, Typography, Chip, Tooltip, IconButton, Select, MenuItem } from '@mui/material';
import { Icon } from './Icons'; 
import Modal from './Modal';
import PDFViewerObject from './PDFViewerObject';

export const CheckboxCellRenderer = ({ isSelected, onToggle }) => (
  <input type="checkbox" checked={isSelected} onChange={onToggle} />
);

export const DropdownCellRenderer = ({ rowId, value, options = [], onChange }) => {
  const handleChange = (event) => {
    onChange(rowId, event.target.value);
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export const JsonCellRenderer = ({ value }) => {
  const jsonString = JSON.stringify(value, null, 2);
  const truncatedString = jsonString.length > 90 ? `${jsonString.substring(0, 90)}...` : jsonString;

  return (
    <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
      {truncatedString}
    </pre>
  );
};

export const CurrencyAmountCellRenderer = ({ value }) => {
  if (!value) {
    return <span>Invalid currency amount</span>;
  }

  if (typeof value === 'string') {
    return <span>{value}</span>;
  }

  if (typeof value === 'object' && 'amount' in value && 'currency' in value) {
    return <span>{`${value.currency} ${parseFloat(value.amount).toFixed(2)}`}</span>;
  }

  return <span>Invalid currency amount</span>;
};

export const ImageCellRenderer = ({ value }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Tooltip title="Voir l'image">
        <IconButton onClick={toggleModal}>
          <Icon iconName="image" type="mdi" />
        </IconButton>
      </Tooltip>
      <Modal isOpen={isModalOpen} closeModal={toggleModal}>
        <PDFViewerObject filePath={value} />
      </Modal>
    </>
  );
};

export const TextCellRenderer = ({ value }) => (
  <Typography variant="body2" color="text.primary">
    {value === null || value === undefined ? '' : String(value)}
  </Typography>
);

export const BooleanCellRenderer = ({ value }) => (
  <Chip
    label={value ? "Vrai" : "Faux"}
    color={value ? "success" : "default"}
    size="small"
    variant="outlined"
  />
);

export const ScheduleCellRenderer = ({ value }) => {
  if (!value) {
    return <Typography variant="body2">No Schedule</Typography>;
  }

  return (
    <Box>
      <Typography variant="body2" color="text.primary">
        Start: {value.start}
      </Typography>
      <Typography variant="body2" color="text.primary">
        End: {value.end}
      </Typography>
      <Typography variant="body2" color="text.primary">
        Type: {value.type}
      </Typography>
    </Box>
  );
}

export const SelectionCellRenderer = ({ isSelected, onToggle }) => (
  <input type="checkbox" checked={isSelected} onChange={onToggle} />
);

export const ActionCellRenderer = ({ onClickAction, tooltipContent, row, actionType = 'edit' }) => {
  const actionSettings = {
    edit: { iconName: "pencil", color: "primary", tooltip: "Modifier" },
    delete: { iconName: "trash-can", color: "secondary", tooltip: "Supprimer" },
    email: { iconName: "email", color: "primary", tooltip: "Envoyer Email" },
    viewPdf: { iconName: "file-pdf-box", color: "success", tooltip: "Voir PDF" }
  };

  const { iconName, color, tooltip } = actionSettings[actionType] || actionSettings.edit;

  return (
    <Tooltip title={tooltipContent || tooltip}>
      <IconButton color={color} onClick={(e) => { e.preventDefault(); onClickAction(row); }}>
        <Icon iconName={iconName} type='mdi' />
      </IconButton>
    </Tooltip>
  );
};
