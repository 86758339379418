// ImportTransactions.js

import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    IconButton,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Collapse,
    FormControl,
    InputLabel,
    TextField
} from '@mui/material';
import { CloudUpload, ExpandMore, ExpandLess } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import Papa from 'papaparse';
import { parse, format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';

const numberFormats = [
    { label: '1,234.56', value: 'commaDot' },
    { label: '1.234,56', value: 'dotComma' },
    { label: '1234.56', value: 'noSeparatorDot' },
    { label: '1234,56', value: 'noSeparatorComma' },
];

const ImportTransactions = () => {
    const { sendRequest } = useContext(HttpRequestContext);
    const [file, setFile] = useState(null);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [mergedData, setMergedData] = useState([]);
    const [initialFilter, setInitialFilter] = useState([]);
    const [filters, setFilters] = useState({});
    const [filterExpanded, setFilterExpanded] = useState(false);
    const [uniqueIdColumns, setUniqueIdColumns] = useState([]);
    const [amountColumn, setAmountColumn] = useState('');
    const [currency, setCurrency] = useState('EUR');
    const [numberFormat, setNumberFormat] = useState('dotComma');
    const [dateColumn, setDateColumn] = useState('');
    const [idBuilderExpanded, setIdBuilderExpanded] = useState(false);
    const [datePreview, setDatePreview] = useState([]);
    const [amountPreview, setAmountPreview] = useState([]);
    const [presets, setPresets] = useState([]);
    const [presetName, setPresetName] = useState('');
    const [regexFilters, setRegexFilters] = useState({});
    const [thirdPartyIdColumns, setThirdPartyIdColumns] = useState([]);
    const [thirdPartyPreview, setThirdPartyPreview] = useState([]);
    const [skipLinesCount, setSkipLinesCount] = useState(0);
    const [skipUntilText, setSkipUntilText] = useState('');
    const [groupByColumns, setGroupByColumns] = useState([]);
    const [mergeRules, setMergeRules] = useState([]);

    const parseDate = (value) => {
        if (!value || typeof value !== 'string') {
            return null;
        }

        let parsedDate = parseISO(value);

        if (isNaN(parsedDate)) {
            parsedDate = parse(value, 'yyyy-MM-dd HH:mm:ss xx', new Date());
        }

        if (isNaN(parsedDate)) {
            parsedDate = parse(value, 'dd/MM/yyyy', new Date());
        }

        if (isNaN(parsedDate)) {
            return null;
        }

        return parsedDate;
    };

    const parseAmount = (value) => {
        if (typeof value === 'number') return value;

        if (value === undefined) return 0;
        let normalizedValue = value;
        if (numberFormat === 'commaDot') {
            normalizedValue = normalizedValue.replace(/,/g, '').replace(/\./g, '.');
        } else if (numberFormat === 'dotComma') {
            normalizedValue = normalizedValue.replace(/\./g, '').replace(/,/g, '.');
        } else if (numberFormat === 'noSeparatorDot') {
            normalizedValue = normalizedValue.replace(/,/g, '');
        } else if (numberFormat === 'noSeparatorComma') {
            normalizedValue = normalizedValue.replace(/\./g, '');
        }

        return parseFloat(normalizedValue);
    };

    const applyInitialFilter = (data) => {
        return data.filter((row) => {
            return initialFilter.every((column) => row[column] !== null && row[column] !== undefined && row[column] !== '');
        });
    };

    const applyFilters = (data) => {
        return data.filter((row) => {
            return Object.keys(filters).every((column) => {
                const filterValue = filters[column];
                if (filterValue.length === 0) return true;
                return filterValue.includes(row[column]);
            }) && Object.keys(regexFilters).every((column) => {
                const regex = new RegExp(regexFilters[column]);
                return regex.test(row[column]);
            });
        });
    };

    const createUniqueId = (row) => {
        return uniqueIdColumns.map((col) => row[col]).join('-');
    };

    const createThirdPartyId = (row) => {
        return thirdPartyIdColumns.map((col) => row[col]).join('-');
    };

    const getDateColumns = (data) => {
        return columns.filter((column) => {
            const validDates = data.map(row => parseDate(row[column])).filter(date => date !== null);
            return validDates.length / data.length >= 0.5;
        });
    };

    const getAmountPreview = (data) => {
        if (!amountColumn) return [];
        const amounts = data.map(row => ({ original: String(row[amountColumn]), parsed: parseAmount(row[amountColumn]) }));
        const nonZeroAmounts = amounts.filter(item => !isNaN(item.parsed) && item.parsed !== 0);
        if (nonZeroAmounts.length === 0) return amounts.slice(0, 5);

        const closestToZero = nonZeroAmounts.reduce((prev, curr) => (Math.abs(curr.parsed) < Math.abs(prev.parsed) ? curr : prev), nonZeroAmounts[0]);
        const largest = amounts.reduce((prev, curr) => (curr.parsed > prev.parsed ? curr : prev), nonZeroAmounts[0]);
        const mostDigits = amounts.reduce((prev, curr) => (curr.original.length > prev.original.length ? curr : prev), nonZeroAmounts[0]);
        const invalidAmounts = amounts.filter(item => isNaN(item.parsed)).slice(0, 2);
        const others = amounts.filter(item => item !== closestToZero && item !== largest && item !== mostDigits).slice(0, 2 - invalidAmounts.length);

        return [closestToZero, largest, mostDigits, ...invalidAmounts, ...others];
    };

    const groupAndMergeData = (data, groupByColumns, mergeRules) => {
        const groups = {};
        data.forEach(row => {
            const groupKey = groupByColumns.map(col => row[col]).join('|');
            if (!groups[groupKey]) {
                groups[groupKey] = [];
            }
            groups[groupKey].push(row);
        });

        const mergedData = [];
        for (const groupKey in groups) {
            const rows = groups[groupKey];
            const mergedRow = mergeRows(rows, mergeRules);
            mergedData.push(mergedRow);
        }

        return mergedData;
    };

    const mergeRows = (rows, mergeRules) => {
        const mergedRow = {};
        const columns = Object.keys(rows[0]);

        columns.forEach(column => {
            let value = null;

            for (const rule of mergeRules) {
                const matchingRows = rows.filter(row => row[rule.column] === rule.value);
                for (const row of matchingRows) {
                    if (row[column]) {
                        value = row[column];
                        break;
                    }
                }
                if (value !== null) {
                    break;
                }
            }

            if (value === null) {
                for (const row of rows) {
                    if (row[column]) {
                        value = row[column];
                        break;
                    }
                }
            }

            mergedRow[column] = value;
        });

        return mergedRow;
    };

    // When file is uploaded and parsed
    useEffect(() => {
        if (file) {
            Papa.parse(file, {
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                beforeFirstChunk: (chunk) => {
                    let lines = chunk.split(/\r\n|\r|\n/);

                    // Skip a given number of lines
                    if (skipLinesCount > 0) {
                        lines = lines.slice(skipLinesCount);
                    }

                    // Skip lines until a line contains a given text
                    if (skipUntilText) {
                        const index = lines.findIndex((line) => line.includes(skipUntilText));
                        if (index >= 0) {
                            lines = lines.slice(index); // Include the line containing the text
                        }
                    }

                    return lines.join('\n');
                },
                complete: (results) => {
                    setColumns(results.meta.fields || []);
                    setData(results.data || []);
                    setInitialFilter([]);
                    setFilters(
                        results.meta.fields.reduce(
                            (acc, field) => ({ ...acc, [field]: [] }),
                            {}
                        )
                    );
                },
            });
        }
    }, [file, skipLinesCount, skipUntilText]);

    // Apply initial filters, filters, grouping to data, update mergedData
    useEffect(() => {
        let newData = applyInitialFilter(data);
        newData = applyFilters(newData);

        if (groupByColumns.length > 0) {
            newData = groupAndMergeData(newData, groupByColumns, mergeRules);
        }

        setMergedData(newData);
    }, [data, initialFilter, filters, regexFilters, groupByColumns, mergeRules]);

    // Update previews when mergedData changes
    useEffect(() => {
        if (dateColumn) {
            const previewData = mergedData.slice(0, 5).map((row) => {
                const parsedDate = parseDate(row[dateColumn]);
                return {
                    original: row[dateColumn],
                    parsed: parsedDate ? format(parsedDate, 'd MMMM yyyy', { locale: fr }) : 'N/A',
                };
            });
            setDatePreview(previewData);
        }
    }, [dateColumn, mergedData]);

    useEffect(() => {
        if (amountColumn) {
            const previewData = getAmountPreview(mergedData);
            setAmountPreview(previewData);
        }
    }, [amountColumn, numberFormat, mergedData]);

    useEffect(() => {
        fetchPresets();
    }, []);

    useEffect(() => {
        if (thirdPartyIdColumns.length > 0) {
            getThirdPartyPreview(mergedData);
        }
    }, [thirdPartyIdColumns, mergedData]);

    const fetchPresets = async () => {
        try {
            const response = await sendRequest({
                apiPath: 'presets/list',
                type: 'GET',
            });
            setPresets(response.rows);
        } catch (error) {
            console.error("Error fetching presets:", error);
        }
    };

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
        // Resetting all relevant states to default values
        setColumns([]);
        setData([]);
        setMergedData([]);
        setInitialFilter([]);
        setFilters({});
        setUniqueIdColumns([]);
        setAmountColumn('');
        setCurrency('EUR');
        setNumberFormat('dotComma');
        setDateColumn('');
        setIdBuilderExpanded(false);
        setDatePreview([]);
        setAmountPreview([]);
        setPresetName('');
        setRegexFilters({});
        setThirdPartyIdColumns([]);
        setSkipLinesCount(0);
        setSkipUntilText('');
        setGroupByColumns([]);
        setMergeRules([]);
    };

    const handleInitialFilterChange = (event) => {
        const { value } = event.target;
        setInitialFilter(typeof value === 'string' ? value.split(',') : value);
    };

    const handleDateColumnChange = (event) => {
        const value = event.target.value;
        setDateColumn(value);
    };

    const handleNumberFormatChange = (event) => {
        setNumberFormat(event.target.value);
    };

    const handleFilterChange = (column, value) => {
        setFilters({ ...filters, [column]: value });
    };

    const handleUniqueIdColumnsChange = (event) => {
        const { value } = event.target;
        setUniqueIdColumns(typeof value === 'string' ? value.split(',') : value);
    };

    const checkForDuplicateIds = () => {
        const ids = mergedData.map(createUniqueId);
        const idCounts = ids.reduce((acc, id) => {
            acc[id] = (acc[id] || 0) + 1;
            return acc;
        }, {});
        return Object.entries(idCounts).filter(([_, count]) => count > 1);
    };

    const duplicateIds = checkForDuplicateIds();

    const handlePresetSave = async () => {
        const presetData = {
            name: presetName,
            preset_type: 'csv_import',
            content: {
                columns,
                initialFilter,
                filters,
                uniqueIdColumns,
                amountColumn,
                currency,
                numberFormat,
                dateColumn,
                regexFilters,
                thirdPartyIdColumns,
                skipLinesCount,
                skipUntilText,
                groupByColumns,
                mergeRules,
            },
        };

        try {
            await sendRequest({
                apiPath: 'presets/save_or_create',
                type: 'POST',
                request: presetData,
            });
            fetchPresets();
        } catch (error) {
            console.error("Error saving preset:", error);
        }
    };

    const handlePresetDelete = async (presetId) => {
        try {
            await sendRequest({
                apiPath: `presets/delete/${presetId}`,
                type: 'DELETE',
            });
            fetchPresets();
        } catch (error) {
            console.error("Error deleting preset:", error);
        }
    };

    const getThirdPartyPreview = (data) => {
        const thirdPartyIds = [...new Set(data.map(createThirdPartyId))];
        const preview = thirdPartyIds.slice(0, 5).map((id) => {
            const rows = data.filter((row) => createThirdPartyId(row) === id).slice(0, 3);
            return { id, rows };
        });
        setThirdPartyPreview(preview);
    };

    const getPreviewData = (data, truncate = true) => {
        return data.slice(0, 20).map((row) => {
            const uniqueId = createUniqueId(row);
            const thirdPartyId = createThirdPartyId(row);
            const parsedDate = dateColumn && parseDate(row[dateColumn]) ? format(parseDate(row[dateColumn]), 'yyyy-MM-dd') : 'N/A';
            const amount = amountColumn ? parseAmount(row[amountColumn]).toFixed(2) : 'N/A';
            const rawSourceData = JSON.stringify(row);
            const displayRawSourceData = truncate && rawSourceData.length > 90 ? rawSourceData.substring(0, 90) + '...' : rawSourceData;

            return {
                uniqueId,
                thirdPartyId,
                parsedDate,
                amount,
                currency,
                rawSourceData: displayRawSourceData,
                fullRawSourceData: rawSourceData,
            };
        });
    };

    const handleImport = async () => {
        const importData = mergedData.map((row) => ({
            uniqueId: createUniqueId(row),
            thirdPartyId: createThirdPartyId(row),
            parsedDate: dateColumn ? format(parseDate(row[dateColumn]), 'yyyy-MM-dd') : 'N/A',
            amount: amountColumn ? parseAmount(row[amountColumn]).toFixed(2) : 'N/A',
            currency,
            rawSourceData: row,
        }));

        try {
            await sendRequest({
                apiPath: '/transactions/bulk-import',
                type: 'POST',
                request: { transactions: importData },
            });
            alert('Importation réussie!');
        } catch (error) {
            console.error("Error during import:", error);
            alert('Erreur lors de l\'importation.');
        }
    };

    const handlePresetLoad = (preset) => {
        setColumns(preset.content.columns);
        setInitialFilter(preset.content.initialFilter);
        setFilters(preset.content.filters);
        setUniqueIdColumns(preset.content.uniqueIdColumns);
        setAmountColumn(preset.content.amountColumn);
        setCurrency(preset.content.currency);
        setNumberFormat(preset.content.numberFormat);
        setDateColumn(preset.content.dateColumn);
        setRegexFilters(preset.content.regexFilters || {});
        setThirdPartyIdColumns(preset.content.thirdPartyIdColumns || []);
        setSkipLinesCount(preset.content.skipLinesCount || 0);
        setSkipUntilText(preset.content.skipUntilText || '');
        setGroupByColumns(preset.content.groupByColumns || []);
        setMergeRules(preset.content.mergeRules || []);
    };

    const dateColumns = getDateColumns(mergedData);

    const handleApplyGrouping = ({ mergedData: newMergedData, groupByColumns, mergeRules }) => {
        setMergedData(newMergedData);
        setGroupByColumns(groupByColumns);
        setMergeRules(mergeRules);
    };

    return (
        <Box p={3} sx={{ flexGrow: 1, width: '100%' }}>
            <Card sx={{ width: '100%' }}>
                <CardContent sx={{ width: '100%' }}>
                    <Typography variant="h5" mb={2}>
                        Importer des Transactions
                    </Typography>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">
                                Télécharger CSV
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                component="label"
                                startIcon={<CloudUpload />}
                            >
                                Télécharger CSV
                                <input
                                    type="file"
                                    hidden
                                    accept=".csv"
                                    onChange={handleFileUpload}
                                />
                            </Button>
                        </CardContent>
                    </Card>

                    {file && (
                        <>
                            {/* Aperçu du Fichier */}
                            <Card sx={{ mt: 3 }}>
                                <CardContent>
                                    <Typography variant="h6">
                                        Aperçu du Fichier
                                    </Typography>
                                    <Box sx={{ overflowX: 'auto' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column, index) => (
                                                        <TableCell key={index}>{column || `Column ${index + 1}`}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {mergedData.slice(0, 5).map((row, rowIndex) => (
                                                    <TableRow key={rowIndex}>
                                                        {columns.map((column, colIndex) => (
                                                            <TableCell key={colIndex}>{row[column]}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </CardContent>
                            </Card>

                            {/* Gérer les Présélections */}
                            <Card sx={{ mt: 3 }}>
                                <CardContent>
                                    <Typography variant="h6" mb={2}>
                                        Gérer les Présélections
                                    </Typography>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nom</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <TextField
                                                        label="Nom du Présélection"
                                                        value={presetName}
                                                        onChange={(e) => setPresetName(e.target.value)}
                                                        fullWidth
                                                        variant="outlined"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handlePresetSave}
                                                        style={{ marginRight: '8px' }}
                                                    >
                                                        Sauvegarder la configuration
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                            {presets.map((preset) => (
                                                <TableRow key={preset.id}>
                                                    <TableCell>{preset.name}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handlePresetLoad(preset)}
                                                            style={{ marginRight: '8px' }}
                                                        >
                                                            Charger
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => handlePresetDelete(preset.id)}
                                                        >
                                                            Supprimer
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>

                            {/* Options d'Importation */}
                            <Card sx={{ mt: 3 }}>
                                <CardContent>
                                    <Box display="flex" alignItems="center" mb={2}>
                                        <Typography variant="h6" flexGrow={1}>
                                            Options d'Importation
                                        </Typography>
                                        <IconButton onClick={() => setIdBuilderExpanded(!idBuilderExpanded)}>
                                            {idBuilderExpanded ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                    </Box>
                                    <Collapse in={idBuilderExpanded}>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <TextField
                                                label="Nombre de lignes à sauter"
                                                type="number"
                                                value={skipLinesCount}
                                                onChange={(e) => setSkipLinesCount(parseInt(e.target.value) || 0)}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <TextField
                                                label="Sauter les lignes jusqu'à ce que la ligne contienne le texte"
                                                value={skipUntilText}
                                                onChange={(e) => setSkipUntilText(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </FormControl>

                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel id="unique-id-columns-label">Colonnes pour ID Unique</InputLabel>
                                            <Select
                                                labelId="unique-id-columns-label"
                                                multiple
                                                value={uniqueIdColumns}
                                                onChange={handleUniqueIdColumnsChange}
                                                input={<OutlinedInput label="Colonnes pour ID Unique" />}
                                                renderValue={(selected) => selected.join(', ')}
                                            >
                                                {columns.map((column) => (
                                                    <MenuItem key={column} value={column}>
                                                        <Checkbox checked={uniqueIdColumns.indexOf(column) > -1} />
                                                        <ListItemText primary={column} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {duplicateIds.length > 0 && (
                                            <>
                                                <Typography variant="h6" mt={3}>
                                                    Duplicates Preview (Grouped by ID)
                                                </Typography>
                                                {duplicateIds.slice(0, 5).map(([id, _], duplicateIndex) => (
                                                    <Box key={duplicateIndex} mb={2}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            ID: {id}
                                                        </Typography>
                                                        <Box sx={{ overflowX: 'auto' }}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {columns.map((column, index) => (
                                                                            <TableCell key={index}>{column || `Column ${index + 1}`}</TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {mergedData
                                                                        .filter((row) => createUniqueId(row) === id)
                                                                        .slice(0, 5)
                                                                        .map((row, rowIndex) => (
                                                                            <TableRow key={rowIndex}>
                                                                                {columns.map((column, colIndex) => (
                                                                                    <TableCell key={colIndex}>{row[column]}</TableCell>
                                                                                ))}
                                                                            </TableRow>
                                                                        ))}
                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </>
                                        )}

                                        {/* Grouping Rules Form */}
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel id="group-by-columns-label">Colonnes pour regrouper</InputLabel>
                                            <Select
                                                labelId="group-by-columns-label"
                                                multiple
                                                value={groupByColumns}
                                                onChange={(event) => {
                                                    const { value } = event.target;
                                                    setGroupByColumns(typeof value === 'string' ? value.split(',') : value);
                                                }}
                                                input={<OutlinedInput label="Colonnes pour regrouper" />}
                                                renderValue={(selected) => selected.join(', ')}
                                            >
                                                {columns.map((column) => (
                                                    <MenuItem key={column} value={column}>
                                                        <Checkbox checked={groupByColumns.indexOf(column) > -1} />
                                                        <ListItemText primary={column} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <Typography variant="h6" mt={3}>
                                            Règles de Fusion
                                        </Typography>
                                        {mergeRules.map((rule, index) => (
                                            <Box key={index} display="flex" alignItems="center" mb={2}>
                                                <FormControl variant="outlined" sx={{ mr: 2, flexGrow: 1 }}>
                                                    <InputLabel id={`merge-rule-column-${index}`}>Colonne</InputLabel>
                                                    <Select
                                                        labelId={`merge-rule-column-${index}`}
                                                        value={rule.column}
                                                        onChange={(e) => {
                                                            const newMergeRules = [...mergeRules];
                                                            newMergeRules[index].column = e.target.value;
                                                            setMergeRules(newMergeRules);
                                                        }}
                                                        label="Colonne"
                                                    >
                                                        {columns.map((column) => (
                                                            <MenuItem key={column} value={column}>
                                                                {column}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    label="Valeur"
                                                    variant="outlined"
                                                    value={rule.value}
                                                    onChange={(e) => {
                                                        const newMergeRules = [...mergeRules];
                                                        newMergeRules[index].value = e.target.value;
                                                        setMergeRules(newMergeRules);
                                                    }}
                                                    sx={{ mr: 2, flexGrow: 1 }}
                                                />
                                                <IconButton
                                                    onClick={() => {
                                                        const newMergeRules = mergeRules.filter((_, i) => i !== index);
                                                        setMergeRules(newMergeRules);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        ))}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setMergeRules([...mergeRules, { column: '', value: '' }])}
                                        >
                                            Ajouter une Règle
                                        </Button>

                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel id="third-party-id-columns-label">Colonnes pour ID de Tiers</InputLabel>
                                            <Select
                                                labelId="third-party-id-columns-label"
                                                multiple
                                                value={thirdPartyIdColumns}
                                                onChange={(event) => {
                                                    const { value } = event.target;
                                                    setThirdPartyIdColumns(typeof value === 'string' ? value.split(',') : value);
                                                }}
                                                input={<OutlinedInput label="Colonnes pour ID de Tiers" />}
                                                renderValue={(selected) => selected.join(', ')}
                                            >
                                                {columns.map((column) => (
                                                    <MenuItem key={column} value={column}>
                                                        <Checkbox checked={thirdPartyIdColumns.indexOf(column) > -1} />
                                                        <ListItemText primary={column} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        {thirdPartyPreview.length > 0 && (
                                            <>
                                                <Typography variant="h6" mt={3}>
                                                    Aperçu des Tiers (Groupé par ID)
                                                </Typography>
                                                {thirdPartyPreview.map((preview, previewIndex) => (
                                                    <Box key={previewIndex} mb={2}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            ID de Tiers: {preview.id}
                                                        </Typography>
                                                        <Box sx={{ overflowX: 'auto' }}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {columns.map((column, index) => (
                                                                            <TableCell key={index}>{column || `Column ${index + 1}`}</TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {preview.rows.map((row, rowIndex) => (
                                                                        <TableRow key={rowIndex}>
                                                                            {columns.map((column, colIndex) => (
                                                                                <TableCell key={colIndex}>{row[column]}</TableCell>
                                                                            ))}
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </>
                                        )}

                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel id="amount-column-label">Colonne Montant</InputLabel>
                                            <Select
                                                labelId="amount-column-label"
                                                value={amountColumn}
                                                onChange={(e) => setAmountColumn(e.target.value)}
                                                input={<OutlinedInput label="Colonne Montant" />}
                                            >
                                                {columns.map((column) => (
                                                    <MenuItem key={column} value={column}>
                                                        {column}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel id="number-format-label">Format des Nombres</InputLabel>
                                            <Select
                                                labelId="number-format-label"
                                                value={numberFormat}
                                                onChange={handleNumberFormatChange}
                                                input={<OutlinedInput label="Format des Nombres" />}
                                            >
                                                {numberFormats.map((format) => (
                                                    <MenuItem key={format.value} value={format.value}>
                                                        {format.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {amountPreview.length > 0 && (
                                            <Box mt={2}>
                                                <Typography variant="h6">Aperçu des Montants</Typography>
                                                <Box sx={{ overflowX: 'auto' }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Original</TableCell>
                                                                <TableCell>Parsé</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {amountPreview.map((item, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{item.original}</TableCell>
                                                                    <TableCell>{isNaN(item.parsed) ? 'N/A' : item.parsed.toFixed(2)}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Box>
                                        )}
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel id="currency-label">Devise</InputLabel>
                                            <Select
                                                labelId="currency-label"
                                                value={currency}
                                                onChange={(e) => setCurrency(e.target.value)}
                                                input={<OutlinedInput label="Devise" />}
                                            >
                                                {['EUR', 'USD', 'GBP', 'CHF'].map((currency) => (
                                                    <MenuItem key={currency} value={currency}>
                                                        {currency}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel id="date-column-label">Colonne Date</InputLabel>
                                            <Select
                                                labelId="date-column-label"
                                                value={dateColumn}
                                                onChange={handleDateColumnChange}
                                                input={<OutlinedInput label="Colonne Date" />}
                                            >
                                                {dateColumns.map((column) => (
                                                    <MenuItem key={column} value={column}>
                                                        {column}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {datePreview.length > 0 && (
                                            <Box mt={2}>
                                                <Typography variant="h6">Aperçu des Dates</Typography>
                                                <Box sx={{ overflowX: 'auto' }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Original</TableCell>
                                                                <TableCell>Parsé</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {datePreview.map((item, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{item.original}</TableCell>
                                                                    <TableCell>{item.parsed}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Box>
                                        )}
                                    </Collapse>
                                </CardContent>
                            </Card>

                            {/* Filtres */}
                            <Card sx={{ mt: 3 }}>
                                <CardContent>
                                    <Box display="flex" alignItems="center" mb={2}>
                                        <Typography variant="h6" flexGrow={1}>
                                            Filtres
                                        </Typography>
                                        <IconButton onClick={() => setFilterExpanded(!filterExpanded)}>
                                            {filterExpanded ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                    </Box>
                                    <Collapse in={filterExpanded}>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel id="initial-filter-label">Sélectionner les Colonnes</InputLabel>
                                            <Select
                                                labelId="initial-filter-label"
                                                multiple
                                                value={initialFilter}
                                                onChange={handleInitialFilterChange}
                                                input={<OutlinedInput label="Sélectionner les Colonnes" />}
                                                renderValue={(selected) => selected.join(', ')}
                                            >
                                                {columns.map((column) => (
                                                    <MenuItem key={column} value={column}>
                                                        <Checkbox checked={initialFilter.indexOf(column) > -1} />
                                                        <ListItemText primary={column} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <Typography variant="h6" mt={3}>
                                            Filtres de Valeurs Autorisées
                                        </Typography>
                                        {columns.map((column, index) => (
                                            <FormControl key={index} fullWidth variant="outlined" margin="normal">
                                                <InputLabel id={`${column}-filter-label`}>{column || `Column ${index + 1}`}</InputLabel>
                                                <Select
                                                    labelId={`${column}-filter-label`}
                                                    multiple
                                                    value={filters[column]}
                                                    onChange={(e) => handleFilterChange(column, e.target.value)}
                                                    input={<OutlinedInput label={column || `Column ${index + 1}`} />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                >
                                                    {[...new Set(mergedData.map(row => row[column]))].map((value, i) => (
                                                        <MenuItem key={i} value={value}>
                                                            <Checkbox checked={filters[column].indexOf(value) > -1} />
                                                            <ListItemText primary={value} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <TextField
                                                    label={`Regex pour ${column}`}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    value={regexFilters[column] || ''}
                                                    onChange={(e) => setRegexFilters({ ...regexFilters, [column]: e.target.value })}
                                                />
                                            </FormControl>
                                        ))}
                                    </Collapse>
                                </CardContent>
                            </Card>

                            {/* Aperçu des Données à Importer */}
                            <Card sx={{ mt: 3 }}>
                                <CardContent>
                                    <Typography variant="h6">
                                        Aperçu des Données à Importer
                                    </Typography>
                                    <Box sx={{ overflowX: 'auto' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Unique Id</TableCell>
                                                    <TableCell>Third Party Id</TableCell>
                                                    <TableCell>Parsed Date</TableCell>
                                                    <TableCell>Amount</TableCell>
                                                    <TableCell>Currency Code</TableCell>
                                                    <TableCell>Raw Source Data</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {getPreviewData(mergedData).map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{row.uniqueId}</TableCell>
                                                        <TableCell>{row.thirdPartyId}</TableCell>
                                                        <TableCell>{row.parsedDate}</TableCell>
                                                        <TableCell>{row.amount}</TableCell>
                                                        <TableCell>{row.currency}</TableCell>
                                                        <TableCell>{row.rawSourceData}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </CardContent>
                            </Card>

                            <Box mt={3} textAlign="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={handleImport}
                                    sx={{ width: '50%' }}
                                >
                                    Importer les Transactions
                                </Button>
                            </Box>
                        </>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default ImportTransactions;
