import React, { useState } from 'react';
import Dashboard from '../../Layouts/Dashboard';
import ImportTransactions from './ImportTransactions';
import TransactionSidebar from './TransactionSidebar';
import { Box } from '@mui/material';

const BulkImportTransactions = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleSectionChange = (route) => {
        // navigate to the route
        window.location.href = route;
    };

    return (
        <Dashboard dashboardTitle='Bulk Import Transactions' withSidebar>
            <Box display="flex" height="100%" overflow="hidden">
                <TransactionSidebar
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                    handleSectionChange={handleSectionChange}
                />
                <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                    <ImportTransactions />
                </Box>
            </Box>
        </Dashboard>
    );
};

export default BulkImportTransactions;
