import React, { useEffect, useState, useContext } from 'react';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import { User, Phone, Mail, MapPin, Briefcase, Building, CreditCard } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const ClientInfoCard = ({ isFormExpanded, selectedCustomerId }) => {
  const { sendRequest } = useContext(HttpRequestContext);
  const [clientInfo, setClientInfo] = useState(null);

  useEffect(() => {
    const fetchClientInfo = async () => {
      if (selectedCustomerId) {
        try {
          const response = await sendRequest({
            apiPath: `crm/container/${selectedCustomerId}`,
            type: 'GET',
          });
          setClientInfo(response.properties);
        } catch (error) {
          console.error('Erreur lors de la récupération des informations du client:', error);
        }
      } else {
        setClientInfo(null); // Réinitialiser si aucun client n'est sélectionné
      }
    };

    fetchClientInfo();
  }, [selectedCustomerId, sendRequest]);


  // Déterminer si le composant doit être affiché
  const shouldDisplay = isFormExpanded && clientInfo;


  const getIcon = (key) => {
    switch (key) {
      case 'firstname':
      case 'lastname':
        return <User className="w-5 h-5 text-blue-500" />;
      case 'email':
        return <Mail className="w-5 h-5 text-red-500" />;
      case 'address_line1':
      case 'address_line2':
        return <MapPin className="w-5 h-5 text-yellow-500" />;
      case 'entity_name':
        return <Building className="w-5 h-5 text-purple-500" />;
      case 'bce_number':
      case 'vat_number':
        return <CreditCard className="w-5 h-5 text-green-500" />;
      default:
        return <Briefcase className="w-5 h-5 text-gray-500" />;
    }
  };

  const renderValue = (property) => {
    if (property.value === null) {
      return <span className="text-gray-400 italic">Non renseigné</span>;
    }
    return property.value;
  };

  return (
    <AnimatePresence>
      {shouldDisplay && (
        <motion.div
          key="clientInfoCard"
          initial={{ x: '100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '100%', opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
        >
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Informations du client</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              {Object.entries(clientInfo).map(([key, property], index) => (
                <div
                  key={key}
                  className={`${
                    index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                  } px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}
                >
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    {getIcon(key)}
                    <span className="ml-2">{property.display_name}</span>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {renderValue(property)}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ClientInfoCard;