import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Dashboard from '../../Layouts/Dashboard';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import { SortableTable } from '../../Components/Table';
import CreateEditContainerInstance from '../../Components/CreateEditContainerInstance';
import CreateAppointmentForm from './CreateAppointmentForm';

const CreateAppointment = () => {
    const { sendRequest } = useContext(HttpRequestContext);
    const { typeName } = useParams();

    const [crmType, setCrmType] = useState(null);
    const [error, setError] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedInstance, setSelectedInstance] = useState(null);

    useEffect(() => {
        const fetchCrmType = async () => {
            try {
                const responseData = await sendRequest({
                    apiPath: 'crm/types/read/appointment',
                    type: 'GET',
                });
                setCrmType(responseData);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchCrmType();
    }, [sendRequest]);

    const handleNewInstance = () => {
        setSelectedInstance(null);
        setIsModalOpen(true);
    };

    const handleEditInstance = (instance) => {
        setSelectedInstance(instance);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedInstance(null);
    };

    const handleSave = () => {
        setIsModalOpen(false);
    };

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!crmType) {
        return <p>Loading...</p>;
    }

    const customColumns = {
        edit: {
            type: 'action',
            display_name: 'Modifier',
            name: 'edit',
            sortable: false,
            actionCallback: (row) => handleEditInstance(row),
            tooltipContent: "Modifier",
        },
    };

    return (
        <Dashboard dashboardTitle="Créer un Rendez-vous">
            <Card variant="outlined" className="mb-4">
                <CreateAppointmentForm />
                <CardContent>
                    <div className="flex justify-between items-center mb-2">
                        <Typography variant='h5' color='textPrimary'>
                            Liste: {crmType.display_name}
                        </Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleNewInstance}
                        >
                            Nouveau
                        </Button>
                    </div>
                    <SortableTable
                        apiEndpoint={`/crm/instances/appointment/list`}
                        customColumns={customColumns}
                    />
                </CardContent>
            </Card>

            {crmType && (
                <CreateEditContainerInstance
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    crmType={crmType}
                    selectedInstance={selectedInstance}
                    onSave={handleSave}
                />
            )}
        </Dashboard>
    );
};

export default CreateAppointment;