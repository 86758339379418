import React, { useState, useEffect, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '../../Components/Modal';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import { SortableTable } from '../../Components/Table';
import DocumentEmailSender from '../../Components/DocumentEmailSender';
import EditCommercialDocument from './EditCommercialDocument';

const CommercialDocumentTable = ({ apiType, entityName, filters = {} }) => {
    const { sendRequest } = useContext(HttpRequestContext);
    const [crmType, setCrmType] = useState(null);
    const [error, setError] = useState(null);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [selectedDocumentId, setSelectedDocumentId] = useState(null);
    const [documentToEdit, setDocumentToEdit] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const initialVisibleColumns = ['num_document','date_emission','date_echeance','status','total_htva','PdfContainer/pdf_path','Client'];

    useEffect(() => {
        const fetchCrmType = async () => {
            try {
                const responseData = await sendRequest({
                    apiPath: `crm/types/read/${apiType}`,
                    type: 'GET',
                });
                setCrmType(responseData);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchCrmType();
    }, [apiType, sendRequest]);

    const handleSendEmail = (row) => {
        let documentId = row.id;

        if (documentId && typeof documentId === 'object' && documentId.value) {
            documentId = documentId.value;
        }

        if (typeof documentId === 'string' || typeof documentId === 'number') {
            setSelectedDocumentId(documentId);
            setIsEmailModalOpen(true);
        } else {
            console.error('Invalid Document ID:', documentId);
        }
    };

    const closeEmailModal = () => {
        setIsEmailModalOpen(false);
        setSelectedDocumentId(null);
    };

    const handleEditDocument = (row) => {
        const documentId = row.id?.value || row.id;
        setDocumentToEdit(documentId);
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setDocumentToEdit(null);
    };

    const handleSaveDocument = () => {
        setIsEditModalOpen(false);
        setDocumentToEdit(null);
    };

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!crmType) {
        return <p>Loading...</p>;
    }

    const customColumns = {
        select: {
            type: 'selection',
            display_name: 'Sélectionner',
            name: 'select',
            sortable: false,
        },
        email: {
            type: 'action',
            display_name: 'Envoyer Email',
            name: 'email',
            sortable: false,
            actionCallback: (row) => {
                handleSendEmail(row);
            },
            tooltipContent: "Envoyer Email",
            actionType: 'email'
        },
        edit: {
            type: 'action',
            display_name: 'Modifier',
            name: 'edit',
            sortable: false,
            actionCallback: (row) => {
                handleEditDocument(row);
            },
            tooltipContent: "Modifier ce document",
            actionType: 'edit'
        }
    };

    const handleDownload = async (selectedRows) => {
        const documentIds = Array.from(selectedRows).map(id => {
            return typeof id === 'object' && id.hasOwnProperty('value') ? id.value : id;
        });

        try {
            const response = await sendRequest({
                apiPath: '/documents/zip',
                type: 'POST',
                request: { documentIds },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'documents.zip');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erreur lors du téléchargement des documents:', error);
        }
    };


    return (
        <Card variant="outlined" className="mb-4">
            <CardContent>
                <div className="flex justify-between items-center mb=2">
                    <Typography variant='h5' color='textPrimary'>
                        Liste: {crmType.display_name}
                    </Typography>
                </div>
                <SortableTable
                    apiEndpoint={`/crm/instances/${apiType}/list`}
                    filters={filters}
                    customColumns={customColumns}
                    selectionKey="id"
                    onMassAction={{
                        action: handleDownload,
                        buttonText: "Télécharger"
                    }}
                    updateApiEndpoint={`/documents/update/status`}
                    updatePropertyKey="status"
                    initialVisibleColumns={initialVisibleColumns}
                />
            </CardContent>
    
            <Modal isOpen={isEmailModalOpen} closeModal={closeEmailModal}>
                <DocumentEmailSender documentContainerId={selectedDocumentId} documentType={apiType} />
            </Modal>
            <Modal isOpen={isEditModalOpen} closeModal={closeEditModal}>
                <EditCommercialDocument
                    isOpen={isEditModalOpen}
                    onClose={closeEditModal}
                    documentId={documentToEdit}
                    crmType={crmType}
                    onSave={handleSaveDocument}
                />
            </Modal>
        </Card>
    );
};

export default CommercialDocumentTable;
