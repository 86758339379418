import React, { useState, useContext } from 'react';
import { ScheduleInputFields } from '../../Components/InputFields';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { TextInputWithIcon } from '../../Components/InputFields';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import Button from '@mui/material/Button';

const CreateAppointmentForm = () => {
    const [schedule, setSchedule] = useState({ start: '', end: '', type: 'onetime' });
    const [description, setDescription] = useState('');

    const { sendRequestWithToast } = useContext(HttpRequestContext);

    const handleCreateAppointment = async (e) => {
        e.preventDefault();
        const { start, end, type } = schedule;

        if (!start || !end || !description || !type) {
            alert("Please ensure all fields are filled in correctly.");
            return;
        }

        const newAppointmentData = {
            schedule: { start, end, type },
            description,
        };

        await sendRequestWithToast({
            apiPath: 'crm/instances/appointment/create',
            type: 'POST',
            request: newAppointmentData,
            successMessage: "The appointment has been created successfully.",
            errorMessage: "Failed to create the appointment."
        });
    };

    const handleScheduleChange = (propertyName, newValue) => {
        setSchedule(newValue);
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant='h5' color='blue-gray' className='mb-2'>
                    Création d'un nouveau rendez-vous
                </Typography>
                <form onSubmit={handleCreateAppointment}>
                    {/* Full-width Description Field */}
                    <TextInputWithIcon
                        label="Description"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                    />
                    {/* Using ScheduleInputFields here */}
                    <ScheduleInputFields
                        value={schedule}
                        onChange={handleScheduleChange}
                        readOnly={false}
                        description="Select the schedule"
                        propertyName="schedule"
                    />
                    <Button
                        color='primary'
                        variant="contained"
                        type='submit'>
                        Create Appointment
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

export default CreateAppointmentForm;