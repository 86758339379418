import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, Typography } from '@mui/material';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import { resolveValueObject } from '../../Components/ValueObjects';
import EditableContainerTable from '../../Components/EditableContainerTable';

const nonEditableFields = [
  'communication_struct',
  'num_document',
  'receipt_number',
  'credit_note_number',
  'total_htva',
  'total_tvac',
];

const EditCommercialDocument = ({ isOpen, onClose, documentId, crmType, onSave }) => {
  const { sendRequestWithToast, sendRequest } = useContext(HttpRequestContext);
  const [documentProperties, setDocumentProperties] = useState({});
  const [newArticles, setNewArticles] = useState([]);
  const [modifiedArticles, setModifiedArticles] = useState([]);
  const [deletedArticles, setDeletedArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // Track if there are unsaved changes

  // Fetch document details
  useEffect(() => {
    if (documentId) {
      const fetchDocumentDetails = async () => {
        setIsLoading(true);
        try {
          // Fetch commercial document
          const documentDetails = await sendRequest({
            apiPath: `/crm/container/${documentId}`,
            type: 'GET',
          });

          // Convert document properties into ValueObjects
          const properties = {};
          for (const [key, value] of Object.entries(documentDetails.properties)) {
            properties[key] = resolveValueObject(value);
          }
          setDocumentProperties(properties);

        } catch (error) {
          console.error("Erreur lors de la récupération du document:", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchDocumentDetails();
    }
  }, [documentId, sendRequest, crmType]);

  // Calculate total_htva and total_tvac from articles
  const calculateTotalsFromArticles = (articles) => {
    let totalHtva = 0;
    let totalTvac = 0;

    articles.forEach((article) => {
      const propertyValues = article.property_values;
      if (propertyValues) {
        const prixUnitaire = propertyValues.prix_unitaire?.amount || 0;
        const prixUnitaireTvac = propertyValues.prix_unitaire_tvac?.amount || 0;
        const quantite = parseFloat(propertyValues.quantite) || 0;

        // Calculate montant_htva and montant_tvac
        const montantHtva = prixUnitaire * quantite;
        const montantTvac = prixUnitaireTvac * quantite;

        totalHtva += montantHtva;
        totalTvac += montantTvac;
      }
    });

    setDocumentProperties((prevProperties) => ({
      ...prevProperties,
      total_htva: prevProperties.total_htva.setValue({
        amount: totalHtva,
        currency: 'EUR' // Assuming EUR, change as needed
      }),
      total_tvac: prevProperties.total_tvac.setValue({
        amount: totalTvac,
        currency: 'EUR' // Assuming EUR, change as needed
      }),
    }));
  };

  // Handle changes in document properties
  const handleDocumentChange = (propertyName, value) => {
    setDocumentProperties((prevProperties) => ({
      ...prevProperties,
      [propertyName]: prevProperties[propertyName].setValue(value),
    }));
    setHasUnsavedChanges(true); // Set unsaved changes flag to true
  };

  // Handle document submission
  const handleSubmit = async () => {
    // Check for unsaved changes in articles and force a save if needed
    if (hasUnsavedChanges) {
      if (!window.confirm("Il y a des modifications non enregistrées dans les articles. Voulez-vous les enregistrer avant de mettre à jour le document ?")) {
        return;
      }
      // Trigger the save for articles before proceeding
      await handleSaveArticles();
    }

    try {
      // Prepare document update payload
      const documentUpdate = {
        container_id: documentId,
        property_values: {},
      };

/*

      if (valueObject.value) {
        if (valueObject.value instanceof File) {
          let file = valueObject.value;
          if (valueObject.value.name.endsWith('.svg')) {
            file = new File([valueObject.value], valueObject.value.name, { type: 'image/svg+xml' });
          }
          formData.append(key, file);
        } else if (typeof valueObject.value === 'object' && valueObject.type === 'label') {
          formData.append(key, valueObject.code);
        } else if (typeof valueObject.value === 'object') {
          formData.append(key, JSON.stringify(valueObject.value));
        } else {
          formData.append(key, valueObject.value);
        }
      }

*/


      for (const [key, valueObject] of Object.entries(documentProperties)) {
        if (valueObject.value) {
          if (valueObject.value instanceof File) {
            let file = valueObject.value;
            if (valueObject.value.name.endsWith('.svg')) {
              file = new File([valueObject.value], valueObject.value.name, { type: 'image/svg+xml' });
            }
            documentUpdate.property_values[key] = file;
          } else if (typeof valueObject.value === 'object' && valueObject.type === 'label') {
            documentUpdate.property_values[key] = valueObject.code;
          } else if (typeof valueObject.value === 'object') {
            documentUpdate.property_values[key]=valueObject.value;
          } else {
            documentUpdate.property_values[key]=valueObject.value;
          }
        }
      }

      // Debug logs
      console.log("Submitting Document Update:", documentUpdate);
      console.log("Modified Articles:", modifiedArticles);
      console.log("New Articles:", newArticles);
      console.log("Deleted Articles:", deletedArticles);

      // Send updated document data, along with article updates, new articles, and deleted articles
      const response = await sendRequestWithToast({
        apiPath: `/document/modify`,
        type: 'POST',
        request: {
          document_update: documentUpdate,
          article_updates: modifiedArticles,
          new_articles: newArticles,
          deleted_articles: deletedArticles,
          pdf_update: {}, // PDF generation handled in backend
        },
        successMessage: "Document mis à jour avec succès",
        errorMessage: "Erreur lors de la mise à jour du document",
      });

      if (response) {
        setHasUnsavedChanges(false); // Reset unsaved changes state after successful save
        onSave();
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour:", error);
    }
  };

  // Save articles if there are unsaved changes
  const handleSaveArticles = async () => {
    try {
      const articleSaveData = {
        newContainers: newArticles,
        modifiedContainers: modifiedArticles,
        deletedContainers: deletedArticles,
      };

      // Pass the article save data to handleSaveArticlesExternally to make sure all articles are saved
      handleSaveArticlesExternally(articleSaveData);

      setHasUnsavedChanges(false); // After save, reset unsaved changes
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des articles:", error);
    }
  };

  // Handle save action from EditableContainerTable
  const handleSaveArticlesExternally = ({ newContainers, modifiedContainers, deletedContainers }) => {
    console.log("Received newContainers from EditableContainerTable:", newContainers);
    console.log("Received modifiedContainers from EditableContainerTable:", modifiedContainers);
    console.log("Received deletedContainers from EditableContainerTable:", deletedContainers);

    setNewArticles(newContainers);
    setModifiedArticles(modifiedContainers);
    setDeletedArticles(deletedContainers);

    // Combine all articles for total calculation
    const allArticles = [...newContainers, ...modifiedContainers];
    calculateTotalsFromArticles(allArticles);

    // Set unsaved changes to true as articles have changed
    setHasUnsavedChanges(true);
  };

  // Confirm close dialog if there are unsaved changes
  const handleClose = () => {
    if (hasUnsavedChanges) {
      if (window.confirm("Vous avez des modifications non enregistrées. Voulez-vous vraiment fermer sans enregistrer ?")) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Modifier le document commercial</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Typography variant="body1">Chargement...</Typography>
        ) : (
          <>
            {/* Commercial document fields */}
            {Object.entries(documentProperties).map(([key, valueObject]) => (
              <div key={key}>
                {nonEditableFields.includes(key) ? (
                  <Box mb={2}>
                    <Typography variant="subtitle1" color="textSecondary">
                      {valueObject.displayName}
                    </Typography>
                    {valueObject.render()}
                  </Box>
                ) : (
                  valueObject.renderInput((propertyName, value) => handleDocumentChange(propertyName, value))
                )}
              </div>
            ))}

            <Box mt={4}>
              <Typography variant="h6">Articles</Typography>
              <EditableContainerTable
                apiPath={`/crm/instances/article/list?items_per_page=-1&filters={"${crmType.type_name}":{"$parent$eq":"${documentId}"}}`}
                containerType="article"
                modifyApiPath="/document/modify"
                handleSaveExternally={handleSaveArticlesExternally}
                setHasUnsavedChanges={setHasUnsavedChanges} // Prop to track unsaved changes
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Annuler
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Mettre à jour
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCommercialDocument;
