import React from 'react';
import { List, ListItem, ListItemText, Drawer, IconButton, Box, Typography, Button, Divider, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';

const Sidebar = ({ sidebarOpen, setSidebarOpen, categories, selectedLabel, onSelectChange, showProfileButton = false, onProfileButtonClick }) => {
    const theme = useTheme();

    const handleCategoryClick = (route) => {
        if (onSelectChange) {
            onSelectChange(route);
        }
        if (typeof setSidebarOpen === 'function') {
            setSidebarOpen(false);
        }
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: sidebarOpen ? theme.spacing(30) : theme.spacing(9),
                flexShrink: 0,
                whiteSpace: 'nowrap',
                '& .MuiDrawer-paper': {
                    width: sidebarOpen ? theme.spacing(30) : theme.spacing(9),
                    boxSizing: 'border-box',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    borderRight: '1px solid #ddd',
                    paddingTop: '64px', // Adjust this to match the height of your navbar
                },
            }}
            open={sidebarOpen}
        >
            <Box
                sx={{
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.main,
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderBottom: '1px solid #ddd',
                }}
            >
                <Tooltip title={sidebarOpen ? 'Réduire' : 'Agrandir'} placement="right">
                    <IconButton onClick={() => {
                        console.log("Toggle sidebar clicked"); // Log added
                        setSidebarOpen(!sidebarOpen);
                    }} sx={{ color: theme.palette.primary.main }}>
                        <MenuIcon />
                    </IconButton>
                </Tooltip>
                {sidebarOpen && (
                    <Box textAlign="center" width="100%" mt={2}>
                        <Typography variant="body1" gutterBottom sx={{ color: theme.palette.primary.main }}>
                            {selectedLabel}
                        </Typography>
                        {showProfileButton && (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    console.log("Changer de Profil button clicked"); // Log added
                                    onProfileButtonClick();
                                }}
                                sx={{ marginBottom: theme.spacing(2) }}
                            >
                                Changer de Profil
                            </Button>
                        )}
                    </Box>
                )}
            </Box>
            <Divider />
            <List>
                {categories.map((category, index) => (
                    <Tooltip title={category.title} placement="right" key={index}>
                        <ListItem
                            button
                            onClick={() => handleCategoryClick(category.route || category.key)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '8px 16px',
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.main,
                                    '& .MuiListItemText-root': {
                                        color: '#fff',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: '#fff',
                                    },
                                },
                                '& .MuiListItemText-root': {
                                    color: theme.palette.primary.dark,
                                },
                                '& .MuiSvgIcon-root': {
                                    color: theme.palette.primary.dark,
                                },
                            }}
                        >
                            {category.icon}
                            {sidebarOpen && <ListItemText primary={category.title} sx={{ marginLeft: 2, textAlign: 'center' }} />}
                        </ListItem>
                    </Tooltip>
                ))}
            </List>
        </Drawer>
    );
};

export default Sidebar;
