import React, {useState} from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { TextCellRenderer, CurrencyAmountCellRenderer, JsonCellRenderer, ScheduleCellRenderer } from './CellRenderers';
import { ScheduleInputFields } from './InputFields';
import PDFViewerObject from './PDFViewerObject'; 
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; 
import Modal from './Modal';
import { format, parse, isValid, parseISO  } from 'date-fns';
import { fr } from 'date-fns/locale';

export class BaseValueObject {
  constructor(type, value, propertyName, displayName, description, modifiedAt, readOnly, extraParameters) {
    this.type = type;
    this.value = value;
    this.propertyName = propertyName;
    this.displayName = displayName;
    this.description = description;
    this.modifiedAt = modifiedAt;
    this.readOnly = readOnly;
    this.extraParameters = extraParameters;
  }

  render() {
    return <TextCellRenderer value={this.value} />;
  }

  renderInput(onChange) {
    return (
      <TextField
        id={this.propertyName}
        type="text"
        label={this.displayName}
        value={this.value || ''}
        onChange={(e) => onChange(this.propertyName, e.target.value)}
        fullWidth
        margin="normal"
        disabled={this.readOnly}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={this.description}
      />
    );
  }  
  renderCompactInput(onChange) {
    return (
      <TextField
        id={this.propertyName}
        type="text"
        value={this.value || ''}
        onChange={(e) => onChange(this.propertyName, e.target.value)}
        variant="outlined"
        size="small"
        fullWidth
        disabled={this.readOnly}
        placeholder={this.description}
        sx={{ margin: 0 }}
      />
    );
  }

  setValue(value) {
    this.value = value;
    this.modifiedAt = new Date().toISOString();
    return this;
  }
}

export class PrimitiveValue extends BaseValueObject {
  constructor(value) {
    super('primitive', value, null, null, null, null, false, null);
  }

  render() {
    return <TextCellRenderer value={this.value} />;
  }

  renderInput(onChange) {
    // For primitive values, we don't have property information, so we render a simple text field
    return (
      <TextField
        type="text"
        value={this.value || ''}
        onChange={(e) => onChange(null, e.target.value)}
        fullWidth
        margin="normal"
      />
    );
  }  
  renderCompactInput(onChange) {
    return (
      <TextField
        type="text"
        value={this.value || ''}
        onChange={(e) => onChange(null, e.target.value)}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ margin: 0 }}
      />
    );
  }
}


export class TextValue extends BaseValueObject {
  constructor(data) {
    super('text', data.value, data.property_name, data.display_name, data.description, data.modified_at, data.read_only, data.extra_parameters);
  }

  render() {
    return <TextCellRenderer value={this.value} />;
  }  
  renderCompactInput(onChange) {
    return (
      <TextField
        id={this.propertyName}
        type="text"
        value={this.value || ''}
        onChange={(e) => onChange(this.propertyName, e.target.value)}
        variant="outlined"
        size="small"
        fullWidth
        disabled={this.readOnly}
        sx={{ margin: 0 }}
      />
    );
  }
}





export class DateValue extends BaseValueObject {
  constructor(data) {
    super(
      'date',
      data.value,
      data.property_name,
      data.display_name,
      data.description,
      data.modified_at,
      data.read_only,
      data.extra_parameters
    );
    this.value = this.parseDate(data.value);
  }

  render() {
    return <span>{this.formatDate(this.value)}</span>;
  }

  renderInput(onChange) {
    return (
      <TextField
        id={this.propertyName}
        type="date"
        label={this.displayName}
        value={this.getInputValue()}
        onChange={(e) => {
          this.setValue(e.target.value);
          onChange(this.propertyName, this.value);
        }}
        fullWidth
        margin="normal"
        helperText={this.description}
        disabled={this.readOnly}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }  
  renderCompactInput(onChange) {
    return (
      <TextField
        id={this.propertyName}
        type="date"
        value={this.getInputValue()}
        onChange={(e) => {
          this.setValue(e.target.value);
          onChange(this.propertyName, this.value);
        }}
        variant="outlined"
        size="small"
        fullWidth
        disabled={this.readOnly}
        sx={{ margin: 0 }}
      />
    );
  }

  getInputValue() {
    if (!this.value) return '';
    const parsedDate = parseISO(this.value);
    return isValid(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : '';
  }

  formatDate(date) {
    if (!date) return '';
    const parsedDate = parseISO(date);
    return isValid(parsedDate)
      ? format(parsedDate, 'dd MMMM yyyy', { locale: fr })
      : '';
  }

  parseDate(dateString) {
    if (!dateString) return null;

    // Attempt to parse the date string as ISO format
    const parsedDate = parseISO(dateString);
    if (isValid(parsedDate)) {
      // Store the date as an ISO string
      return parsedDate.toISOString();
    }

    console.warn(`Invalid date format: ${dateString}`);
    return null;
  }

  setValue(value) {
    // Value comes in 'yyyy-MM-dd' format from the date input
    const parsedDate = parseISO(value);
    if (isValid(parsedDate)) {
      this.value = parsedDate.toISOString();
    } else {
      console.warn(`Invalid date format in setValue: ${value}`);
      this.value = null;
    }
    this.modifiedAt = new Date().toISOString();
    return this;
  }
}


export class NumberValue extends BaseValueObject {
  constructor(data) {
    super('number', data.value, data.property_name, data.display_name, data.description, data.modified_at, data.read_only, data.extra_parameters);
  }

  render() {
    return <TextCellRenderer value={this.value} />;
  }

  renderInput(onChange) {
    return (
      <TextField
        id={this.propertyName}
        type="number"
        label={this.displayName}
        value={this.value || 0}
        onChange={(e) => {
          const parsedValue = parseFloat(e.target.value);
          const value = isNaN(parsedValue) ? 0 : parsedValue;
          onChange(this.propertyName, value);
        }}
        fullWidth
        margin="normal"
        disabled={this.readOnly}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={this.description}
      />
    );
  }

  renderCompactInput(onChange) {
    return (
      <TextField
        id={this.propertyName}
        type="number"
        value={this.value || 0}
        onChange={(e) => {
          const parsedValue = parseFloat(e.target.value);
          const value = isNaN(parsedValue) ? 0 : parsedValue;
          onChange(this.propertyName, value);
        }}
        variant="outlined"
        size="small"
        fullWidth
        disabled={this.readOnly}
        sx={{ margin: 0 }}
      />
    );
  }

  setValue(value) {
    const parsedValue = parseFloat(value);
    this.value = isNaN(parsedValue) ? 0 : parsedValue;
    this.modifiedAt = new Date().toISOString();
    return this;
  }
}



export class CurrencyValue extends BaseValueObject {
  constructor(data) {
    super('currency_amount', data.value, data.property_name, data.display_name, data.description, data.modified_at, data.read_only, data.extra_parameters);
    this.supportedCurrencies = data.supported_currencies;
    this.currencySymbols = data.currency_symbols;
  }

  render() {
    return this.value ? <CurrencyAmountCellRenderer value={this.value} /> : <TextCellRenderer value={""} />;
  }

  getAmount()
  {
    return this.value?.amount || 0;
  }

  renderInput(onChange) {
    return (
      <div style={{ display: 'flex', gap: '16px' }}>
        <TextField
          id={`${this.propertyName}_amount`}
          name="amount"
          type="number"
          label={`${this.displayName} Amount`}
          value={this.value?.amount || ''}
          onChange={(e) => {
            const updatedValue = { ...this.value, amount: e.target.value };
            onChange(this.propertyName, updatedValue);
          }}
          fullWidth
          margin="normal"
          helperText={this.description}
          disabled={this.readOnly}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Enter amount"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel shrink id={`${this.propertyName}_currency_label`}>Currency</InputLabel>
          <Select
            labelId={`${this.propertyName}_currency_label`}
            id={`${this.propertyName}_currency`}
            value={this.value?.currency || ''}
            onChange={(e) => {
              const updatedValue = { ...this.value, currency: e.target.value };
              onChange(this.propertyName, updatedValue);
            }}
            disabled={this.readOnly}
            displayEmpty
            placeholder="Select currency"
          >
            <MenuItem value="" disabled>
              <em>Select currency</em>
            </MenuItem>
            {this.supportedCurrencies.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {this.currencySymbols[currency] || currency}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
  renderCompactInput(onChange) {
    return (
      <div style={{ display: 'flex', gap: '8px' }}>
        <TextField
          id={`${this.propertyName}_amount`}
          name="amount"
          type="number"
          value={this.value?.amount || ''}
          onChange={(e) => {
            const updatedValue = { ...this.value, amount: parseFloat(e.target.value) || 0 }; // Parse number properly
            onChange(this.propertyName, updatedValue); // Pass updated value to handlePropertyChange
          }}
          variant="outlined"
          size="small"
          fullWidth
          disabled={this.readOnly}
          sx={{ margin: 0 }}
        />
        <FormControl variant="outlined" size="small" sx={{ minWidth: 70 }}>
          <Select
            value={this.value?.currency || ''}
            onChange={(e) => {
              const updatedValue = { ...this.value, currency: e.target.value };
              onChange(this.propertyName, updatedValue); // Pass updated value to handlePropertyChange
            }}
            disabled={this.readOnly}
          >
            {this.supportedCurrencies.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {this.currencySymbols[currency] || currency}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export class ScheduleValue extends BaseValueObject {
  constructor(data) {
    super('schedule', data.value, data.property_name, data.display_name, data.description, data.modified_at, data.read_only, data.extra_parameters);
    this.scheduleStart = data.start;
    this.scheduleEnd = data.end;
    this.scheduleType = data.type;
  }

  render() {
    return <ScheduleCellRenderer value={this.value} />;
  }

  renderInput(onChange) {
    return (
      <ScheduleInputFields
        value={this.value}
        onChange={onChange}
        readOnly={this.readOnly}
        description={this.description}
        propertyName={this.propertyName}
      />
    );
  }
}

export class LabelValue extends BaseValueObject {
  constructor(data) {
    super(
      'label',
      data.value,
      data.property_name,
      data.display_name,
      data.description,
      data.modified_at,
      data.read_only,
      data.extra_parameters
    );

    // Parse the LabelVO structure from data.value
    const labelVO = data.value || {};
    this.item = labelVO.item || {};
    this.labelSet = labelVO.label_set || null;
    this.label = this.item.label || '';
    this.code = this.item.code || '';

    // Build acceptedValues from the label set
    this.acceptedValues = [];
    if (this.labelSet && this.labelSet.labels) {
      this.acceptedValues = this.labelSet.labels.map((labelItem) => ({
        label: labelItem.label,
        code: labelItem.code,
        description: labelItem.description,
      }));
    }
  }

  render() {
    return <span>{this.label}</span>;
  }

  renderInput(onChange) {
    return (
      <FormControl fullWidth margin="normal">
        <InputLabel>{this.displayName}</InputLabel>
        <Select
          id={this.propertyName}
          value={this.code || ''}
          onChange={(e) => {
            const selectedCode = e.target.value;
            const selectedLabelItem = this.acceptedValues.find(
              (val) => val.code === selectedCode
            );
            if (selectedLabelItem) {
              // Send the selected LabelItem to the backend
              onChange(this.propertyName, selectedLabelItem);
            }
          }}
          disabled={this.readOnly}
        >
          {this.acceptedValues.map((val) => (
            <MenuItem key={val.code} value={val.code}>
              {val.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  renderCompactInput(onChange) {
    return (
      <FormControl fullWidth variant="outlined" size="small" sx={{ margin: 0 }}>
        <Select
          id={this.propertyName}
          value={this.code || ''}
          onChange={(e) => {
            const selectedCode = e.target.value;
            const selectedLabelItem = this.acceptedValues.find(
              (val) => val.code === selectedCode
            );
            if (selectedLabelItem) {
              // Send the selected LabelItem to the backend
              onChange(this.propertyName, selectedLabelItem);
            }
          }}
          disabled={this.readOnly}
        >
          {this.acceptedValues.map((val) => (
            <MenuItem key={val.code} value={val.code}>
              {val.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  setValue(newValue) {
    // Handle different forms of newValue (string, object, etc.)
    if (typeof newValue === 'string') {
      // Assume newValue is a code or label
      const selectedLabelItem = this.acceptedValues.find(
        (val) => val.code === newValue || val.label === newValue
      );
      if (selectedLabelItem) {
        this.item = selectedLabelItem;
        this.label = selectedLabelItem.label;
        this.code = selectedLabelItem.code;
      } else {
        // If not found, create a new item with the label
        this.item = { label: newValue, code: null };
        this.label = newValue;
        this.code = null;
      }
    } else if (typeof newValue === 'object' && newValue !== null) {
      // Assume newValue is a LabelItem object
      this.item = newValue;
      this.label = newValue.label || '';
      this.code = newValue.code || '';
    } else {
      // Reset to default if newValue is invalid
      this.item = { label: '', code: null };
      this.label = '';
      this.code = null;
    }
    this.modifiedAt = new Date().toISOString();
    return this;
  }
}



export class EmailValue extends BaseValueObject {
  constructor(data) {
    super('email', data.value, data.property_name, data.display_name, data.description, data.modified_at, data.read_only, data.extra_parameters);
  }

  render() {
    return <TextCellRenderer value={this.value} />;
  }
}

export class ImageValue extends BaseValueObject {
  constructor(data) {
    super('image', data.value, data.property_name, data.display_name, data.description, data.modified_at, data.read_only, data.extra_parameters);
  }

  render(onChange) {
    const imageUrl = this.value && typeof this.value === 'object' && this.value.url ? this.value.url : null;

    if (!imageUrl) {
      return (
        <div>
          {this.displayName || 'Aucun fichier disponible'}
        </div>
      );
    }

    return (
      <ImageValueRenderer 
        imageUrl={imageUrl} 
        displayName={this.displayName} 
      />
    );
  }

  renderInput(onChange) {
    return (
      <Button variant="contained" component="label" margin="normal" disabled={this.readOnly}>
        {this.displayName}
        <input
          type="file"
          hidden
          onChange={(e) => onChange(this.propertyName, e.target.files[0])}
          accept="image/*,application/pdf"
        />
      </Button>
    );
  }
}

// Fonction pour gérer l'affichage du modal avec le PDF
function ImageValueRenderer({ imageUrl, displayName }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div>
      <Button
        variant="contained"
        startIcon={<PictureAsPdfIcon />}
        onClick={openModal}
        style={{ textTransform: 'none' }}
      >
        {displayName}
      </Button>

      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <PDFViewerObject filePath={imageUrl} />
      </Modal>
    </div>
  );
}

export class PhoneNumberValue extends BaseValueObject {
  constructor(data) {
    super('phone', data.value, data.property_name, data.display_name, data.description, data.modified_at, data.read_only, data.extra_parameters);
  }

  render() {
    return <TextCellRenderer value={this.value} />;
  }

  renderInput(onChange) {
    return (
      <TextField
        id={this.propertyName}
        type="tel"
        label={this.displayName}
        value={this.value || ''}
        onChange={(e) => onChange(this.propertyName, e.target.value)}
        fullWidth
        margin="normal"
        helperText={this.description}
        disabled={this.readOnly}
      />
    );
  }
}

export class JsonValue extends BaseValueObject {
  constructor(data) {
    super('json', data.value, data.property_name, data.display_name, data.description, data.modified_at, data.read_only, data.extra_parameters);
  }

  render() {
    return <JsonCellRenderer value={this.value} />;
  }

  renderInput(onChange) {
    return (
      <TextField
        id={this.propertyName}
        multiline
        rows={4}
        label={this.displayName}
        value={JSON.stringify(this.value, null, 2) || ''}
        onChange={(e) => {
          try {
            const jsonValue = JSON.parse(e.target.value);
            onChange(this.propertyName, jsonValue);
          } catch (error) {
            // Handle JSON parsing error
          }
        }}
        fullWidth
        margin="normal"
        helperText={this.description}
        disabled={this.readOnly}
      />
    );
  }
}

export function resolveValueObject(data) {
  // Check if the data is a full property definition or just a primitive value
  if (typeof data !== 'object' || data === null || !('type' in data)) {
    return new PrimitiveValue(data);
  }

  // If it's a full property definition, proceed as before
  switch (data.type) {
    case 'string':
    case 'text':
      return new TextValue(data);
    case 'date':
    case 'datetime':
      return new DateValue(data);
    case 'currency_amount':
      return new CurrencyValue(data);
    case 'schedule':
      return new ScheduleValue(data);
    case 'label':
      return new LabelValue(data);
    case 'email':
      return new EmailValue(data);
    case 'image':
      return new ImageValue(data);
    case 'phone':
      return new PhoneNumberValue(data);
    case 'json':
      return new JsonValue(data);
    case 'number':
      return new NumberValue(data);
    default:
      // If the type is not recognized, treat it as a primitive value
      return new PrimitiveValue(data.value);
  }
}