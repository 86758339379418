import React, { useState } from 'react';
import ResponsiveNavbar from './ResponsiveNavbar';
import AdminSidebar from '../Components/Admin/AdminSidebar';
import { Box } from '@mui/material';

const Dashboard = ({ children, dashboardTitle, withSidebar = false }) => {
    const [sidebarOpen, setSidebarOpen] = useState(!withSidebar);
    const navbarHeight = 80; // Adjust based on your navbar's height (default height for AppBar is 80px)

    return (
        <div className="wrapper">
            <div className="min-h-screen w-full bg-[#f8fafc] relative">
                <Box sx={{ width: '100%' }}>
                    <ResponsiveNavbar />
                    {withSidebar && <AdminSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />}
                    <Box
                        component="main"
                        sx={{
                            backgroundImage: "url('/dist/img/baseapp_background.svg')",
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            justifyContent: 'between',
                            width: "100%",
                            padding:"2rem",
                            paddingTop: `${navbarHeight}px`,
                        }}
                    >   
                        {children}
                    </Box>
                </Box>
            </div>
        </div>
    );
}

export default Dashboard;
