import React, { useState, useContext, useEffect } from 'react';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { TextInputWithIcon } from '../../Components/InputFields';
import UserSearch from '../Admin/UserSearch';
import TypeDropdown from '../../Components/TypeDropdown';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const CreateEmailTemplate = () => {
    const { sendRequestWithToast } = useContext(HttpRequestContext);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCrmType, setSelectedCrmType] = useState('');
    const [postmarkId, setPostmarkId] = useState('');
    const [fields, setFields] = useState([{ name: '', value: '' }]);
    const [displayName, setDisplayName] = useState('');
    const [placeholders, setPlaceholders] = useState([]);

    const handleUserSearch = (user) => {
        setSelectedUser(user);
    };

    const handleCrmTypeChange = async (selectedType) => {
        setSelectedCrmType(selectedType);
    
        if (selectedType) {
            try {
                const apiPath = `crm/types/read/${selectedType}`;
                const response = await sendRequestWithToast({
                    apiPath,
                    type: 'GET',
                    errorMessage: "Erreur lors de la récupération des placeholders",
                });
    
                if (response) {
                    // Récupérer les placeholders du type CRM actuel
                    let placeholdersSet = new Set(Object.keys(response.properties || {}));
    
                    // Récupérer les placeholders des types parents
                    if (response.parent_links && response.parent_links.length > 0) {
                        const parentPlaceholders = await Promise.all(
                            response.parent_links.map(async (parentType) => {
                                const parentApiPath = `crm/types/read/${parentType}`;
                                const parentResponse = await sendRequestWithToast({
                                    apiPath: parentApiPath,
                                    type: 'GET',
                                    errorMessage: `Erreur lors de la récupération des placeholders du parent ${parentType}`,
                                });
    
                                if (parentResponse && parentResponse.properties) {
                                    return Object.keys(parentResponse.properties).map(
                                        (prop) => `${parentType}_${prop}` // Préfixer les placeholders des parents
                                    );
                                }
                                return [];
                            })
                        );
    
                        // Ajouter les placeholders des parents à l'ensemble
                        parentPlaceholders.forEach((placeholdersArray) => {
                            placeholdersArray.forEach((ph) => placeholdersSet.add(ph));
                        });
                    }
    
                    // Mettre à jour les placeholders dans le state
                    setPlaceholders([...placeholdersSet]);
                } else {
                    setPlaceholders([]);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des placeholders:', error);
                setPlaceholders([]);
            }
        } else {
            setPlaceholders([]);
        }
    };

    const handleAddField = () => {
        setFields([...fields, { name: '', value: '' }]);
    };

    const handleFieldChange = (index, field, value) => {
        const newFields = [...fields];
        newFields[index][field] = value;
        setFields(newFields);
    };

    const handleRemoveField = (index) => {
        const newFields = fields.filter((_, i) => i !== index);
        setFields(newFields);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const fieldsObject = fields.reduce((obj, field) => {
            if (field.name) {
                obj[field.name] = field.value;
            }
            return obj;
        }, {});

        const newTemplate = {
            postmark_id: postmarkId,
            fields: fieldsObject,
            display_name: displayName,
            placeholders: placeholders,
            crm_type: selectedCrmType,
            user_id: selectedUser.id,
        };

        try {
            const response = await sendRequestWithToast({
                apiPath: '/emailtemplate/create',
                type: 'POST',
                request: newTemplate,
                successMessage: "Template créé avec succès!",
                errorMessage: "Erreur lors de la création du template",
            });

            if (response.success) {
                console.log('Template created successfully:', response);
            } else {
                console.error('Error creating template:', response.message);
            }
        } catch (error) {
            console.error('Erreur lors de la création du template:', error);
        }
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h5" className="mb-5">
                    Créer un nouveau Template d'Email
                </Typography>
                <UserSearch onSearch={handleUserSearch} />
                {selectedUser && (
                    <form onSubmit={handleSubmit} style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                        <TypeDropdown
                            selectedType={selectedCrmType}
                            onTypeChange={handleCrmTypeChange}
                            userEmail={selectedUser ? selectedUser.email : null}
                        />

                        <TextInputWithIcon
                            id="postmark-id"
                            type="text"
                            placeholder="Postmark ID"
                            label="Postmark ID"
                            iconName="email"
                            value={postmarkId}
                            onChange={(e) => setPostmarkId(e.target.value)}
                            required
                        />
                        <TextInputWithIcon
                            id="template-name"
                            type="text"
                            placeholder="Nom du Template"
                            label="Nom du Template"
                            iconName="text_fields"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                            required
                        />
                        <Box mt={2}>
                            <Typography variant="h6">Fields</Typography>
                            {fields.map((field, index) => (
                                <Box key={index} display="flex" alignItems="center" mt={2}>
                                    <TextField
                                        label="Nom du Field"
                                        value={field.name}
                                        onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Valeur du Field"
                                        value={field.value}
                                        onChange={(e) => handleFieldChange(index, 'value', e.target.value)}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <IconButton onClick={() => handleRemoveField(index)} color="secondary">
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            ))}
                            <Button variant="outlined" onClick={handleAddField} color="primary" style={{ marginTop: 16 }}>
                                Ajouter un Field
                            </Button>
                        </Box>
                        <Box mt={2} display="flex" flexWrap="wrap">
                            {placeholders.map((ph, index) => (
                                <Chip
                                    key={index}
                                    label={ph}
                                    style={{ margin: 2 }}
                                />
                            ))}
                        </Box>
                        <Button
                            color='primary'
                            variant="contained"
                            ripple="true"
                            className='w-full md:w-auto mt-5'
                            type="submit"
                        >
                            Créer le Template
                        </Button>
                    </form>
                )}
            </CardContent>
        </Card>
    );
};

export default CreateEmailTemplate;
