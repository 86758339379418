import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { UserContext } from '../Components/UserProvider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { HttpRequestContext } from '../Components/HttpRequestProvider';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const ResponsiveNavbar = () => {
    const { logout, user } = useContext(UserContext);
    const { sendRequest } = useContext(HttpRequestContext);
    const [crmTypes, setCrmTypes] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [transactionsAnchorEl, setTransactionsAnchorEl] = useState(null);
    const [listesAnchorEl, setListesAnchorEl] = useState(null);
    const [workflowAnchorEl, setWorkflowAnchorEl] = useState(null);

    useEffect(() => {
        const fetchCrmTypes = async () => {
            try {
                const responseData = await sendRequest({
                    apiPath: 'crm/types/list?items_per_page=-1&show_hidden=1',
                    type: 'GET',
                });
                setCrmTypes(responseData.rows);
            } catch (err) {
                console.error("Error fetching CRM Types:", err.message);
            }
        };
        
        fetchCrmTypes();
    }, [sendRequest]);

    const encodageChildren = crmTypes
    .filter(type => !type.hidden) // Filter out items where type.hidden is true
    .map(type => ({
        href: `/crm/type/${type.type_name}`,
        label: type.display_name
    }));

    const workflowChildren = crmTypes.map(type => ({
        href: `/flow/${type.type_name}`,
        label: type.display_name
    }));

    const navLinks = [
        { href: '/my-website', label: 'Mon Site' },
        {
            label: 'Workflow',
            onClick: (event) => setWorkflowAnchorEl(event.currentTarget),
            children: workflowChildren
        },
        { href: '/invoicing', label: 'Facturation' },
        {
            label: 'Transactions',
            onClick: (event) => setTransactionsAnchorEl(event.currentTarget),
            children: [
                { href: '/transactions/bulk-import', label: 'Bulk Import' },
                { href: '/transactions/matching', label: 'Matching Client' },
                { href: '/transactions/invoice-matching', label: 'Matching Factures' },
            ]
        },
        //DEVELOPMENT { href: '/appointments/create', label: 'Appointments' },
        { href: '/crm/manage-types', label: 'Objects CRM' },
        {
            label: 'Listes', 
            onClick: (event) => setListesAnchorEl(event.currentTarget),
            children: encodageChildren
        },
        user?.admin && { href: '/admin/dashboard', label: 'Admin Dashboard' },
        { onClick: logout, label: 'Logout' }
    ].filter(Boolean); // Remove null items

    const toggleDrawer = (isOpen) => () => {
        setDrawerOpen(isOpen);
    };

    const handleMenuClose = () => {
        setTransactionsAnchorEl(null);
        setListesAnchorEl(null);
        setWorkflowAnchorEl(null);
    };

    const renderMobileMenu = (
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <List>
                {navLinks.map((link, index) => 
                    link.children ? (
                        <ListItem button onClick={link.onClick} key={index + "-head"}>
                            <ListItemText primary={link.label} key={index + "-items"} />
                            <Menu
                                anchorEl={link.label === 'Transactions' ? transactionsAnchorEl : (link.label === 'Listes' ? listesAnchorEl : workflowAnchorEl)}
                                open={Boolean(link.label === 'Transactions' ? transactionsAnchorEl : (link.label === 'Listes' ? listesAnchorEl : workflowAnchorEl))}
                                onClose={handleMenuClose}
                            >
                                {link.children.map((childLink) => (
                                    <MenuItem 
                                        key={childLink.href} 
                                        onClick={handleMenuClose}
                                        component={Link} 
                                        to={childLink.href}
                                        style={{ width: '100%' }}
                                    >
                                        {childLink.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </ListItem>
                    ) : (
                        <ListItem button key={index} onClick={link.onClick} href={link.href}>
                            <ListItemText primary={link.label} />
                        </ListItem>
                    )
                )}
            </List>
        </Drawer>
    );

    return (
        <AppBar position="fixed" style={{ backgroundColor: '#ffffff', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', zIndex: theme.zIndex.drawer + 1 }}>
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="home" href="/">
                    <img src="/assets/images/logo2.svg" alt="Company Logo" style={{ width: '30px' }} />
                    <h1 className="ml-3 font-bold text-xl" style={{ color: theme.palette.primary.main }}>Innovaplus</h1>
                </IconButton>

                {isMobile ? (
                    <>
                        <div style={{ flexGrow: 1 }}></div>
                        <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                        {renderMobileMenu}
                    </>
                ) : (
                    <>
                        <div style={{ flexGrow: 1 }}></div>
                        {navLinks.map((link, index) => 
                            link.children ? (
                                <div key={link.label}>
                                    <Button color="inherit" onClick={link.onClick}>
                                        {link.label}
                                    </Button>
                                    <Menu
                                        anchorEl={link.label === 'Transactions' ? transactionsAnchorEl : (link.label === 'Listes' ? listesAnchorEl : workflowAnchorEl)}
                                        open={Boolean(link.label === 'Transactions' ? transactionsAnchorEl : (link.label === 'Listes' ? listesAnchorEl : workflowAnchorEl))}
                                        onClose={handleMenuClose}
                                    >
                                        {link.children.map((childLink) => (
                                            <MenuItem 
                                                key={childLink.href} 
                                                onClick={handleMenuClose}
                                                component={Link} 
                                                to={childLink.href}
                                                style={{ width: '100%' }}
                                            >
                                                {childLink.label}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            ) : (
                                <Button key={index + "-button"} color="inherit" onClick={link.onClick} href={link.href}>
                                    {link.icon} {link.label}
                                </Button>
                            )
                        )}
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default ResponsiveNavbar;
