import { useContext, useCallback } from 'react';
import { HttpRequestContext } from "./HttpRequestProvider";
import { resolveValueObject } from './ValueObjects';
import { ContainerValueObject } from './ValueObjectsContainer';


export function ContainerListReader() {
  const { sendRequest } = useContext(HttpRequestContext);

  const fetchData = useCallback(async (endpoint, params = {}, returnValueObject = false) => {
    const queryParams = new URLSearchParams(params).toString();
    const url = `${endpoint}?${queryParams}`;
    
    try {
      const response = await sendRequest({ apiPath: url, type: 'GET' });
      return transformResponse(response, returnValueObject);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }, [sendRequest]);

  const transformResponse = useCallback((response, returnValueObject) => {
    const transformedRows = response.rows.map(row => {
      const transformedRow = {};
      for (const [key, value] of Object.entries(row)) {
        transformedRow[key] = resolveValueObject(value);
      }
      return returnValueObject ? new ContainerValueObject(row) : transformedRow;
    });

    return {
      ...response,
      rows: transformedRows,
    };
  }, []);

  return { fetchData };
}