import React from 'react';
import Modal from '../../Components/Modal';
import CommercialDocumentTable from './CommercialDocumentTable';
import { Typography, Button } from '@mui/material';

const OverdueDocuments = ({ apiType, isOpen, onClose }) => {
    const overdueFilters = {
        'date_echeance': { '$lt': new Date().toISOString() },
        'status': { '$nin': ['Payé', 'Payée', 'Brouillon'] },
    };

    return (
        <Modal isOpen={isOpen} closeModal={onClose}>
            <Typography variant="h6" color="primary" gutterBottom>
                Documents en Retard
            </Typography>
            <CommercialDocumentTable 
                apiType={apiType}
                entityName="Documents en retard"
                filters={overdueFilters} 
            />
            <Button onClick={onClose} variant="contained" color="primary" style={{ marginTop: '20px' }}>
                Fermer
            </Button>
        </Modal>
    );
};

export default OverdueDocuments;
