import React, { useState, useEffect, useContext } from 'react';
import { Modal, Box, Button, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Checkbox, ListItemText, Alert  } from '@mui/material';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import PropertyInputField from '../../Components/PropertyInputField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const EditNodeModal = ({ open, onClose, onSave, initialData, typeName, nodeType }) => {
  const { sendRequest } = useContext(HttpRequestContext);
  const [formData, setFormData] = useState({
    emailProperty: initialData?.emailProperty || '',
    emailPropertyType: initialData?.emailPropertyType || '',
    templateId: initialData?.templateId || '',
    actionType: initialData?.triggerType || 'création',
    selectedProperties: initialData?.selectedProperties || [],
    waitTime: initialData?.waitTime || '',
    waitUnit: initialData?.waitUnit || 'minute',
    editProperty: initialData?.editProperty || '',
    editValue: initialData?.editValue || '',
    ifElseProperty: initialData?.ifElseProperty || '',
    ifElseValue: initialData?.ifElseValue || '',
    dateProperty: initialData?.dateProperty || '',
    waitDirection: initialData?.waitDirection || 'before',
    childType: initialData?.childType || '', // Ajout du childType
  });

  const [crmProperties, setCrmProperties] = useState([]);
  const [emailProperties, setEmailProperties] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [dateProperties, setDateProperties] = useState([]);
  const [error, setError] = useState(null);

  const fetchPropertiesByType = async (propertyTypes) => {
    const response = await sendRequest({
      apiPath: `/crm/type/${typeName}/get-properties?types=${propertyTypes.join(',')}`,
      type: 'GET',
    });
    return response.properties || {};
  };

  useEffect(() => {
    const fetchCrmType = async () => {
      if (['trigger', 'edit', 'ifElse'].includes(nodeType)) {
        const response = await sendRequest({
          apiPath: `/crm/types/read/${typeName}`,
          type: 'GET',
        });
        setCrmProperties(Object.values(response.properties) || []);
      }
    };


    const fetchEmailProperties = async () => {
      if (nodeType === 'sendEmail') {
        const properties = await fetchPropertiesByType(['email']);
        
        if (properties.email && Object.keys(properties.email).length > 0) {
          setEmailProperties(Object.values(properties.email));
          setError(null);
        } else {
          setEmailProperties([]);
          setError('Aucune propriété email disponible pour ce type.');
        }
      } else if (nodeType === 'sendEmailToChildren') {
        const response = await sendRequest({
          apiPath: `/crm/type/${typeName}/childs`,
          type: 'GET',
        });
  
        const childTypes = response.childs || [];
        let emailProps = [];
  
        for (const childType of childTypes) {
          const propertiesResponse = await sendRequest({
            apiPath: `/crm/type/${childType.type_name}/get-properties?types=email`,
            type: 'GET',
          });
  
          const childEmailProperties = propertiesResponse.properties?.email;
  
          if (childEmailProperties && Object.keys(childEmailProperties).length > 0) {
            emailProps.push({
              type_name: childType.type_name,
              properties: Object.values(childEmailProperties),
            });
          }
        }
  
        if (emailProps.length > 0) {
          setEmailProperties(emailProps);
          setError(null);
        } else {
          setEmailProperties([]);
          setError('Aucune propriété email disponible pour les types enfants.');
        }
      }
    };

    const fetchEmailTemplates = async () => {
      if (nodeType === 'sendEmail' || nodeType === 'sendEmailToChildren') {
        const response = await sendRequest({
          apiPath: `/emailtemplate/user`,
          type: 'GET',
        });
        setEmailTemplates(response.templates || []);
      }
    };

    const fetchDateProperties = async () => {
      if (nodeType === 'waitUntil') {
        const properties = await fetchPropertiesByType(['date', 'datetime', 'schedule']);
        setDateProperties([
          ...(properties.date || []),
          ...(properties.datetime || []),
          ...(properties.schedule || []),
        ]);
      }
    };

    fetchCrmType();
    fetchEmailProperties();
    fetchEmailTemplates();
    fetchDateProperties();
  }, [typeName, sendRequest, nodeType]);

  useEffect(() => {
    if (initialData) {
      setFormData({
        emailProperty: initialData?.emailProperty || '',
        emailPropertyType: initialData?.emailPropertyType || '',
        templateId: initialData?.templateId || '',
        actionType: initialData?.triggerType || 'création',
        selectedProperties: initialData?.selectedProperties || [],
        waitTime: initialData?.waitTime || '',
        waitUnit: initialData?.waitUnit || 'minute',
        editProperty: initialData?.editProperty || '',
        editValue: initialData?.editValue || '',
        ifElseProperty: initialData?.ifElseProperty || '',
        ifElseValue: initialData?.ifElseValue || '',
        dateProperty: initialData?.dateProperty || '',
        waitDirection: initialData?.waitDirection || 'before',
        childType: initialData?.childType || '', // Ajout du childType
      });
    }
  }, [initialData]);

  const handleChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === 'childType' && { emailProperty: '' }), // Réinitialiser emailProperty si childType change
    }));
  };


  const handleSave = () => {
    console.log('FormData on Save:', formData);
    onSave(formData);
    onClose();
  };

  const renderTriggerFields = () => (
    <>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="action-type-label">Action Type</InputLabel>
        <Select
          labelId="action-type-label"
          id="actionType"
          name="actionType"
          value={formData.actionType}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          <MenuItem value="création">Création</MenuItem>
          <MenuItem value="modification">Modification</MenuItem>
          <MenuItem value="modification_de_propriété">Modification de propriété</MenuItem>
        </Select>
      </FormControl>
      {formData.actionType === 'modification_de_propriété' && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="selected-properties-label">Properties</InputLabel>
          <Select
            labelId="selected-properties-label"
            id="selectedProperties"
            name="selectedProperties"
            multiple
            value={formData.selectedProperties}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {crmProperties.map((property) => (
              <MenuItem key={property.property_name} value={property.property_name}>
                <Checkbox checked={formData.selectedProperties.indexOf(property.property_name) > -1} />
                <ListItemText primary={property.display_name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );

  const renderWaitFields = () => (
    <>
      <TextField
        id="waitTime"
        name="waitTime"
        label="Wait Time"
        type="number"
        fullWidth
        margin="normal"
        value={formData.waitTime}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="wait-unit-label">Unit</InputLabel>
        <Select
          labelId="wait-unit-label"
          id="waitUnit"
          name="waitUnit"
          value={formData.waitUnit}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          <MenuItem value="minute">Minutes</MenuItem>
          <MenuItem value="hour">Hours</MenuItem>
          <MenuItem value="day">Days</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  const renderWaitUntilFields = () => (
    <>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="date-property-label">Date Property</InputLabel>
        <Select
          labelId="date-property-label"
          id="dateProperty"
          name="dateProperty"
          value={formData.dateProperty}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {dateProperties.map((property) => (
            <MenuItem key={property.property_name} value={property.property_name}>
              {property.display_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        id="waitTime"
        name="waitTime"
        label="Wait Time"
        type="number"
        fullWidth
        margin="normal"
        value={formData.waitTime}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
      />

      <FormControl fullWidth margin="normal">
        <InputLabel id="wait-unit-label">Time Unit</InputLabel>
        <Select
          labelId="wait-unit-label"
          id="waitUnit"
          name="waitUnit"
          value={formData.waitUnit}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          <MenuItem value="minute">Minutes</MenuItem>
          <MenuItem value="hour">Hours</MenuItem>
          <MenuItem value="day">Days</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel id="wait-direction-label">Direction</InputLabel>
        <Select
          labelId="wait-direction-label"
          id="waitDirection"
          name="waitDirection"
          value={formData.waitDirection}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          <MenuItem value="before">Before</MenuItem>
          <MenuItem value="after">After</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  const renderEditFields = () => (
    <>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="edit-property-label">Property</InputLabel>
        <Select
          labelId="edit-property-label"
          id="editProperty"
          name="editProperty"
          value={formData.editProperty}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {crmProperties.map((property) => (
            <MenuItem key={property.property_name} value={property.property_name}>
              {property.display_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {formData.editProperty && (
        <PropertyInputField
          property={crmProperties.find(prop => prop.property_name === formData.editProperty)}
          value={formData.editValue}
          onChange={(name, value) => handleChange('editValue', value)}
        />
      )}
    </>
  );

  const renderIfElseFields = () => (
    <>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="ifElse-property-label">Property</InputLabel>
        <Select
          labelId="ifElse-property-label"
          id="ifElseProperty"
          name="ifElseProperty"
          value={formData.ifElseProperty}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {crmProperties.map((property) => (
            <MenuItem key={property.property_name} value={property.property_name}>
              {property.display_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {formData.ifElseProperty && (
        <PropertyInputField
          property={crmProperties.find(prop => prop.property_name === formData.ifElseProperty)}
          value={formData.ifElseValue}
          onChange={(name, value) => handleChange('ifElseValue', value)}
        />
      )}
    </>
  );

  const renderSendEmailFields = () => (
    <>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="email-property-type-label">Email Property Type</InputLabel>
        <Select
          labelId="email-property-type-label"
          id="emailPropertyType"
          name="emailPropertyType"
          value={formData.emailPropertyType}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {emailProperties.map((property) => (
            <MenuItem key={property.type_name} value={property.type_name}>
              {property.type_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="email-property-label">Email Property</InputLabel>
        <Select
          labelId="email-property-label"
          id="emailProperty"
          name="emailProperty"
          value={formData.emailProperty}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {emailProperties
            .filter(property => property.type_name === formData.emailPropertyType)
            .map((property) => (
              <MenuItem key={property.property_name} value={property.property_name}>
                {property.display_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="template-id-label">Email Template</InputLabel>
        <Select
          labelId="template-id-label"
          id="templateId"
          name="templateId"
          value={formData.templateId}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {emailTemplates.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.display_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

  const renderSendEmailToChildrenFields = () => (
    <>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="child-type-label">Child Type</InputLabel>
        <Select
          labelId="child-type-label"
          id="childType"
          name="childType"
          value={formData.childType}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {emailProperties.map((child) => (
            <MenuItem key={child.type_name} value={child.type_name}>
              {child.type_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  
      <FormControl fullWidth sx={{ mt: 2 }} disabled={!formData.childType}>
        <InputLabel id="email-property-label">Email Property</InputLabel>
        <Select
          labelId="email-property-label"
          id="emailProperty"
          name="emailProperty"
          value={formData.emailProperty}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {emailProperties
            .find((child) => child.type_name === formData.childType)
            ?.properties.map((property) => (
              <MenuItem key={property.property_name} value={property.property_name}>
                {property.display_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
  
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="template-id-label">Email Template</InputLabel>
        <Select
          labelId="template-id-label"
          id="templateId"
          name="templateId"
          value={formData.templateId}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {emailTemplates.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.display_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
  
  


  return (
    <Modal open={open} onClose={onClose} aria-labelledby="edit-node-title">
      <Box sx={style}>
        <Typography id="edit-node-title" variant="h6" component="h2">
          Edit {nodeType.charAt(0).toUpperCase() + nodeType.slice(1)} Node
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {nodeType === 'trigger' && renderTriggerFields()}
        {nodeType === 'wait' && renderWaitFields()}
        {nodeType === 'waitUntil' && renderWaitUntilFields()}
        {nodeType === 'edit' && renderEditFields()}
        {nodeType === 'ifElse' && renderIfElseFields()}
        {nodeType === 'sendEmail' && renderSendEmailFields()}
        {nodeType === 'sendEmailToChildren' && renderSendEmailToChildrenFields()}
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditNodeModal;