import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiContainer: {
      variants: [
        {
          props: { variant: 'responsiveContainerLeft' }, // Nom de la variante
          style: ({ theme }) => ({
            marginLeft: 0,   // Aligne à gauche
          }),
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#ffffff',
          backgroundColor: '#185675',
          '&:hover': {
            backgroundColor: '#123850',
          },
          borderRadius: '8px',
          textTransform: 'uppercase',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: 'linear-gradient(135deg, #c9e8f4 0%, #185675 50%, #123850 100%)',
          [theme.breakpoints.down('md')]: {
            background: 'linear-gradient(135deg, #c9e8f4 0%, #185675 50%, #123850 100%)',
          },
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#f5f5f5',  // Example of using custom background color
          color: '#185675',  // Example of using custom text color
          borderRight: '1px solid #ddd',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#c9e8f4',
            '&:hover': {
              backgroundColor: '#185675',
              color: '#ffffff',
            },
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: '#185675',
        },
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'level1' },
          style: {
            backgroundColor: '#f5f5f5',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
            width: '100%',
            marginBottom: '1rem',
            borderRadius: '12px',
          },
        },
        {
          props: { variant: 'level2' },
          style: {
            backgroundColor: '#ffffff',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            width: '100%',
            marginBottom: '1rem',
            borderRadius: '12px',
          },
        },
        {
          props: { variant: 'level3' },
          style: {
            backgroundColor: '#ececec',
            boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)',
            margin: '1rem 0',
            width: '100%',
            borderRadius: '12px',
          },
        },
        {
          props: { variant: 'error' },
          style: {
            backgroundColor: 'red',
            boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)',
            margin: '1rem 0',
            width: '100%',
            borderRadius: '12px',
          },
        },
      ],
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
        fullwidth: {
          padding: '24px 0',
          '&:last-child': {
            paddingBottom: '24px',
          },
          overflowX: 'auto',
        },
      },
      variants: [
        {
          props: { variant: 'fullwidth' },
          style: {
            padding: '24px 0',
            overflowX: 'auto',
          },
        },
      ],
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          marginBottom: '16px',
        },
        h5: {
          color: '#185675',
          fontSize: '1.5rem',
          fontWeight: 600,
        },
        small: {
          fontSize: '0.875rem',
          color: '#123850',
          opacity: 0.8,
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {},
        cardFooter: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderTop: '1px solid #E0E0E0',
          padding: '16px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#185675',
      light: '#c9e8f4',
      dark: '#123850',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#8cc63d',
      light: '#bef67a',
      dark: '#629028',
      contrastText: '#ffffff',
    },
    background: {
      default: '#f4f4f9',
      paper: '#ffffff',
    },
    text: {
      primary: '#333343',
      secondary: '#595959',
    },
    action: {
      active: '#123850',
      hover: '#185675',
      selected: '#c9e8f4',
      disabled: '#999999',
      disabledBackground: '#f4f4f9',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    button: {
      textTransform: 'none',
    },
    headline: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    body: {
      fontSize: '1rem',
      fontWeight: 400,
    },
  },
  borders: {
    default: '1px solid #ddd',
    rounded: '8px',
  },
  shadows: [
    'none',
    '0px 2px 4px rgba(0, 0, 0, 0.05)',
    '0px 4px 8px rgba(0, 0, 0, 0.1)',
    '0px 6px 12px rgba(0, 0, 0, 0.15)',
    '0px 8px 16px rgba(0, 0, 0, 0.2)',
    '0px 10px 20px rgba(0, 0, 0, 0.25)',
    '0px 12px 24px rgba(0, 0, 0, 0.3)',
    '0px 14px 28px rgba(0, 0, 0, 0.35)',
    '0px 16px 32px rgba(0, 0, 0, 0.4)',
    '0px 18px 36px rgba(0, 0, 0, 0.45)',
    '0px 20px 40px rgba(0, 0, 0, 0.5)',
    '0px 22px 44px rgba(0, 0, 0, 0.55)',
    '0px 24px 48px rgba(0, 0, 0, 0.6)',
    '0px 26px 52px rgba(0, 0, 0, 0.65)',
    '0px 28px 56px rgba(0, 0, 0, 0.7)',
    '0px 30px 60px rgba(0, 0, 0, 0.75)',
    '0px 32px 64px rgba(0, 0, 0, 0.8)',
    '0px 34px 68px rgba(0, 0, 0, 0.85)',
    '0px 36px 72px rgba(0, 0, 0, 0.9)',
    '0px 38px 76px rgba(0, 0, 0, 0.95)',
    '0px 40px 80px rgba(0, 0, 0, 1)',
    '0px 40px 80px rgba(0, 0, 0, 1)',
    '0px 40px 80px rgba(0, 0, 0, 1)',
    '0px 40px 80px rgba(0, 0, 0, 1)',
    '0px 40px 80px rgba(0, 0, 0, 1)',
  ],
});

export default theme;
