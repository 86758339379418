import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Dashboard from '../../Layouts/Dashboard';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import { SortableTable } from '../../Components/Table';
import KanbanBoard from '../../Components/Kanban';
import CreateEditContainerInstance from '../../Components/CreateEditContainerInstance';
import EditableContainerTable from '../../Components/EditableContainerTable';

const CreateCRMInstances = () => {
    const { typeName } = useParams();
    const { sendRequest } = useContext(HttpRequestContext);

    const [crmType, setCrmType] = useState(null);
    const [error, setError] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedInstance, setSelectedInstance] = useState(null);
    const [isBulkEditOpen, setIsBulkEditOpen] = useState(false); // State for bulk edit modal

    const [selectedKanbanColumn, setSelectedKanbanColumn] = useState('');
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // State to track unsaved changes

    useEffect(() => {
        const fetchCrmType = async () => {
            try {
                const responseData = await sendRequest({
                    apiPath: `crm/types/read/${typeName}`,
                    type: 'GET',
                });
                setCrmType(responseData);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchCrmType();
    }, [typeName, sendRequest]);

    const handleNewInstance = () => {
        setSelectedInstance(null);
        setIsModalOpen(true);
    };

    const handleEditInstance = (instance) => {
        setSelectedInstance(instance);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedInstance(null);
    };

    const handleSave = () => {
        setIsModalOpen(false);
        setIsBulkEditOpen(false);
        setHasUnsavedChanges(false); // Reset unsaved changes state after saving
    };

    const handleOpenBulkEdit = () => {
        setIsBulkEditOpen(true);
    };

    const handleCloseBulkEdit = () => {
        if (hasUnsavedChanges) {
            if (window.confirm("Vous avez des modifications non enregistrées. Voulez-vous vraiment fermer sans enregistrer ?")) {
                setIsBulkEditOpen(false);
            }
        } else {
            setIsBulkEditOpen(false);
        }
    };

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!crmType) {
        return <p>Loading...</p>;
    }

    const labelProperties = crmType ? Object.entries(crmType.properties)
        .filter(([_, property]) => property.type === 'label')
        .map(([key, property]) => ({
            label: property.display_name,
            value: key
        })) : [];
    if (labelProperties.length > 0 && selectedKanbanColumn === '') {
        setSelectedKanbanColumn(labelProperties[0].value);
    }

    const forcedColumns = selectedKanbanColumn && crmType && crmType.properties[selectedKanbanColumn]
        ? crmType.properties[selectedKanbanColumn].accepted_values
        : [];

    const customColumns = {
        edit: {
            type: 'action',
            display_name: 'Modifier',
            name: 'edit',
            sortable: false,
            actionCallback: (row) => {
                console.log(row);
                handleEditInstance(row);
            },
            tooltipContent: "Modifier"
        }
    };

    return (
        <Dashboard dashboardTitle={'Créations d\'instances'}>
            <Card variant="outlined" className="mb-4">
                <CardContent>
                    <div className="flex justify-between items-center mb-2">
                        <Typography variant='h5' color='textPrimary'>
                            Liste: {crmType.display_name}
                        </Typography>
                        <div>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleNewInstance}
                                style={{ marginRight: 10 }}
                            >
                                Nouveau
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleOpenBulkEdit}
                            >
                                Éditer en Lot
                            </Button>
                        </div>
                    </div>
                    <SortableTable
                        apiEndpoint={`/crm/instances/${typeName}/list`}
                        customColumns={customColumns}
                    />
                </CardContent>
            </Card>
            <Card variant="outlined" className="mb-4">
                <CardContent>
                    <div className="flex justify-between items-center mb-2">
                        <Typography variant='h5' color='textPrimary'>
                            Vue Kanban: {crmType.display_name}
                        </Typography>
                        <FormControl>
                            <InputLabel></InputLabel>
                            <Select
                                value={selectedKanbanColumn}
                                onChange={(e) => setSelectedKanbanColumn(e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {labelProperties.map((property) => (
                                    <MenuItem key={property.value} value={property.value}>
                                        {property.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <KanbanBoard
                        apiEndpoint={`/crm/instances/${typeName}/list`}
                        kanbanColumnField={selectedKanbanColumn}
                        forcedColumns={forcedColumns}
                    />
                </CardContent>
            </Card>
            {crmType && (
                <CreateEditContainerInstance
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    crmType={crmType}
                    selectedInstance={selectedInstance}
                    onSave={handleSave}
                />
            )}
            {/* Bulk Edit Modal for EditableContainerTable */}
            <Dialog open={isBulkEditOpen} onClose={handleCloseBulkEdit} maxWidth="md" fullWidth>
                <DialogTitle>Modification de Masse: {crmType.display_name}</DialogTitle>
                <DialogContent>
                    <EditableContainerTable
                        apiPath={`/crm/instances/${typeName}/list?items_per_page=-1`}
                        containerType={typeName}
                        modifyApiPath={`/crm/containers/update-multiple`}
                        onSave={handleSave} // This will close the modal after saving
                        setHasUnsavedChanges={setHasUnsavedChanges} // Pass the setHasUnsavedChanges function
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseBulkEdit} color="secondary">
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
        </Dashboard>
    );
};

export default CreateCRMInstances;
