import React from 'react';
import { Card, CardContent, Typography, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { TextInputWithIcon } from '../../Components/InputFields';

const LineItemTable = ({ lineItems, onLineItemChange }) => {
    // Calculer le prix TVAC à partir du prix HTVA et de la TVA
    const calculatePriceTVAC = (priceHTVA, taxRate) => {
        return (parseFloat(priceHTVA || 0) * (1 + parseFloat(taxRate || 0) / 100)).toFixed(2);
    };

    // Calculer le prix HTVA à partir du prix TVAC et de la TVA
    const calculatePriceHTVA = (priceTVAC, taxRate) => {
        return (parseFloat(priceTVAC || 0) / (1 + parseFloat(taxRate || 0) / 100));
    };

    // Calculer le total HTVA et le total TVAC
    const calculateTotals = () => {
        let totalHTVA = 0;
        let totalTVAC = 0;

        lineItems.forEach(item => {
            const quantity = parseFloat(item.quantity || 1);
            const priceHTVA = parseFloat(item.price || 0);
            const priceTVAC = parseFloat(item.priceTVAC || 0);

            totalHTVA += priceHTVA * quantity;
            totalTVAC += priceTVAC * quantity;
        });

        return { totalHTVA, totalTVAC };
    };

    const { totalHTVA, totalTVAC } = calculateTotals();

    const handleAddItem = () => {
        const newItem = { description: '', quantity: 1, price: 0, priceTVAC: 0, tax: 0 };
        onLineItemChange([...lineItems, newItem]);
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = lineItems.map((item, i) => {
            if (i === index) {
                let updatedItem = { ...item, [field]: value };

                // Si le prix HTVA ou le taux de TVA est modifié, recalculer le prix TVAC
                if (field === 'price' || field === 'tax') {
                    updatedItem.priceTVAC = calculatePriceTVAC(updatedItem.price, updatedItem.tax);
                }

                // Si le prix TVAC est modifié, recalculer le prix HTVA
                if (field === 'priceTVAC') {
                    updatedItem.price = calculatePriceHTVA(value, updatedItem.tax);
                }

                return updatedItem;
            }
            return item;
        });
        onLineItemChange(updatedItems);
    };

    const handleRemoveItem = (index) => {
        const updatedItems = lineItems.filter((_, i) => i !== index);
        onLineItemChange(updatedItems);
    };


    return (
        <Card variant="level3">
            <CardContent>
                <Typography variant="h5" color="primary" gutterBottom>
                    Produits et services
                </Typography>
                <TableContainer component={Paper} elevation={0}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell align="right">Quantité</TableCell>
                                <TableCell align="right">Prix HTVA</TableCell>
                                <TableCell align="right">TVA (%)</TableCell>
                                <TableCell align="right">Prix unitaire TVAC</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lineItems.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <TextInputWithIcon
                                            iconName="pencil-outline"
                                            value={item.description}
                                            onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                                            placeholder="Description"
                                            size="small"
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <TextInputWithIcon
                                            type="number"
                                            iconName="cube-outline"
                                            value={item.quantity.toString()}
                                            onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                                            placeholder="Quantité"
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <TextInputWithIcon
                                            type="number"
                                            iconName="tag-outline"
                                            value={item.price.toString()}
                                            onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                                            placeholder="Prix HTVA"
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <TextInputWithIcon
                                            type="number"
                                            iconName="calculator"
                                            value={item.tax.toString()}
                                            onChange={(e) => handleItemChange(index, 'tax', e.target.value)}
                                            placeholder="TVA (%)"
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <TextInputWithIcon
                                            type="number"
                                            iconName="cash-outline"
                                            value={item.priceTVAC.toString()}
                                            onChange={(e) => handleItemChange(index, 'priceTVAC', e.target.value)}
                                            placeholder="Prix TVAC"
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton aria-label="delete" color="error" onClick={() => handleRemoveItem(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddItem}
                    sx={{ mt: 3 }}
                >
                    Ajouter un élément
                </Button>
                
                <Card variant="level1" sx={{ mt: 4 }}>
                    <CardContent>
                        <Typography variant="h6" color="primary" gutterBottom>
                            Récapitulatif
                        </Typography>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell align="right">Quantité</TableCell>
                                        <TableCell align="right">Prix unitaire HTVA</TableCell>
                                        <TableCell align="right">TVA (%)</TableCell>
                                        <TableCell align="right">Total HTVA</TableCell>
                                        <TableCell align="right">Total TVAC</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {lineItems.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell align="right">{item.quantity}</TableCell>
                                            <TableCell align="right">{parseFloat(item.price).toFixed(2)} €</TableCell>
                                            <TableCell align="right">{item.tax}%</TableCell>
                                            <TableCell align="right">{(item.quantity * item.price).toFixed(2)} €</TableCell>
                                            <TableCell align="right">{(item.quantity * item.priceTVAC).toFixed(2)} €</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={4} align="right"><strong>Total</strong></TableCell>
                                        <TableCell align="right"><strong>{totalHTVA.toFixed(2)} €</strong></TableCell>
                                        <TableCell align="right"><strong>{totalTVAC.toFixed(2)} €</strong></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>

            </CardContent>
        </Card>
    );
};

export default LineItemTable;