import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, useTheme, Button, Popover, Switch, FormControlLabel, Card, CardContent } from '@mui/material';
import { DateRangePicker } from 'react-date-range';
import { addDays, subDays, format } from 'date-fns';
import { CalendarToday, ExpandMore, CompareArrows, TrendingUp, TrendingDown } from '@mui/icons-material';
import Dashboard from '../../Layouts/Dashboard';
import { ContainerListReader } from '../../Components/ContainerListReader';
import Scorecard from './Scorecard';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Custom hook for data fetching
const useDataFetcher = () => {
  const { fetchData } = ContainerListReader();

  const fetchTransactions = async (startDate, endDate) => {
    try {
      const filters = JSON.stringify({
        transaction_date: {
          $gte: startDate.toISOString(),
          $lte: endDate.toISOString()
        }
      });
      const response = await fetchData(`/crm/instances/transaction/list`, { items_per_page: -1, filters });
      return response.rows;
    } catch (error) {
      console.error("Error fetching transaction data:", error);
      throw error;
    }
  };

  return { fetchTransactions };
};

const CRMDashboard = () => {

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Card sx={{ p: 2, boxShadow: 2 }}>
          <Typography variant="subtitle2">{label}</Typography>
          {payload.map((entry, index) => (
            <Typography key={index} variant="body2" sx={{ color: entry.color }}>
              {entry.name}: €{entry.value.toFixed(2)}
            </Typography>
          ))}
        </Card>
      );
    }
    return null;
  };


  const theme = useTheme();
  const { fetchTransactions } = useDataFetcher();
  const [dateRanges, setDateRanges] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: 'selection'
    },
    {
      startDate: subDays(new Date(), 60),
      endDate: subDays(new Date(), 30),
      key: 'comparison'
    }
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [compareMode, setCompareMode] = useState(false);
  const [cashflowData, setCashflowData] = useState({
    transactions: [],
    comparisonTransactions: []
  });
  const [monthlyOverview, setMonthlyOverview] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const transactions = await fetchTransactions(dateRanges[0].startDate, dateRanges[0].endDate);

        let comparisonTransactions = [];
        if (compareMode) {
          comparisonTransactions = await fetchTransactions(dateRanges[1].startDate, dateRanges[1].endDate);
        }

        setCashflowData({
          transactions,
          comparisonTransactions
        });
      } catch (error) {
        setError("Failed to fetch cashflow data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, [dateRanges, compareMode]);

  useEffect(() => {
    const fetchMonthlyOverview = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const startDate = subDays(new Date(), 365 + 31);
        const endDate = new Date();
        const transactions = await fetchTransactions(startDate, endDate);

        // Generate 13-month cashflow overview
        const monthlyData = Array.from({ length: 13 }, (_, i) => {
          const monthStart = subDays(new Date(), (12 - i) * 30);
          const monthEnd = subDays(monthStart, -29);
          const monthTransactions = transactions.filter(
            (t) => new Date(t.transaction_date.value) >= monthStart && new Date(t.transaction_date.value) <= monthEnd
          );
          const totalNet = monthTransactions.reduce((sum, t) => sum + t.amount.getAmount(), 0);
          const totalIn = monthTransactions.filter(t => t.amount.getAmount() > 0).reduce((sum, t) => sum + t.amount.getAmount(), 0);
          const totalOut = monthTransactions.filter(t => t.amount.getAmount() < 0).reduce((sum, t) => sum - t.amount.getAmount(), 0);

          return {
            month: format(monthStart, 'MMM yyyy'),
            net: totalNet,
            in: totalIn,
            out: totalOut
          };
        });

        // Only use the last 12 months for display
        setMonthlyOverview(monthlyData.slice(1));
      } catch (error) {
        setError("Failed to fetch monthly overview data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchMonthlyOverview();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (ranges) => {
    const updatedRanges = [...dateRanges];
    updatedRanges[0] = ranges.selection;
    if (compareMode) {
      const daysDiff = Math.round((ranges.selection.endDate - ranges.selection.startDate) / (1000 * 60 * 60 * 24));
      updatedRanges[1] = {
        startDate: subDays(ranges.selection.startDate, daysDiff + 1),
        endDate: subDays(ranges.selection.startDate, 1),
        key: 'comparison'
      };
    }
    setDateRanges(updatedRanges);
  };

  const handleCompareToggle = (event) => {
    setCompareMode(event.target.checked);
    if (event.target.checked) {
      const daysDiff = Math.round((dateRanges[0].endDate - dateRanges[0].startDate) / (1000 * 60 * 60 * 24));
      setDateRanges([
        dateRanges[0],
        {
          startDate: subDays(dateRanges[0].startDate, daysDiff + 1),
          endDate: subDays(dateRanges[0].startDate, 1),
          key: 'comparison'
        }
      ]);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-range-popover' : undefined;

  const formatDateRange = (range) => {
    return `${format(range.startDate, 'MMM d, yyyy')} - ${format(range.endDate, 'MMM d, yyyy')}`;
  };

  return (
    <Dashboard dashboardTitle="CRM Dashboard - Rapport Cashflow">
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Grid container spacing={3} alignItems="center" mb={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Rapport Cashflow</Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={compareMode}
                  onChange={handleCompareToggle}
                  color="primary"
                />
              }
              label="Compare"
              componentsProps={{
                typography: {
                  sx: {
                    marginBottom: 0, // Supprime la marge pour le texte
                  }
                }
              }}
            />
            <Button
              aria-describedby={id}
              variant="outlined"
              onClick={handleClick}
              startIcon={<CalendarToday />}
              endIcon={<ExpandMore />}
              sx={{
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                },
              }}
            >
              {formatDateRange(dateRanges[0])}
              {compareMode && (
                <>
                  <CompareArrows sx={{ mx: 1 }} />
                  {formatDateRange(dateRanges[1])}
                </>
              )}
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box sx={{ p: 2 }}>
                <DateRangePicker
                  onChange={handleSelect}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={compareMode ? dateRanges : [dateRanges[0]]}
                  direction="horizontal"
                />
              </Box>
            </Popover>
          </Grid>
        </Grid>

        {isLoading && <Typography>Loading...</Typography>}
        {error && <Typography color="error">{error}</Typography>}

        {!isLoading && !error && (
          <Grid container spacing={3}>
            {/* Scorecard components for Cashflow */}
            <Grid item xs={12} md={4}>
              <Scorecard
                label="Net Cashflow"
                dataSet={cashflowData.transactions}
                compareDataSet={compareMode ? cashflowData.comparisonTransactions : null}
                valueExtractor={item => {return item.amount.getAmount()}}
                aggregation="sum"
                formatter={value => `€${value.toFixed(2)}`}
                color={theme.palette.primary.main}
                icon={<TrendingUp />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Scorecard
                label="Cashflow Entrées"
                dataSet={cashflowData.transactions.filter(item => item.amount.getAmount() > 0)}
                compareDataSet={compareMode ? cashflowData.comparisonTransactions.filter(item => item.amount.getAmount() > 0) : null}
                valueExtractor={item => {return item.amount.getAmount()}}
                aggregation="sum"
                formatter={value => `€${value.toFixed(2)}`}
                color={theme.palette.success.light}
                icon={<TrendingUp />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Scorecard
                label="Cashflow Sorties"
                dataSet={cashflowData.transactions.filter(item => item.amount.getAmount() < 0)}
                compareDataSet={compareMode ? cashflowData.comparisonTransactions.filter(item => item.amount.getAmount() < 0) : null}
                valueExtractor={item => {return -item.amount.getAmount()}}
                aggregation="sum"
                formatter={value => `€${value.toFixed(2)}`}
                color={theme.palette.error.light}
                icon={<TrendingDown />}
              />
            </Grid>
          </Grid>
        )}

        {/* Monthly Cashflow Overview Section */}
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12}>
            <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" fontWeight="bold" mb={2}>Aperçu des 12 derniers mois</Typography>
                <ResponsiveContainer width="100%" height={600}>
                  <BarChart data={monthlyOverview} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="month" 
                      angle={-45}
                      textAnchor="end"
                      height={70}
                      interval={0}
                      tick={{ fontSize: 12 }}
                    />
                    <YAxis 
                      tickFormatter={(value) => `€${value / 1000}k`}
                      tick={{ fontSize: 12 }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend wrapperStyle={{ paddingTop: '20px' }} />
                    <Bar dataKey="in" name="Entrées" fill={theme.palette.success.light} />
                    <Bar dataKey="out" name="Sorties" fill={theme.palette.error.light} />
                    <Bar dataKey="net" name="Net" fill={theme.palette.primary.main} />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>


      </Box>
    </Dashboard>
  );
};

export default CRMDashboard;