import React, { useEffect, useState, useContext } from 'react';
import Dashboard from '../Layouts/Dashboard';
import PhoneInput from 'react-phone-input-2';
import { BookingCard } from './Modules/BookingCard';
import { UserContext } from '../Components/UserProvider';
import 'react-phone-input-2/lib/style.css';
import KanbanBoard from '../Components/Kanban';
import { SortableTable } from '../Components/Table';
import { ContainerListReader } from '../Components/ContainerListReader'; // Import the useApiWrapper hook

const HomePage = () => {
    const { user } = useContext(UserContext);
    const { fetchData } = ContainerListReader(); // Use the useApiWrapper hook

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const result = await fetchData('/crm/instances/transaction/list', { page: 1, items_per_page: 10 });
                console.log('Transactions:', result); // Log the result to the console
            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        };

        fetchTransactions();
    }, []); // Empty dependency array means this effect runs once on component mount

    return (
        <Dashboard dashboardTitle={"Dashboard"}>
            {/* Your existing dashboard content */}
        </Dashboard>
    );
};

export default HomePage;