import React, { useEffect, useState, useContext } from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import AdminCreateEditContainerInstance from './AdminCreateEditContainerInstance';
import TypeDropdown from '../../Components/TypeDropdown';

const UserCRMData = ({ user }) => {
    const { sendRequest } = useContext(HttpRequestContext);
    const [crmData, setCrmData] = useState(null);
    const [crmTypes, setCrmTypes] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchCRMData = async () => {
            try {
                const response = await sendRequest({
                    apiPath: `/admin/crm/count_preset_containers?user_id=${user.id}`,
                    type: 'GET'
                });
                setCrmData(response.counts);
            } catch (error) {
                console.error('Error fetching CRM data:', error);
            }
        };

        const fetchCRMTypes = async () => {
            try {
                const response = await sendRequest({
                    apiPath: `/admin/crm/types/list?user_email=${user.email}&show_hidden=true`,
                    type: 'GET'
                });
                setCrmTypes(response.rows);
            } catch (error) {
                console.error('Error fetching CRM types:', error);
            }
        };

        if (user && user.id) {
            fetchCRMData();
            fetchCRMTypes();
        }
    }, [sendRequest, user]);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSaveContainer = (newContainerId) => {
        setModalOpen(false);
        // Optionally refresh CRM data here
    };

    const selectedCrmType = crmTypes.find(type => type.type_name === selectedType);

    if (!crmData) {
        return <Typography variant="h6">Aucune donnée CRM disponible</Typography>;
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    Données CRM
                </Typography>
                {Object.entries(crmData).map(([type, count]) => (
                    <Typography variant="body2" color="textSecondary" key={type}>
                        {`${type}: ${count}`}
                    </Typography>
                ))}

                <Typography variant="h5" gutterBottom>
                    Créer un nouveau CRM Container
                </Typography>
                <TypeDropdown
                    selectedType={selectedType}
                    onTypeChange={setSelectedType}
                    userEmail={user.email}
                />
                
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    sx={{ mt: 2 }}
                    disabled={!selectedCrmType}
                >
                    Créer
                </Button>

                {selectedCrmType && (
                    <AdminCreateEditContainerInstance
                        isOpen={modalOpen}
                        onClose={handleCloseModal}
                        crmType={selectedCrmType} // Pass the entire selected CRM type object
                        onSave={handleSaveContainer}
                        parentDefinitions={{}}
                        parentContainers={{}}
                        user={user}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default UserCRMData;
