import React, { useEffect, useMemo, useState, useCallback, useContext } from 'react';
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { Icon } from './Icons';
import Modal from './Modal';
import { HttpRequestContext } from './HttpRequestProvider'; 
import { ContainerListReader } from './ContainerListReader';
import { ActionCellRenderer } from './CellRenderers';

const categorizeColumns = (columns) => {
  const categories = {
    'Colonnes directes': []
  };

  columns.forEach(column => {
    if (column.name.includes('/')) {
      const parts = column.name.split('/');
      const mainCategory = parts[0];
      const subCategory = parts.slice(1).join('/');

      if (!categories[mainCategory]) {
        categories[mainCategory] = [];
      }

      categories[mainCategory].push({ ...column, subCategory });
    } else {
      categories['Colonnes directes'].push(column);
    }
  });

  return categories;
};

const ColumnSelectionModal = ({ isVisible, closeModal, availableColumns, visibleColumns, toggleColumnVisibility }) => {
  const categories = categorizeColumns(availableColumns.filter(col => col.type !== "list"));
  
  return (
    <Modal isOpen={isVisible} closeModal={closeModal}>
      <Box padding={2}>
        <Typography variant="h6" className="mb-4">Sélection des colonnes</Typography>
        <div className="max-h-[60vh] overflow-y-auto">
          {Object.entries(categories).map(([category, columns]) => (
            <div key={category} className="mb-4">
              <Typography variant="subtitle2" className="font-bold mb-2">{category}</Typography>
              <div className="pl-4">
                {columns.map((column) => (
                  <label key={column.name} className="flex items-center text-truncate hover:bg-gray-100 px-2 py-1">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      checked={visibleColumns.has(column.name)}
                      onChange={() => toggleColumnVisibility(column.name)}
                    />
                    <span className="ml-2">{column.display_name || column.subCategory || column.name}</span>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-6 flex justify-end">
          <Button onClick={closeModal} variant="outlined">
            Fermer
          </Button>
        </div>
      </Box>

    </Modal>
  );
};


export function SortableTable({ onMassAction, selectionKey, apiEndpoint, filters = {}, customColumns = {}, itemsPerPage = 10, initialSortColumn = null, initialSortDirection = 'asc', updateApiEndpoint, updatePropertyKey, initialVisibleColumns }) {
  const theme = useTheme();
  const { fetchData } = ContainerListReader();
  const { sendRequestWithToast } = useContext(HttpRequestContext);
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState(initialSortColumn ? { columnId: initialSortColumn, direction: initialSortDirection } : null);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLabelChanged, setIsLabelChanged] = useState(false);
  const [modifiedLabels, setModifiedLabels] = useState({});
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(new Set());
  const [selectedRows, setSelectedRows] = useState(new Set());
  const showCheckboxes = !!onMassAction;
  const stableFilters = useMemo(() => filters, [JSON.stringify(filters)]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const queryParams = {
        page: currentPage,
        items_per_page: itemsPerPage,
        ...(sortOrder && { sort: sortOrder.columnId, order: sortOrder.direction }),
        ...(Object.keys(stableFilters).length > 0 && { filters: JSON.stringify(stableFilters) })
      };
      const data = await fetchData(apiEndpoint, queryParams);
      setRows(data.rows);
      setTotalPages(data.totalPages);
      setAvailableColumns(data.columns);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [apiEndpoint, currentPage, itemsPerPage, sortOrder, stableFilters, fetchData]);

  useEffect(() => {
    loadData();
  }, [loadData, refreshTrigger]);
  // Ajoutez le useEffect ici, juste après le useEffect qui appelle loadData()
  useEffect(() => {
    if (availableColumns.length > 0 && visibleColumns.size === 0) {
      if (initialVisibleColumns && initialVisibleColumns.length > 0) {
        setVisibleColumns(new Set(initialVisibleColumns));
      } else {
        const allColumnNames = [...Object.keys(customColumns), ...availableColumns.map(col => col.name)];
        setVisibleColumns(new Set(allColumnNames));
      }
    }
  }, [availableColumns]);


  const toggleModalVisibility = () => setIsModalOpen(!isModalOpen);

  const toggleColumnVisibility = columnName => {
    setVisibleColumns(prev => {
      const newSet = new Set(prev);
      if (newSet.has(columnName)) {
        newSet.delete(columnName);
      } else {
        newSet.add(columnName);
      }
      return newSet;
    });
  };

  const handleSort = columnId => setSortOrder(prev => ({ columnId, direction: prev?.columnId === columnId && prev.direction === 'asc' ? 'desc' : 'asc' }));

  const handlePageChange = newPage => setCurrentPage(newPage);

  const handleLabelChange = (containerId, propertyName, newValue) => {
    // Ensure containerId is a primitive value and not an object
    if (containerId && containerId.value) {
      containerId = containerId.value;
    }

    console.log('Container ID:', containerId);
    console.log('Property Name:', propertyName);
    console.log('New Value:', newValue);

    setIsLabelChanged(true);
    setModifiedLabels(prev => ({
        ...prev,
        [containerId]: {
            ...prev[containerId],
            [propertyName]: newValue
        }
    }));
};

  const toggleRowSelection = (row) => {
    setSelectedRows(prevSelectedRows => {
        const newSelectedRows = new Set(prevSelectedRows);
        const rowKey = row[selectionKey];
        if (newSelectedRows.has(rowKey)) {
            newSelectedRows.delete(rowKey);
        } else {
            newSelectedRows.add(rowKey);
        }
        return newSelectedRows;
    });
};

const applyChanges = async () => {
  const updates = Object.entries(modifiedLabels).map(([containerId, newValues]) => {
      const propertyUpdates = {};

      Object.entries(newValues).forEach(([propertyName, newValue]) => {
          propertyUpdates[propertyName] = newValue;
      });

      return {
          container_id: containerId,
          property_values: propertyUpdates,
      };
  });

  console.log("Updates to be sent:", updates);  // Log the updates for debugging

  try {
      const response = await sendRequestWithToast({
          apiPath: 'crm/containers/update-multiple', // Assurez-vous que c'est le bon chemin
          type: "POST",
          request: { updates },
          successMessage: "Containers updated successfully",
          errorMessage: "Failed to update containers",
      });

      if (response) {
          setIsLabelChanged(false);
          setModifiedLabels({});
          setRefreshTrigger(!refreshTrigger);
      }
  } catch (error) {
      console.error("Error updating:", error);
  }
};

  const visibleColumnData = availableColumns.filter(col => 
    visibleColumns.has(col.name) && col.type !== "list"
  );

  const allColumns = [
    ...Object.entries(customColumns).map(([key, value]) => ({ name: key, ...value })),
    ...visibleColumnData
  ];

  const renderCell = (columnData, rowData) => {
    if (columnData.type === 'selection') {
        const isSelected = selectedRows.has(rowData[selectionKey]);
        return (
            <input 
                type="checkbox" 
                checked={isSelected} 
                onChange={() => toggleRowSelection(rowData)} 
            />
        );
    } else if (customColumns[columnData.name]) {
        return (
            <ActionCellRenderer
                onClickAction={() => customColumns[columnData.name].actionCallback(rowData)}
                tooltipContent={customColumns[columnData.name].tooltipContent}
                row={rowData}
                actionType={customColumns[columnData.name].actionType || 'edit'}
            />
        );
    }

    const valueObject = rowData[columnData.name];
    if (valueObject && typeof valueObject.render === 'function') {
        return valueObject.render((newValue) => {
            const containerId = rowData.id.value; // On suppose que rowData.id est toujours une PrimitiveValue
            handleLabelChange(containerId, columnData.name, newValue);
        });
    }
    return null;
};
  

  if (loading && rows.length === 0) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <div className="mb-4 flex justify-end">
        <Button variant="contained" color="primary" size="small" className="uppercase tracking-wider" onClick={toggleModalVisibility} startIcon={isModalOpen ? <Icon iconName="eye-slash" type="fa" /> : <Icon iconName="eye" type="fa" />}>
          {isModalOpen ? 'Masquer menu' : 'Menu colonnes'}
        </Button>
      </div>

      <ColumnSelectionModal 
        isVisible={isModalOpen} 
        closeModal={toggleModalVisibility} 
        availableColumns={availableColumns} 
        visibleColumns={visibleColumns} 
        toggleColumnVisibility={toggleColumnVisibility} 
      />

      <Card variant="outlined" style={{ overflowX: 'scroll' }}>
        <CardContent style={{ padding: "0px" }}>
          <table className="w-full min-w-max table-auto text-left">
            <thead style={{ backgroundColor: theme.palette.primary.light }}>
              <tr>
                {allColumns.map((column) => (
                  <th
                    key={column.name}
                    onClick={() => handleSort(column.name)}
                    className="cursor-pointer border-b border-blue-gray-100 bg-blue-gray-50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                    >
                      {column.display_name || column.name}
                      {!customColumns[column.name] && <ChevronUpDownIcon strokeWidth={2} className={`h-4 w-4 ${sortOrder?.columnId === column.name ? 'text-blue-500' : ''}`} />}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {allColumns.map((column, cellIndex) => (
                    <td key={cellIndex} className="p-4">
                      {renderCell(column, row)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </CardContent>
        <CardActions className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} sur {totalPages}
          </Typography>
          <div className="flex-grow flex justify-center items-center">
            {isLabelChanged && (
              <Button 
                variant="contained" 
                color="primary"
                size="small"
                className="tracking-wider"
                onClick={applyChanges}
              >
                Appliquer
              </Button>
            )}
          </div>

          {showCheckboxes && onMassAction && (
            <div className="flex-grow flex justify-center items-center">
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="tracking-wider"
                onClick={() => onMassAction.action(Array.from(selectedRows))}
              >
                {onMassAction.buttonText}
              </Button>
            </div>
          )}

          <div className="flex gap-2">
            <Button 
              variant="outlined" 
              size="small" 
              color="primary" 
              onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
              disabled={currentPage === 1}
            >
              Précédent
            </Button>
            <Button 
              variant="outlined" 
              size="small" 
              color="primary" 
              onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Suivant
            </Button>
          </div>
        </CardActions>
      </Card>
    </>
  );
}
