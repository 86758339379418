// components/alerts/WarningAlert.jsx
import React from 'react';
import { Alert, Collapse, IconButton, Typography, Box, Button } from '@mui/material';
import { Warning as WarningIcon, Close as CloseIcon } from '@mui/icons-material';

const WarningAlert = ({ 
    isVisible,
    onClose,
    onViewDetails,
    message,
}) => {
    return (
        <Collapse in={isVisible}>
            <Alert 
                severity="warning"
                icon={<WarningIcon fontSize="small" />}
                action={
                    <Box display="flex" alignItems="center" gap={1}>
                        <Button 
                            size="small" 
                            color="warning" 
                            variant="outlined"
                            onClick={onViewDetails}
                            sx={{ 
                                textTransform: 'none',
                                borderColor: 'warning.light',
                                '&:hover': {
                                    borderColor: 'warning.main',
                                    backgroundColor: 'warning.light',
                                }
                            }}
                        >
                            Voir les détails
                        </Button>
                        <IconButton
                            size="small"
                            onClick={onClose}
                            sx={{ color: 'warning.dark' }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                }
                sx={{
                    mb: 2,
                    '& .MuiAlert-message': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '& .MuiAlert-icon': {
                        color: 'warning.main',
                    },
                }}
            >
                <Typography variant="body2">
                    {message}
                </Typography>
            </Alert>
        </Collapse>
    );
};

export default WarningAlert;