import React, { useState, useEffect, useContext } from 'react';
import Dashboard from '../../Layouts/Dashboard';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import InvoiceForm from './InvoiceForm';
import RecuForm from './RecuForm';
import CreditNoteForm from './CreditNoteForm';
import CommercialDocumentTable from './CommercialDocumentTable';
import AccountingSidebar from './AccountingSidebar';
import ClientInfoCard from './ClientInfoCard';
import OverdueDocuments from './OverdueDocuments';
import { Box, Modal, Paper, FormControl, InputLabel, Select, MenuItem, Typography, Button, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const AccountingDashboard = () => {
    const { sendRequest } = useContext(HttpRequestContext);
    const [billingprofiles, setBillingProfiles] = useState([]);
    const [selectedBillingProfileId, setSelectedBillingProfileId] = useState('');
    const [billingProfileModalOpen, setBillingProfileModalOpen] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [currentSection, setCurrentSection] = useState('facture');
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isSidebarOpen, setIsSidebarOpen] = useState(!isTabletOrMobile);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [isFormExpanded, setIsFormExpanded] = useState(false);
    const [hasOverdueDocuments, setHasOverdueDocuments] = useState(false);
    const [documentMessage, setDocumentMessage] = useState('');
    const [overdueDocumentType, setOverdueDocumentType] = useState('');
    const [isOverdueModalOpen, setIsOverdueModalOpen] = useState(false);

    const checkForOverdueDocuments = async (apiType) => {
        try {
            if (apiType !== 'notedecredit') {
                const overdueResponse = await sendRequest({
                    apiPath: `/overdue_documents/${apiType}/user`,
                    type: 'GET',
                });

                if (overdueResponse.rows.length > 0) {
                    setHasOverdueDocuments(true);
                    setDocumentMessage(`Il y a des ${apiType === 'facture' ? 'factures' : 'reçus'} en retard!`);
                    setOverdueDocumentType(apiType);
                } else {
                    setHasOverdueDocuments(false);
                    setDocumentMessage(`Aucun ${apiType === 'facture' ? 'facture' : 'reçu'} en retard.`);
                }
            } else {
                setHasOverdueDocuments(false);
                setDocumentMessage('');
            }
        } catch (err) {
            console.error('Error checking for overdue documents:', err);
        }
    };
    
    const handleCustomerChange = (customerId) => {
        setSelectedCustomerId(customerId); 
    };

    const fetchCustomers = async () => {
        console.log("Fetching customers..."); 
        try {
            const customerResponse = await sendRequest({
                apiPath: `crm/instances/client/list`,
                type: 'GET',
            });
            if (customerResponse && Array.isArray(customerResponse.rows)) {
                const customerOptions = customerResponse.rows.map(customer => {
                    const values = [
                        customer.lastname?.value,
                        customer.firstname?.value,
                        customer.entity_name?.value
                    ].filter(value => value); 
                    
                    return {
                        label: values.join(", "), 
                        value: customer.id,
                    };
                });
                setCustomers(customerOptions);
            }
        } catch (err) {
            console.error("Erreur lors de la récupération des clients:", err);
        }
    };

    useEffect(() => {
        const fetchBillingProfiles = async () => {
            console.log("Fetching billing profiles..."); 
            try {
                const billingprofileResponse = await sendRequest({
                    apiPath: `crm/instances/billingprofile/list`,
                    type: 'GET',
                });
                if (billingprofileResponse && Array.isArray(billingprofileResponse.rows)) {
                    const profileOptions = billingprofileResponse.rows.map(profile => ({
                        label: profile.company_name.value,
                        value: profile.id,
                    }));
                    setBillingProfiles(profileOptions);

                    const storedBillingProfileId = localStorage.getItem('selectedBillingProfileId');
                    if (!storedBillingProfileId || !profileOptions.some(profile => profile.value === storedBillingProfileId)) {
                        setBillingProfileModalOpen(true);
                    } else {
                        setSelectedBillingProfileId(storedBillingProfileId);
                    }
                }
            } catch (err) {
                console.error("Erreur lors de la récupération des profils de facturation:", err);
            }
        };
        fetchBillingProfiles();
        fetchCustomers();
    }, [sendRequest]);
    useEffect(() => {
        if (currentSection) {
            checkForOverdueDocuments(currentSection);
        }
    }, [currentSection]);

    const handleBillingProfileChange = (profileId) => {
        setSelectedBillingProfileId(profileId);
        localStorage.setItem('selectedBillingProfileId', profileId);
    };

    const handleSaveBillingProfile = () => {
        if (!selectedBillingProfileId) {
            alert('Veuillez sélectionner un profil de facturation.');
            return;
        }
        localStorage.setItem('selectedBillingProfileId', selectedBillingProfileId);
        setBillingProfileModalOpen(false);
    };

    const handleSectionChange = (section) => {
        setCurrentSection(section === currentSection ? '' : section);
    };

    const handleInvoiceCreated = () => {
        console.log('Facture créée avec succès!');
    };

    const handleCreditNoteCreated = () => {
        console.log('Note de crédit créée avec succès!');
    };

    const handleRecuCreated = () => {
        console.log('Reçu créé avec succès!');
    };

    const renderContent = () => {
        return (
            <>
                {/* Affichez le message des documents en retard si nécessaire */}
                {hasOverdueDocuments && (
                    <Box mb={2} p={2} border={1} borderColor="red" bgcolor="rgba(255, 0, 0, 0.1)">
                        <Typography variant="h6" color="red">
                            {documentMessage}
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => setIsOverdueModalOpen(true)}>
                            Voir les {overdueDocumentType === 'facture' ? 'factures' : 'reçus'} en retard
                        </Button>
                    </Box>
                )}
    
                {/* Affichez le contenu de la section sélectionnée */}
                {{
                    facture: (
                        <>
                            <Box display="flex" width="100%" height="100%" flexDirection="row">
                                <Box flex={3} pr={2}>
                                    <InvoiceForm
                                        billingprofiles={billingprofiles}
                                        customers={customers}
                                        onInvoiceCreated={handleInvoiceCreated}
                                        selectedBillingProfileId={selectedBillingProfileId}
                                        onCustomerChange={handleCustomerChange}
                                        isFormExpanded={isFormExpanded}
                                        setIsFormExpanded={setIsFormExpanded}
                                        onClientCreated={fetchCustomers}
                                    />
                                </Box>
                                <Box flex={1}>
                                    <ClientInfoCard
                                        selectedCustomerId={selectedCustomerId}
                                        isFormExpanded={isFormExpanded}
                                    />
                                </Box>
                            </Box>
                            <CommercialDocumentTable key="facture" apiType="facture" entityName="factures" />
                        </>
                    ),
                    recudecaisse: (
                        <>
                            <Box display="flex" width="100%" height="100%" flexDirection="row">
                                <Box flex={3} pr={2}>
                                    <RecuForm
                                        billingprofiles={billingprofiles}
                                        customers={customers}
                                        onRecuCreated={handleRecuCreated}
                                        selectedBillingProfileId={selectedBillingProfileId}
                                        onCustomerChange={handleCustomerChange}
                                        isFormExpanded={isFormExpanded}
                                        setIsFormExpanded={setIsFormExpanded}
                                    />
                                </Box>
                                <Box flex={1}>
                                    <ClientInfoCard
                                        selectedCustomerId={selectedCustomerId}
                                        isFormExpanded={isFormExpanded}
                                    />
                                </Box>
                            </Box>
                            <CommercialDocumentTable key="recudecaisse" apiType="recudecaisse" entityName="reçus" />
                        </>
                    ),
                    notedecredit: (
                        <>
                            <Box display="flex" width="100%" height="100%" flexDirection="row">
                                <Box flex={3} pr={2}>
                                    <CreditNoteForm
                                        billingprofiles={billingprofiles}
                                        customers={customers}
                                        onCreditNoteCreated={handleCreditNoteCreated}
                                        selectedBillingProfileId={selectedBillingProfileId}
                                        onCustomerChange={handleCustomerChange}
                                        isFormExpanded={isFormExpanded}
                                        setIsFormExpanded={setIsFormExpanded}
                                    />
                                </Box>
                                <Box flex={1}>
                                    <ClientInfoCard
                                        selectedCustomerId={selectedCustomerId}
                                        isFormExpanded={isFormExpanded}
                                    />
                                </Box>
                            </Box>
                            <CommercialDocumentTable key="notedecredit" apiType="notedecredit" entityName="notes de crédit" />
                        </>
                    ),
                }[currentSection]}
            </>
        );
    };
    

    const getSelectedBillingProfileLabel = () => {
        const selectedProfile = billingprofiles.find(profile => profile.value === selectedBillingProfileId);
        return selectedProfile ? selectedProfile.label : '';
    };

    const openBillingProfileModal = () => {
        console.log("openBillingProfileModal function called"); // Ajoutez ce log
        setBillingProfileModalOpen(true);
        console.log("billingProfileModalOpen set to true"); // Ajoutez ce log
    };

    return (
        <Dashboard dashboardTitle='Comptabilité' withSidebar>
            <Box display="flex" height="100%" overflow="hidden">
                <AccountingSidebar
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                    handleSectionChange={handleSectionChange}
                    getSelectedBillingProfileLabel={getSelectedBillingProfileLabel}
                    openBillingProfileModal={openBillingProfileModal}  // Ajouter cette ligne pour ouvrir le modal
                />
                <Box flexGrow={1} p={2} height="100%" overflow="auto">
                    {renderContent()}
                </Box>
            </Box>
            <Modal
                open={billingProfileModalOpen}
                onClose={() => {
                    console.log("Modal closed"); // Ajoutez ce log
                    setBillingProfileModalOpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper style={{ padding: '20px', margin: '20px auto', maxWidth: '80%', borderRadius: '10px' }}>
                    <Typography variant="h6" mb={2}>
                        Sélectionnez un profil de facturation
                    </Typography>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Profil de facturation</InputLabel>
                        <Select
                            value={selectedBillingProfileId || ''}
                            onChange={(e) => handleBillingProfileChange(e.target.value)}
                        >
                            {billingprofiles.map((profile) => (
                                <MenuItem key={profile.value} value={profile.value}>
                                    {profile.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        onClick={handleSaveBillingProfile}
                        variant="contained"
                        color="primary"
                        style={{ marginTop: '20px' }}
                    >
                        Enregistrer
                    </Button>
                </Paper>
            </Modal>
            <OverdueDocuments 
                apiType={overdueDocumentType} 
                isOpen={isOverdueModalOpen} 
                onClose={() => setIsOverdueModalOpen(false)} 
            />
        </Dashboard>
    );
};

export default AccountingDashboard;
